import { IEntity } from '../types';
import { EntityService } from './EntityService';

export class ZCoreDashboardService extends EntityService<IEntity> {
    private zedxApiURL: string = process.env.REACT_APP_ZedX_Non_Odata_API!;

    constructor() {
        super(`/ZCoreDashboards`);
    }

    public async getDashboardData(dashboardRef: string): Promise<any> {
        const endPoint: string = `${this.zedxApiURL}/ZCoreDashboards/GetDashboardData/${dashboardRef}`;
        const data = await super.readAnyEndPointValue(endPoint);
        return data;
    }
}