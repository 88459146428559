import { useState } from "react";
import { IOrbProps } from "./App";
import { useApi } from './components/useApi';
import { useUserContext } from './components/useUserContext';
import { useDataContext } from './components/useDataContext';
import { useIntegration } from './components/useIntegration';
import React from "react";
import { HashRouter, Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from "./components/ErrorBoundary";
import { IntegrationContext } from "./components/IntegrationContext";
import { DataContext } from "./components/DataContext";
import { OrbUserContext } from "./components/OrbUserContext";
import { CrLoadingOverlay } from './components/cr/CrLoadingOverlay';
import { AppGlobals, AppIDs } from "./AppGlobals";
import Logout from "./Logout";
import { ManageProjectsMenu } from "./components/ManageProjectsMenu";
import PerformanceReportingAdmin from "./components/PerformanceReportingAdmin";
import { withErrorHandling } from './components/withErrorHandling';
import { AdminRoute, DirectorateAdminRoute, MilestoneAdminRoute, NotAuthorised, NotFound, ProjectAdminRoute } from "./Routes";
import { MenuToggle } from "./components/MenuToggle";
import ProjectUpdates from "./components/project/ProjectUpdates";
import PortfolioUpdates from "./components/portfolio/PortfolioUpdates";
import ProgrammeUpdates from "./components/programme/ProgrammeUpdates";
import LoadingWhite from "./LoadingWhite";
import RiskUpdates from "./components/risk/RiskUpdates";
import ProjectSignOff from "./components/project/ProjectSignOff";
import RiskSignOff from "./components/risk/RiskSignOff";
import PortfolioSignOff from "./components/portfolio/PortfolioSignOff";
import { ManageProjectsWelcome } from "./components/manageProjects/ManageProjectsWelcome";
import SystemAdministration from "./components/SystemAdministration";

const ManageProjectsApp: React.FunctionComponent<IOrbProps> = (props: IOrbProps) => {

    const [lastPortfolioIdForChilds, setLastPortfolioIdForChilds] = useState(null);
    const [lastProgrammeIdForChilds, setLastProgrammeIdForChilds] = useState(null);
    const [lastProjectIdForChilds, setLastProjectIdForChilds] = useState(null);
    const [lastDirectorateIdForChilds, setLastDirectorateIdForChilds] = useState(null);
    const { dataServices, errorHandling } = props;
    const apiConnected = useApi(dataServices/*, errorHandling*/);
    const uc = useUserContext(apiConnected, dataServices, errorHandling);
    const ld = useDataContext(apiConnected, dataServices, errorHandling);
    const integration = useIntegration({
        disableDirectorateManagement: false,
        disableGroupManagement: false,
        disablePartnerOrganisationManagement: false,
        disableProjectManagement: false,
        disableUserManagement: false,
        dataSourceName: ''
    });
    const routeProps = { ...props, apiConnected: apiConnected, ...uc, ...ld };

    const commonProps = {
        lastPortfolioIdForChilds: lastPortfolioIdForChilds,
        setLastPortfolioIdForChilds: setLastPortfolioIdForChilds,
        lastProgrammeIdForChilds: lastProgrammeIdForChilds,
        setLastProgrammeIdForChilds: setLastProgrammeIdForChilds,
        lastProjectIdForChilds: lastProjectIdForChilds,
        setLastProjectIdForChilds: setLastProjectIdForChilds,
        lastDirectorateIdForChilds: lastDirectorateIdForChilds,
        setLastDirectorateIdForChilds: setLastDirectorateIdForChilds,
        ...routeProps,

    };

    const commonPropsUpdates = {
        lastDirectorateIdForChilds: lastDirectorateIdForChilds,
        lastProjectIdForChilds: lastProjectIdForChilds,
        emphasiseProjectsWithAttribute: 'SoS Priority Project',
        emphasisedProjectsHeaderText: 'SoS Priority Projects',
        otherProjectsHeaderText: 'All Other Reports',
        ...routeProps,
    };

    const [isNavigationVisible, setNavigationVisible] = useState(false);

    const toggleNavigation = () => {
        setNavigationVisible(!isNavigationVisible);
    };

    if (uc.userContext.UserId === null) return (<LoadingWhite />);

    const appName = props.menuData.filter(m => m.ID === AppIDs.PROJPORT)[0].AppName;

    return (

        <React.Fragment>
            <ErrorBoundary>
                <IntegrationContext.Provider value={integration}>
                    <DataContext.Provider value={ld}>
                        <OrbUserContext.Provider value={uc}>
                            <div className="main">
                                <div className="blue-banner">
                                    <MenuToggle onToggleNavigation={toggleNavigation} />
                                    <div className="blue-banner-white-bold">ZedX for {AppGlobals.CustomerSectorTitle} - {appName}</div>
                                    <div className="right-aligned-text"><Logout onLogout={props.onLogout} /></div>
                                </div>
                                <div className="container">
                                    <HashRouter>
                                        <div className={`navigation ${isNavigationVisible ? 'show' : ''}`}>
                                            {!uc.userContext.UserId &&
                                                <CrLoadingOverlay isLoading={true} />
                                                ||
                                                <ManageProjectsMenu  {...routeProps} onLinkClicked={toggleNavigation} />
                                            }
                                        </div>
                                        <div className="content">
                                            <Switch>
                                                <Route exact path="/performance-reporting/welcome" {...routeProps}>
                                                    <ManageProjectsWelcome />
                                                </Route>
                                                <DirectorateAdminRoute path="/performance-reporting/portfolios"  {...routeProps}>
                                                    <PerformanceReportingAdmin {...commonProps} entity="Portfolios" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/updates-for-portfolio/:portfolioIdForUpdates" {...routeProps}>
                                                    <PortfolioUpdates {...commonPropsUpdates} />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/sign-off-for-portfolio/:portfolioIdForUpdates" {...routeProps}>
                                                    <PortfolioSignOff {...commonPropsUpdates} />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/directorates"  {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Directorates" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/programmes"  {...routeProps}>
                                                    <PerformanceReportingAdmin {...commonProps} entity="Programmes" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/programmes-for-portfolio/:portfolioIdForProgrammes" {...routeProps}>
                                                    <PerformanceReportingAdmin {...commonProps} entity="Programmes" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/updates-for-programme/:programmeIdForUpdates" {...routeProps}>
                                                    <ProgrammeUpdates {...commonPropsUpdates} />
                                                </DirectorateAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/projects"  {...routeProps}>
                                                    <PerformanceReportingAdmin {...commonProps} entity="Projects" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/projects-for-portfolio/:portfolioIdForProjects" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Projects" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/projects-for-programme/:programmeIdForProjects" {...routeProps}>
                                                    <PerformanceReportingAdmin {...commonProps} entity="Projects" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/projects-for-directorate/:directorateIdForProjects" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Projects" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/updates-for-project/:projectIdForUpdates" {...routeProps}>
                                                    <ProjectUpdates {...commonPropsUpdates} />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/sign-off-for-project/:projectIdForUpdates/:requestWorkflowId/:requestUpdatePeriod" {...routeProps}>
                                                    <ProjectSignOff {...commonPropsUpdates} />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/sign-off-for-project/:projectIdForUpdates" {...routeProps}>
                                                    <ProjectSignOff {...commonPropsUpdates} />
                                                </ProjectAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/key-work-areas"  {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="KeyWorkAreas" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/key-work-areas-for-directorate/:directorateIdForKeyWorkAreas" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="KeyWorkAreas" />
                                                </DirectorateAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/risks-for-project/:projectIdForRisks" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Risks" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/updates-for-risk/:riskIdForUpdates" {...routeProps}>
                                                    <RiskUpdates {...commonPropsUpdates} />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/sign-off-for-risk/:riskIdForUpdates" {...routeProps}>
                                                    <RiskSignOff {...commonPropsUpdates} />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/risk-mitigating-actions-for-risk/:riskIdForMitigatingActions" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="RiskMitigationActions" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/work-streams"  {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="WorkStreams" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/work-streams-for-project/:projectIdForWorkStream" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="WorkStreams" />
                                                </ProjectAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/metrics"  {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Metrics" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/metrics-for-directorate/:directorateIdForMetrics" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Metrics" />
                                                </DirectorateAdminRoute>
                                                <MilestoneAdminRoute path="/performance-reporting/milestones"  {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Milestones" />
                                                </MilestoneAdminRoute>
                                                <MilestoneAdminRoute path="/performance-reporting/milestones-for-work-stream/:workstreamIdForMilestones" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Milestones" />
                                                </MilestoneAdminRoute>
                                                <MilestoneAdminRoute path="/performance-reporting/milestones-for-work-area/:workareaIdForMilestones" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Milestones" />
                                                </MilestoneAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/benefits" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Benefits" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/benefits-for-project/:projectIdForBenefits"  {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Benefits" />
                                                </ProjectAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/commitments" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Commitments" />
                                                </DirectorateAdminRoute>
                                                <DirectorateAdminRoute path="/performance-reporting/commitments-for-directorate/:directorateIdForCommitments" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Commitments" />
                                                </DirectorateAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/dependencies" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Dependencies" />
                                                </ProjectAdminRoute>
                                                <ProjectAdminRoute path="/performance-reporting/dependencies-for-project/:projectIdForDependencies" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="Dependencies" />
                                                </ProjectAdminRoute>

                                                <AdminRoute path="/performance-reporting/directorate-users/:directorateIdForDirectorateUsers" {...routeProps}>
                                                    <PerformanceReportingAdmin {...commonProps} entity="DirectorateUsers" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/project-users/:projectIdForProjectUsers" {...routeProps}>
                                                    <PerformanceReportingAdmin  {...commonProps} entity="ProjectUsers" />
                                                </AdminRoute>

                                                {/* settings */}
                                                <AdminRoute path="/performance-reporting/settings/benefit-types"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="BenefitTypes" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/attributes"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Attributes" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/reporting-frequencies"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="ReportingFrequencies" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/risk-types"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="RiskTypes" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/risk-discussion-forums"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="RiskDiscussionForums" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/units"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Units" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/thresholds"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Thresholds" />
                                                </AdminRoute>
                                                <AdminRoute path="/performance-reporting/settings/threshold-appetites"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="ThresholdAppetites" />
                                                </AdminRoute>

                                                <Route path="/not-authorised">
                                                    <NotAuthorised />
                                                </Route>
                                                <Route path="*">
                                                    <NotFound />
                                                </Route>
                                            </Switch>

                                        </div>
                                    </HashRouter>
                                </div>
                            </div>
                        </OrbUserContext.Provider>
                    </DataContext.Provider>
                </IntegrationContext.Provider>
            </ErrorBoundary>
        </React.Fragment>
    );
};

export const ManageProjectsAppWithErrorHandling = withErrorHandling(ManageProjectsApp);