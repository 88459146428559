import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from '@azure/msal-browser';
import { IDataServices } from './types';
import { withErrorHandling } from './components/withErrorHandling';
import App from './App';
import axios from 'axios';
import { AppGlobals } from './AppGlobals';
import { dataServices } from './dataServices';


// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

const onError = (errorUserMessage: string, errorDetail?: string): void => {
  //this.setState({ Error: errorUserMessage });
  //if (errorDetail) console.log(`${errorUserMessage}: ${errorDetail}`);
}
const clearErrors = () => {
  //this.setState({ Error: null });
}
const onFirstAPIRequestError = (errorType: string/*, tokenRefreshService: ITokenRefreshService*/) => {
  //this.setState({ FirstAPICallError: errorType/*, TokenRefreshService: tokenRefreshService*/ });
}



export let msalInstance: PublicClientApplication;

try {

  const urlParams = new URLSearchParams(window.location.search);
  let clientIdParameter: string = urlParams.get('c') || '';
  if (clientIdParameter == '') {
    //try to get from session
    clientIdParameter = sessionStorage.getItem("clientIdParameter") || '';
  }
  else {
    //otherwise store in session
    sessionStorage.setItem("clientIdParameter", clientIdParameter);
  }
  let authTypeChars: string = "";
  let customerId: string = "";

  if (clientIdParameter.length >= 3) {
    // Get the last 2 characters
    authTypeChars = clientIdParameter.substring(clientIdParameter.length - 2).toLowerCase();
    customerId = clientIdParameter.substring(0, clientIdParameter.length - 2);

    console.log("Auth Type:", authTypeChars);
    console.log("Extracted customer id:", customerId);
  } else {
    console.error("customerId should have at least 3 characters");
  }


  if (customerId == "") {
    window.location.href = AppGlobals.ZedxNetSiteUrl;
  }
  else {
    const zedxNetapiURL = process.env.REACT_APP_ZedX_Net_API!;
    axios.get(`${zedxNetapiURL}/customers/GetCustomerAuthDetails?c=${customerId}`).then((res) => {
      console.log('GetCustomerAuthDetails Response', res);
      const entraClientId: string = res.data.EntraClientId;
      const redirectAddressMSAuth: string = res.data.RedirectAddressMSAuth;
      const customerSector: string = res.data.CustomerSector;
      const customerApps: any[] = res.data.CustomerApps;
      const authType: string = authTypeChars === "-a" ? "AzureAd" : "CustomJwt";  //'CustomJwt' or 'AzureAd';
      AppGlobals.AuthType = authType;
      AppGlobals.CustomerRef = customerId;
      AppGlobals.CustomerSectorTitle = customerSector;

      if (authType === 'AzureAd' && (entraClientId == null || entraClientId === '')) {
        window.location.href = AppGlobals.ZedxNetSiteUrl;
      }

      msalConfig.auth.clientId = entraClientId;
      msalConfig.auth.redirectUri = redirectAddressMSAuth;
      msalInstance = new PublicClientApplication(msalConfig);

      const renderApp = (
        <React.Fragment>
          <App
            authType={authType}
            dataServices={dataServices}
            menuData={customerApps}
            errorHandling={{
              onError: onError,
              clearErrors: clearErrors,
              onFirstAPIRequestError: onFirstAPIRequestError
            }}
          />
        </React.Fragment>
      );

      const rootElement = document.getElementById('root');

      if (authType === 'AzureAd') {
        ReactDOM.render(
          <MsalProvider instance={msalInstance}>
            {renderApp}
          </MsalProvider>,
          rootElement
        );
      } else {
        ReactDOM.render(renderApp, rootElement);
      }
    });
  }

} catch (error) {
  console.error("Error fetching EntraClientId:", error);
}



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
