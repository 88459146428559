import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { IProgressIndicatorStyles, PrimaryButton, ProgressIndicator } from "@fluentui/react";
import { AppGlobals } from "./AppGlobals";
import axios from "axios";
import styles from './styles/cr.module.scss';
import { CrTextField } from "./components/cr/CrTextField";
import { CrProgressIndicator } from "./components/cr/CrProgressIndicator";

interface CustomSignInProps {
  onAccountSelection: () => void;
}

export const CustomSignIn = (props: CustomSignInProps): React.ReactElement => {
  const { instance } = useMsal();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [invalidLoginMsg, setInvalidLoginMsg] = useState<string>("");
  const [userAuthButNotFound, setUserAuthButNotFound] = useState<boolean>(false);
  const [progressHidden, setProgressHidden] = useState<boolean>(true);

  const handleLogin = async () => {
    try {
      setProgressHidden(false);
      const zedxApiURL = process.env.REACT_APP_ZedX_Non_Odata_API!;
      const response = await axios.post(`${zedxApiURL}/Accounts/Authenticate`, {
        username: email,
        password: password,
        customerRef: AppGlobals.CustomerRef
      });

      //console.log('Authentication response:', response.data);
      if (response.data.token == "") {
        //console.log("user authenticated with core but doesnt exist in db");
        setProgressHidden(true);
        setUserAuthButNotFound(true);
      }
      else {
        //user verified, go ahead to welcome after setting values
        AppGlobals.UserID = response.data.id;
        AppGlobals.Username = response.data.username;
        AppGlobals.UserTitle = response.data.name;
        AppGlobals.CustomJwtToken = response.data.token;
        AppGlobals.CustomerID = response.data.customerId;
        AppGlobals.CustomerTitle = response.data.customerTitle;
        setInvalidLoginMsg("");
        props.onAccountSelection();
      }


    } catch (error) {
      // Handle error here
      console.error('Authentication error:', error);
      setInvalidLoginMsg("Invalid Username or Password.");
      setProgressHidden(true);
    }
  };

  const customStyles: Partial<IProgressIndicatorStyles> = {
    root: {
      //padding: 0, // Adjust the padding for the root element
    },
    itemProgress: {
      margin: 0, // Adjust the margin for the progress bar
      paddingTop: '3px',
    },
  };

  if (isLoading) {
    // You can render a loading spinner or some other loading indicator here
    return <div>Loading...</div>;
  }

  return (
    <div className="main">
      <div className="blue-banner" style={{ marginLeft: '0', paddingLeft: '0' }}>
        <div className="blue-banner-white-bold">ZedX Apps</div>
      </div>
      <div className="container">
        <div className="content" style={{ paddingTop: '0', width: 'calc(100% - 38px)' }}>
          <div style={{ visibility: progressHidden ? 'hidden' : 'visible' }}>
            <ProgressIndicator
              barHeight={4}
              styles={customStyles}
            />
          </div>
          <div className={styles.cr}>
            <h2 style={{ color: 'rgb(112,146,190)', fontWeight: 'normal' }}>Welcome to ZedX Apps</h2>
            {!userAuthButNotFound &&
              <div>
                <div>
                  To login to ZedX Apps, enter your email address and password then click on the button below.
                  <br /><br />
                </div>
                <div style={{ maxWidth: '500px' }}>
                  <CrTextField
                    label="Email Address"
                    maxLength={500}
                    className={styles.formField}
                    value={email}
                    onChange={(_, newValue) => setEmail(newValue || "")}
                    required={true}
                    type="email"
                  />

                  <CrTextField
                    label="Password"
                    value={password}
                    className={styles.formField}
                    onChange={(_, newValue) => setPassword(newValue || "")}
                    required={true}
                    type="password"
                  />
                  <PrimaryButton text={'Login'} onClick={() => handleLogin()} />
                  <div style={{ marginTop: '20px', color: 'red' }}>
                    {invalidLoginMsg}
                  </div>
                </div>
              </div>
            }
            {
              userAuthButNotFound &&
              <div>
                Your login details were validated with our central authentication system, but we could not find a record in our database for the email address '{email}'. Please contact your administrator for assistance.
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};
