import { IOrbProps } from "./App";
import { useApi } from './components/useApi';
import { useUserContext } from './components/useUserContext';
import { useDataContext } from './components/useDataContext';
import { useIntegration } from './components/useIntegration';
import React, { useState } from "react";
import { HashRouter, Switch, Route } from 'react-router-dom';
import { ErrorBoundary } from "./components/ErrorBoundary";
import { IntegrationContext } from "./components/IntegrationContext";
import { DataContext } from "./components/DataContext";
import { OrbUserContext } from "./components/OrbUserContext";
import { CrLoadingOverlay } from './components/cr/CrLoadingOverlay';
import { AppGlobals } from "./AppGlobals";
import Logout from "./Logout";
import { withErrorHandling } from './components/withErrorHandling';
import { AccountAdminMenu } from "./components/AccountAdminMenu";
import SystemAdministration from "./components/SystemAdministration";
import { AdminRoute, CustomSectionsAdminRoute, DirectorateAdminRoute, FinancialRiskAdminRoute, NotAuthorised, NotFound } from "./Routes";
import { MenuToggle } from "./components/MenuToggle";
import LoadingWhite from "./LoadingWhite";
import EmailSettings from "./components/manageEmails/EmailSettings";
import Logs from "./components/adminLogs/Logs";
import DevTest from "./components/adminLogs/DevTest";

const AccountAdminApp: React.FunctionComponent<IOrbProps> = (props: IOrbProps) => {

    const { dataServices, errorHandling } = props;
    const apiConnected = useApi(dataServices/*, errorHandling*/);
    const uc = useUserContext(apiConnected, dataServices, errorHandling);
    const ld = useDataContext(apiConnected, dataServices, errorHandling);
    const integration = useIntegration({
        disableDirectorateManagement: false,
        disableGroupManagement: false,
        disablePartnerOrganisationManagement: false,
        disableProjectManagement: false,
        disableUserManagement: false,
        dataSourceName: ''
    });
    const routeProps = { ...props, apiConnected: apiConnected, ...uc, ...ld };

    const [isNavigationVisible, setNavigationVisible] = useState(false);

    const toggleNavigation = () => {
        setNavigationVisible(!isNavigationVisible);
    };

    if (uc.userContext.UserId === null) return (<LoadingWhite />);

    return (

        <React.Fragment>
            <ErrorBoundary>
                <IntegrationContext.Provider value={integration}>
                    <DataContext.Provider value={ld}>
                        <OrbUserContext.Provider value={uc}>
                            <div className="main">
                                <div className="blue-banner">
                                    <MenuToggle onToggleNavigation={toggleNavigation} />
                                    <div className="blue-banner-white-bold">ZedX for {AppGlobals.CustomerSectorTitle} - Account Admin</div>
                                    <div className="right-aligned-text"><Logout onLogout={props.onLogout} /></div>
                                </div>
                                <div className="container">
                                    <HashRouter>
                                        <div className={`navigation ${isNavigationVisible ? 'show' : ''}`}>
                                            {!uc.userContext.UserId &&
                                                <CrLoadingOverlay isLoading={true} />
                                                ||
                                                <AccountAdminMenu {...routeProps} onLinkClicked={toggleNavigation} />
                                            }
                                        </div>
                                        <div className="content">
                                            <Switch>
                                                <Route exact path="/admin/welcome" {...routeProps}>
                                                    <div>Welcome to Account Admin</div>
                                                </Route>
                                                <AdminRoute path="/admin/groups"  {...routeProps}>
                                                    <SystemAdministration {...routeProps} entity="Groups" />
                                                </AdminRoute>
                                                <DirectorateAdminRoute path="/admin/directorates"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Directorates" />
                                                </DirectorateAdminRoute>
                                                <AdminRoute path="/admin/divisions"  {...routeProps}>
                                                    <SystemAdministration {...routeProps} entity="OrgLevel3s" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/users"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Users" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/user-groups"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="UserGroups" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/user-partner-organisations"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="UserPartnerOrganisations" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/manage-emails"  {...routeProps}>
                                                    <EmailSettings {...routeProps} />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/logs"  {...routeProps}>
                                                    <Logs {...routeProps} />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/dev-test"  {...routeProps}>
                                                    <DevTest {...routeProps} />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/benefit-types"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="BenefitTypes" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/attributes"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Attributes" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/reporting-frequencies"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="ReportingFrequencies" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/risk-types"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="RiskTypes" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/risk-discussion-forums"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="RiskDiscussionForums" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/units"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Units" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/thresholds"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Thresholds" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/threshold-appetites"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="ThresholdAppetites" />
                                                </AdminRoute>
                                                <AdminRoute path="/admin/administrators"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="Administrators" />
                                                </AdminRoute>
                                                <CustomSectionsAdminRoute path="/admin/custom-report-sections"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="CustomReportSections" />
                                                </CustomSectionsAdminRoute>
                                                <FinancialRiskAdminRoute path="/admin/financial-risk-user-groups"  {...routeProps}>
                                                    <SystemAdministration  {...routeProps} entity="FinancialRiskUserGroups" />
                                                </FinancialRiskAdminRoute>
                                                <Route path="/not-authorised">
                                                    <NotAuthorised />
                                                </Route>
                                                <Route path="*">
                                                    <NotFound />
                                                </Route>
                                            </Switch>

                                        </div>
                                    </HashRouter>
                                </div>
                            </div>
                        </OrbUserContext.Provider>
                    </DataContext.Provider>
                </IntegrationContext.Provider>
            </ErrorBoundary>
        </React.Fragment>
    );
};

export const AccountAdminAppWithErrorHandling = withErrorHandling(AccountAdminApp);