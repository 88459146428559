import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import * as types from '../../types';
import * as services from '../../services';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import AutomationOptionsList from './AutomationOptionsList';
import { ColumnType, IGenColumn } from '../../types/GenColumn';
import EntityListCat from '../entity/EntityListCat';
import OutboxList from './OutboxList';


interface ILookupData {
}
class LookupData implements ILookupData {
}

export interface IEmailSettingsClsProps extends types.IBaseComponentProps {
}

interface IAppSettingsState {
  LookupData: ILookupData;
  LastRunMsg_Stage1: string;
  LastRunMsg_Stage2: string;
  OutboxListRefreshCounter: number;
  Outbox_ListFilterText: string;

}
class AppSettingsState implements IAppSettingsState {
  public LookupData = new LookupData();
  public LastRunMsg_Stage1 = "";
  public LastRunMsg_Stage2 = "";
  public OutboxListRefreshCounter = 0;
  public Outbox_ListFilterText: string = null;
  constructor() {

  }
}

//#endregion types defination

export default class EmailSettingsCls extends React.Component<IEmailSettingsClsProps, AppSettingsState> {

  private automationOptionService: services.ZCoreAutomationOptionService = new services.ZCoreAutomationOptionService();
  private autoFunctionLastRunService: services.ZCoreAutoFunctionLastRunService = new services.ZCoreAutoFunctionLastRunService();
  constructor(props: IEmailSettingsClsProps, state: IAppSettingsState) {
    super(props);
    this.state = new AppSettingsState();
  }


  public render(): React.ReactElement<IEmailSettingsClsProps> {

    return (
      <React.Fragment>
        <Pivot onLinkClick={this.props.errorHandling.clearErrors}>
          <PivotItem headerText="Process Emails">
            {this.renderAutomationOptions()}
          </PivotItem>
          <PivotItem headerText="Email Outbox">
            {this.renderOutboxList()}
          </PivotItem>
        </Pivot>
      </React.Fragment>
    );
  }

  private renderAutomationOptions() {
    const sendEmailsPermission = this.sendEmailsPermission();
    return (
      <div>
        <AutomationOptionsList
          disabled={!sendEmailsPermission}
          {...this.props}
        />
        {
          (sendEmailsPermission === true) &&

          <div style={{ paddingTop: '30px' }}>
            <div style={{ paddingBottom: '10px' }}>
              {
                this.state.LastRunMsg_Stage1 !== "Working" &&
                <span>Note: {this.state.LastRunMsg_Stage1}</span>
              }
              {this.state.LastRunMsg_Stage1 === "Working" &&
                <div>
                  <span>Working... Please Wait</span><br />
                  <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={this.loadAutoFunctionLastRun_Stage1} >Click to Refresh Status</span>
                </div>
              }
            </div>
            <PrimaryButton
              text="Send Emails to Outbox"
              onClick={this.handleProcessLnk_Step1}
              disabled={this.state.LastRunMsg_Stage1 === "Working" ? true : false}
            />

          </div>
        }

      </div>
    );
  }

  private renderOutboxList(): React.ReactElement<types.IWebPartComponentProps> {
    const sendEmailsPermission = this.sendEmailsPermission();
    return (
      <div>
        <div style={{ paddingTop: "10px" }}>
          <OutboxList
            {...this.props}
            //onError={this.onError}
            filterText={this.state.Outbox_ListFilterText}
            onChangeFilterText={this.handleOutboxList_ChangeFilterText}
            outboxListRefreshCounter={this.state.OutboxListRefreshCounter}
          />
          {
            (sendEmailsPermission === true) &&
            <div style={{ paddingTop: '30px' }}>
              <div style={{ paddingBottom: '10px' }}>
                {
                  this.state.LastRunMsg_Stage2 !== "Working" &&
                  <span>{this.state.LastRunMsg_Stage2}</span>
                }
                {this.state.LastRunMsg_Stage2 === "Working" &&
                  <div>
                    <span>Working... Please Wait</span><br />
                    <span style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }} onClick={this.loadAutoFunctionLastRun_Stage2} >Click to Refresh Data</span>
                  </div>
                }

              </div>
              <PrimaryButton
                text="Send Emails Now"
                onClick={this.handleProcessLnk_Step2}
                disabled={this.state.LastRunMsg_Stage2 === "Working" ? true : false}
              />
            </div>
          }
        </div>
      </div>
    );
  }

  private handleProcessLnk_Step1 = (): void => {
    this.automationOptionService.processAsAutoFunction().then((res: string): void => {
      this.setState({
        LastRunMsg_Stage1: res, //res = "Working" at this stage
      });

    }, (err) => {
    });
  }

  private handleProcessLnk_Step2 = (): void => {
    this.automationOptionService.processAsAutoFunctionFromOutbox().then((res: string): void => {
      this.setState({
        LastRunMsg_Stage2: res, //res = "Working" at this stage
      });
    }, (err) => {
    });
  }

  //#endregion Render


  //#region Permissions


  // private sendEmailsPermission(): boolean {
  //   //super user/SysManager check
  //   let ups: IUserPermission[] = this.state.UserPermissions;
  //   for (let i = 0; i < ups.length; i++) {
  //     let up: IUserPermission = ups[i];
  //     if (up.PermissionTypeId == 1 || up.PermissionTypeId == 15) {
  //       return true;
  //     }
  //   }

  //   return false;
  // }

  private sendEmailsPermission(): boolean {
    return this.props.userPermissions.UserIsSystemAdmin()
      || this.props.userPermissions.UserIsGIAAActionsSuperUser();
  }

  private isSuperUser(): boolean {
    return this.props.userPermissions.UserIsSystemAdmin()
      || this.props.userPermissions.UserIsGIAAActionsSuperUser();
  }


  //#endregion Permissions

  //#region Load Data

  private loadAutoFunctionLastRun_Stage1 = (): void => {
    this.autoFunctionLastRunService.getLastRunMsg("Stage1").then((res: string): void => {
      console.log('Last Run Msg', res);
      this.setState({
        LastRunMsg_Stage1: res,
      });

    }, (err) => {

    });
  }

  private loadAutoFunctionLastRun_Stage2 = (): void => {
    this.autoFunctionLastRunService.getLastRunMsg("Stage2").then((res: string): void => {
      console.log('Last Run Msg', res);
      this.setState({
        LastRunMsg_Stage2: res,
        OutboxListRefreshCounter: (this.state.OutboxListRefreshCounter + 1),
      });

    }, (err) => {

    });
  }

  public componentDidMount(): void {
    //this.setState({ Loading: true } );
    Promise.all([
      this.loadAutoFunctionLastRun_Stage1(),
      this.loadAutoFunctionLastRun_Stage2(),
    ]);
  }



  //#endregion Load Data

  //#region Event Handlers

  private handleOutboxList_ChangeFilterText = (value?: string): void => {
    this.setState({ Outbox_ListFilterText: value });
  }

  //#endregion Event Handlers

}
