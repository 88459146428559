import React, { useEffect, useState } from "react";
import { msalInstance } from ".";
import { AppGlobals } from "./AppGlobals";
import { AccountService } from "./services";
import { CrLoadingOverlay } from "./components/cr/CrLoadingOverlay";

interface AfterSignInProps {
  accountService?: AccountService;
  onAccountSelection: () => void;
}

export const AfterSignIn = (props: AfterSignInProps): React.ReactElement => {
  const [username, setUsername] = useState<string>('');
  const [userAuthButNotFound, setUserAuthButNotFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const checkEmail = async () => {
      try {
        const uname = msalInstance.getAllAccounts()[0].username;
        setUsername(uname);

        const response = await props.accountService.authenticateMSAuth(uname, AppGlobals.CustomerRef);

        if (response.id > 0) {
          AppGlobals.UserID = response.id;
          AppGlobals.Username = response.username;
          AppGlobals.CustomerID = response.customerId;
          AppGlobals.CustomerTitle = response.customerTitle;
          AppGlobals.UserTitle = response.name;

          props.onAccountSelection();
        } else {
          setUserAuthButNotFound(true);
        }
      } catch (error) {
        console.error('Authentication error:', error);
      } finally {
        setLoading(false);
      }
    };

    checkEmail();
  }, [props]);

  return (
    <div className="main">
      <div className="blue-banner" style={{ marginLeft: '0', paddingLeft: '0' }}>
        <div className="blue-banner-white-bold">ZedX Apps</div>
      </div>
      <div className="container">
        <div className="content" style={{ width: 'calc(100% - 38px)' }}>
          <div>
            <h2 style={{ color: 'rgb(112,146,190)', fontWeight: 'normal' }}>
              Welcome to ZedX Apps
            </h2>
            <div style={{ position: 'relative' }}>
              <CrLoadingOverlay isLoading={loading} opaque={true} />
              {userAuthButNotFound && (
                <div>
                  Your login details were validated with Microsoft authentication system, but we could not find a record in our database for the email address '{username}'. Please contact your administrator for assistance.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
