import React, { createContext, useContext, useState, ReactNode, CSSProperties } from 'react';

interface ContentStyleContextProps {
    contentStyle?: CSSProperties;
    setContentStyle?: React.Dispatch<React.SetStateAction<CSSProperties | undefined>>;
}

const ContentStyleContext = createContext<ContentStyleContextProps>({});

export const useContentStyle = () => useContext(ContentStyleContext);

export const ContentStyleProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [contentStyle, setContentStyle] = useState<CSSProperties | undefined>();

    return (
        <ContentStyleContext.Provider value={{ contentStyle, setContentStyle }}>
            {children}
        </ContentStyleContext.Provider>
    );
};
