import { IEntityChildren, IApp03Task } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';
import { EntityService } from './EntityService';

export class App03TaskService extends EntityService<IApp03Task> {
    protected childrenEntities = ['App03TaskUsers($expand=User)'];

    constructor() {
        super(`/App03Tasks`);
    }

    public readAllForList(includeClosed?: boolean, workflowID?: number, workflowStageID?: number, archived?: boolean, userID?: number): Promise<IApp03Task[]> {
        return this.readAll(
            `?$select=ID,Title,TaskRef,ExternalRef,TaskPriorityID,Archived,StartDate,EndDate`
            + `&$orderby=Title`
            + `&$expand=Workflow($select=RefColumnID),`
            + `App03TaskUsers($expand=User($select=Title))`
            + `&$filter=WorkflowID eq ${workflowID}`
            + (userID !== null ? ` and App03TaskUsers/any(taskUser: taskUser/UserID eq ${userID})` : ``)
            + (workflowStageID !== null ? ` and WorkflowStageID eq ${workflowStageID} and Archived ne true` : ``)
            + (archived !== null ? ` and Archived eq ${archived}` : ``)
        );
    }    

    public async readForView(taskID: number): Promise<IApp03Task> {
        return this.read(
            taskID,
            false,
            false,
            [
                'App03TaskUsers($expand=User($select=Title,EmailAddress))',
                'WorkflowStage($select=ID,Title)',
                'AddedBy($select=ID,Title)',
            ]
        );
    }

    // public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IApp03Workflow[]> {
    //     return this.readAll(
    //         `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
    //         + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
    //         + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
    //     );
    // }

    // public async entityChildren(id: number): Promise<IEntityChildren[]> {
    //     return this.entityChildrenSingle(id, 'App03TaskUsers', 'App03WorkflowStages', true);
    // }


}