import React, { useEffect, useState } from "react";
import { DonutChart, IChartDataPoint, IChartProps } from "@fluentui/react-charting";
import { IGraphData } from "../../types";
import { ThemeProvider } from "@fluentui/react";
import useScreenSizeDetector from "../useScreenSizeDetector";

interface GrSingleNumberProps {
    graphData: IGraphData;
    containerWidth?: string;
    containerHeight?: string;
}

interface ChartConfig {
    Main: {
        Title: string,
        SubTitle: string,
        TitlesAlign: string,
        TitlesMarginBottom: number,
        DataColumn: string;
        ColorColumn: string;
        DataFontSize: number;
    };
}

interface IChartData {
    data: any;
    color?: string;
}

export const GrSingleNumber = (props: GrSingleNumberProps): React.ReactElement => {

    const { isMobile, isMediumScreen, isLargeScreen } = useScreenSizeDetector();
    const width: string = props.containerWidth ?? '100%'; // Change to 100% for full width
    const height: string = props.containerHeight ?? '100%'; // Full height

    const [chartData, setChartData] = useState<IChartData>();
    const [graphConfig, setGraphConfig] = useState<ChartConfig>();

    const rootStyle = {
        width: width,
        height: height,
        //display: 'flex',  // Ensure the chart container is using flexbox
        justifyContent: 'center',  // Center horizontally
        alignItems: 'center',  // Center vertically
    };

    useEffect(() => {
        const adjustData = () => {
            //console.log(props.graphData);
            const jsonData = props.graphData.jSonProperties;
            const chartConfig: ChartConfig = JSON.parse(jsonData);

            //const cData: IChartDataPoint[] = [];
            for (const value of props.graphData.data) {
                let color: string | undefined = undefined;
                if (value[chartConfig.Main.ColorColumn]?.trim() !== '') {
                    color = value[chartConfig.Main.ColorColumn];
                }

                const data = value[chartConfig.Main.DataColumn];

                setGraphConfig(chartConfig);

                const chartData: IChartData = {
                    data: data,
                    color: color,
                };

                setChartData(chartData);

                break; // Break after the first iteration
            }

        };

        adjustData();
    }, [props.graphData]);

    const titleTextAlign: React.CSSProperties['textAlign'] =
        ['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent'].includes(graphConfig?.Main?.TitlesAlign?.toLocaleLowerCase())
            ? graphConfig.Main.TitlesAlign as React.CSSProperties['textAlign']
            : 'left';

    const titleMarginBottom: string =
        graphConfig?.Main?.TitlesMarginBottom !== undefined
            ? `${graphConfig.Main.TitlesMarginBottom}px`
            : '20px';

    const dataFontSize: string =
        graphConfig?.Main?.DataFontSize !== undefined
            ? `${graphConfig.Main.DataFontSize}px`
            : '150px';

    return (
        <div style={rootStyle}>

            {graphConfig && chartData && (
                <>
                    {graphConfig.Main.Title && graphConfig.Main.Title.length > 0 && <div style={{ marginBottom: titleMarginBottom }}>
                        <div style={{ textAlign: titleTextAlign }}>{graphConfig.Main.Title}</div>
                        <div style={{ fontSize: 'small', textAlign: titleTextAlign }}>{graphConfig.Main.SubTitle}</div>
                    </div>}
                    <div style={{ textAlign: 'center' }}>
                        <span style={{ fontSize: dataFontSize, fontWeight: 'bold', color: chartData.color }}>{chartData.data} </span>
                    </div>
                </>
            )}

        </div>
    );
};


