import * as React from 'react';
import { IAutomationOption, IEntityFormProps2 } from '../../types';
import styles from '../../styles/cr.module.scss';
import { UpdateHeader2 } from '../cr/UpdateHeader2';
import AutoOption from './AutoOption';
import { NotifySettingsForm } from './NotifySettingsForm';

export interface INotifySettingsSectionProps extends IEntityFormProps2 {
    disabled: boolean;
}

export class NotifySettingsSectionState {
    public Loading: boolean = false;
    public NotifySettingsSection_IsOpen: boolean = false;
    constructor() {
    }
}

export default class NotifySettingsSection extends React.Component<INotifySettingsSectionProps, NotifySettingsSectionState> {

    constructor(props: INotifySettingsSectionProps, state: NotifySettingsSectionState) {
        super(props);
        this.state = new NotifySettingsSectionState();
    }

    public render(): React.ReactElement<INotifySettingsSectionProps> {

        const ShowForm = this.state.NotifySettingsSection_IsOpen;
        return (
            <div className={styles.cr}>
                <UpdateHeader2 title='Email & Notifications Setup'
                    isOpen={ShowForm}
                    leadUser=""
                    hideRagIndicator={true}
                    rag={null}
                    ragLabel={null}
                    onClick={this.handleNotifySettingsSection_toggleOpen} />

                {ShowForm && <div style={{ overflowX: 'hidden' }}>
                    <NotifySettingsForm
                        onCancelClick={this.handleNotifySettingsForm_Cancel}
                        errorHandling={this.props.errorHandling}
                        onSaved={this.props.onSaved}
                    />
                    <br /><br />
                </div>}
            </div>
        );
    }


    private handleNotifySettingsSection_toggleOpen = (): void => {
        this.setState({ NotifySettingsSection_IsOpen: !this.state.NotifySettingsSection_IsOpen });
    }

    private handleNotifySettingsForm_Cancel = (): void => {
        this.setState({ NotifySettingsSection_IsOpen: false });
    }
}
