import { IEntity } from "./Entity";

export interface IApp03TaskEvidence  extends IEntity {
    FileUniqueID?: string;
    Details?: string;
    TaskID?: number; 
    DateCreated?: Date;
    AddedByUserID?: number;
    AttachmentType?:string;
}

export class App03TaskEvidence implements IApp03TaskEvidence{ 
    public ID: number = 0;
    public Title: string = null;
    public FileUniqueID?: string = null;
    public Details?: string = "";
    public TaskID?: number = null;
    public DateCreated?: Date = null;
    public AddedByUserID?: number = null;
    public AttachmentType?: string = null;

    constructor(taskID: number, attachmentType: string) {
        this.TaskID = taskID;
        this.AttachmentType = attachmentType;
    }
}