import * as React from 'react';
import * as types from '../../../types';
import * as services from '../../../services';
import { ICLCaseEvidence, CLCaseEvidence } from '../../../types';
import { CrTextField } from '../../cr/CrTextField';
import { FieldErrorMessage } from '../../cr/FieldDecorators';
import { CrChoiceGroup } from '../../cr/CrChoiceGroup';
import { FormButtons } from '../../cr/FormButtons';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import { FormCommandBar } from '../../cr/FormCommandBar';
import styles from '../../../styles/cr.module.scss';
import '../../../styles/CustomFabric.scss';
import { IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';

export interface IEvidenceSaveFormProps extends types.IBaseComponentProps {
    caseId: number;
    workerId?: number;
    evidenceId: number;
    showForm: boolean;
    evidenceType?: string;
    onSaved?: () => void;
    onCancelled?: () => void;
}

export interface IErrorMessage {
    Details: string;
    Title: string;
    FileUpload: string;
}
export class ErrorMessage implements IErrorMessage {
    public Details = null;
    public Title = null;
    public FileUpload = null;
}

export interface IEvidenceSaveFormState {
    Loading: boolean;
    FormData: ICLCaseEvidence;
    FormDataBeforeChanges: ICLCaseEvidence;
    FormIsDirty: boolean;
    UploadStatus: string;
    UploadProgress: number;
    ShowUploadProgress: boolean;
    EditRequest: boolean;
    ErrMessages: IErrorMessage;
}

export class EvidenceSaveFormState implements IEvidenceSaveFormState {
    public Loading = false;
    public FormData;
    public FormDataBeforeChanges;
    public FormIsDirty = false;
    public UploadStatus = "";
    public UploadProgress: number = 0;
    public ShowUploadProgress = false;
    public EditRequest = false;
    public ErrMessages = new ErrorMessage();

    constructor(caseId: number, workerId: number, evidenceType: string) {
        let defaultAttachmentType: string = "None";
        if (evidenceType === "IR35" || evidenceType === "ContractorSecurityCheck") {
            defaultAttachmentType = "PDF";
        }
        this.FormData = new CLCaseEvidence(caseId, evidenceType, defaultAttachmentType, workerId);
        this.FormDataBeforeChanges = new CLCaseEvidence(caseId, evidenceType, defaultAttachmentType, workerId);
    }
}

export default class EvidenceSaveForm extends React.Component<IEvidenceSaveFormProps, IEvidenceSaveFormState> {

    private cLCaseEvidenceService: services.CLCaseEvidenceService = new services.CLCaseEvidenceService();
    private zFileService: services.ZFileService = new services.ZFileService();

    constructor(props: IEvidenceSaveFormProps, state: IEvidenceSaveFormState) {
        super(props);

        this.state = new EvidenceSaveFormState(props.caseId, props.workerId, props.evidenceType);
    }

    public render(): React.ReactElement<IEvidenceSaveFormProps> {
        const headerText: string = (this.props.evidenceType === "IR35" || this.props.evidenceType === "ContractorSecurityCheck") ? "Evidence" : "Case Discussion, General Comments and Attachments";
        return (
            <Panel isOpen={this.props.showForm} headerText={headerText} type={PanelType.medium} onRenderNavigation={() => <FormCommandBar onSave={this.saveEvidence} onCancel={this.props.onCancelled} /*saveDisabled={this.state.ShowUploadProgress}*/ />}>
                <div className={styles.cr}>
                    {this.renderFormFields()}
                    <FormButtons
                        primaryText={"Save"}
                        primaryDisabled={this.state.ShowUploadProgress}
                        onPrimaryClick={() => this.saveEvidence()}
                        onSecondaryClick={this.props.onCancelled}
                    />
                    {this.renderInfoText()}
                </div>
            </Panel>
        );
    }

    public renderInfoText() {
        if (this.state.FormData.ID > 0 && this.state.EditRequest === true && this.state.FormData.AttachmentType === "PDF") {
            const fileName = this.state.FormData.Title;
            return (
                <div style={{ marginTop: '20px' }}>
                    This evidence is linked to file "{fileName}". <br />
                    To change the file, please delete this evidence record and add again.
                </div>
            );
        }
        return null;
    }

    public renderFormFields() {
        return (
            <React.Fragment>
                {this.renderDetails()}
                {this.renderAttachmentTypeChoiceOptions()}
                {this.renderLinkBox()}
                {this.renderFileUpload()}

            </React.Fragment>
        );
    }

    private renderDetails() {
        return (
            <CrTextField
                label="Details"
                required={true}
                className={styles.formField}
                value={this.state.FormData.Details}
                onChange={(_, v) => this.changeTextField(v, "Details")}
                multiline={true}
                rows={3}
                errorMessage={this.state.ErrMessages.Details}
            />
        );
    }

    private renderAttachmentTypeChoiceOptions() {
        let options: IChoiceGroupOption[] = [
            { key: 'None', text: 'None' },
            { key: 'PDF', text: 'PDF File' },
            { key: 'Link', text: 'Link' },
        ];

        if (this.props.evidenceType === "IR35" || this.props.evidenceType === "ContractorSecurityCheck") {
            options = options.filter(x => x.key === 'PDF');
        }

        const fd = this.state.FormData;

        return (
            <div>
                <CrChoiceGroup
                    label="Attachment"
                    className="inlineflex"
                    options={options}
                    disabled={this.state.EditRequest}
                    selectedKey={fd.AttachmentType}
                    onChange={(ev, option) => this.changeChoiceGroup(ev, option, "AttachmentType")}
                />
            </div>
        );
    }

    private renderLinkBox() {
        if (this.state.FormData.AttachmentType === "Link") {
            return (
                <CrTextField
                    label="Link"
                    required={true}
                    className={styles.formField}
                    value={this.state.FormData.Title}
                    onChange={(_, v) => this.changeTextField(v, "Title")}
                    errorMessage={this.state.ErrMessages.Title}
                />
            );
        }
        else
            return false;
    }

    private renderFileUpload() {
        if (this.state.EditRequest === true) return null;
        if (this.state.FormData.AttachmentType !== "PDF")
            return null;

        return (
            <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                <div>
                    <input type="file" name="fileUpload" id="fileUpload" accept="application/pdf"></input>
                    {this.state.ErrMessages.FileUpload && <FieldErrorMessage value={this.state.ErrMessages.FileUpload} />}
                    <div style={{ paddingTop: '10px' }}>
                        Please upload all evidence as PDFs. For guidance on saving documents and emails please click <span onClick={this.viewHelpPDF} style={{ textDecoration: 'underline', cursor: 'pointer' }}>here</span>.
                    </div>
                </div>
                {this.state.ShowUploadProgress && <div style={{ minHeight: '80px', marginTop: '15px' }}>
                    <div>
                        {this.state.UploadStatus}
                    </div>
                    <div>
                        {this.state.UploadProgress} %
                    </div>
                </div>}
            </div>
        );
    }




    private uploadFile = async (evidenceId: number, uploadedByUserId: number) => {
        this.setState({
            UploadStatus: "Uploading file ...",
            ShowUploadProgress: true
        });

        let myfile = (document.querySelector("#fileUpload") as HTMLInputElement).files[0];
        let fileName: string = `${evidenceId}_${myfile.name}`;

        try {
            const response = await this.zFileService.uploadFile('CL', myfile, (progressEvent) => {
                const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                this.setState({ UploadProgress: progress });
            });

            if (response.status === true) {
                this.afterFileUpload(String(response.fileID), evidenceId, fileName, uploadedByUserId);
            }
            else {
                console.log('error in uploading file');
            }
        } catch (error) {
            console.error('Error uploading file', error);
        }
    }


    private afterFileUpload = (fileUniqueID: string, evidenceId: number, fileName: string, uploadedByUserId: number): void => {
        const fdata = { ...this.state.FormData, "FileUniqueID": fileUniqueID, "Title": fileName, "ID": evidenceId, "UploadedByUserId": uploadedByUserId };
        this.setState({
            FormData: fdata
        }, this.saveEvidence);
    }

    private viewHelpPDF = () => {
        console.log('help pdf');
    }

    private saveEvidence = (): void => {

        if (this.validateEntity()) {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);

            let f: ICLCaseEvidence = { ...this.state.FormData };
            if (f.ID === 0) {

                //firts create record in the db, so we can get the ID, then use the ID to append in the file name to make file name unique
                this.cLCaseEvidenceService.create(f).then(x => {
                    if (this.state.FormData.AttachmentType === "PDF") {
                        this.uploadFile(x.ID, x.UploadedByUserId);
                    }
                    else {
                        //its a link instead of the file, so close the form
                        console.log('evidence saved as link');
                        this.props.onSaved();
                    }
                });
            }
            else {

                this.cLCaseEvidenceService.updatePut(f.ID, f).then(this.props.onSaved, (err) => {
                    if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error updating item`, err.message);
                });
            }
        }
    }

    private validateEntity = (): boolean => {
        let returnVal: boolean = true;
        let errMsg: IErrorMessage = { ...this.state.ErrMessages };

        if ((this.state.FormData.Details === null) || (this.state.FormData.Details === '')) {
            errMsg.Details = "Details required";
            returnVal = false;
        }
        else {
            errMsg.Details = null;
        }

        if (this.state.EditRequest === false && this.state.FormData.AttachmentType === "PDF") {
            const file = (document.querySelector("#fileUpload") as HTMLInputElement).files[0];

            if (file == null) {
                errMsg.FileUpload = "PDF file required";
                returnVal = false;
            }
            else {
                const fileName = file.name;
                const ext = fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

                if (ext === "pdf") {
                    errMsg.FileUpload = null;
                }
                else {
                    errMsg.FileUpload = "PDF file required";
                    returnVal = false;
                }
            }

        }
        else {
            errMsg.FileUpload = null;
        }

        if (this.state.FormData.AttachmentType === "Link") {
            if (this.state.FormData.Title === null || this.state.FormData.Title === '') {

                errMsg.Title = "Link Required";
                returnVal = false;
                console.log('error link required');
            }
            else {
                const x: string = this.state.FormData.Title.toLowerCase();
                if (x.search("http") === 0) {
                    //ok
                }
                else {
                    errMsg.Title = "Link should start with http:// or https://";
                    returnVal = false;
                    console.log('Link should start with http:// or https://');
                }
            }
        }
        else {
            errMsg.Title = null;
        }

        this.setState({ ErrMessages: errMsg });

        return returnVal;
    }

    private loadEvidence = (): Promise<void> => {
        let x = this.cLCaseEvidenceService.read(this.props.evidenceId).then((e: ICLCaseEvidence): void => {

            this.setState({
                FormData: e,
                FormDataBeforeChanges: e,
            });

        }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading evidence data`, err.message); });
        return x;
    }

    public componentDidMount(): void {
        this.setState({ Loading: true });
        let loadingPromises = [this.loadLookups()];
        if (this.props.evidenceId) {
            this.setState({ EditRequest: true });
            loadingPromises.push(this.loadEvidence());
        }
        Promise.all(loadingPromises).then(p => this.onAfterLoad(p[1])).then(p => this.setState({ Loading: false })).catch(err => this.setState({ Loading: false }));
    }

    private loadLookups(): Promise<any> {
        let proms: any[] = [];
        return Promise.all(proms);
    }

    private onAfterLoad = (entity: types.IEntity): void => {

    }

    private changeTextField = (value: string, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value), FormIsDirty: true });
    }

    private cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }
    protected changeChoiceGroup = (ev, option: IChoiceGroupOption, f: string): void => {
        const selectedKey = option.key;
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, selectedKey)/*, FormIsDirty: true*/ });

    }
}