import React, { useEffect, useState } from "react";
import { IVerticalStackedChartProps, IVSChartDataPoint, VerticalStackedBarChart } from "@fluentui/react-charting";
import { IGraphData } from "../../types";
import useScreenSizeDetector from "../useScreenSizeDetector";

interface GrVerticalStackedBarChartProps {
    graphData: IGraphData;
}

interface IStack {
    LegendText: string;
    QueryColumn: string;
    Color: string;
    Color2: string;
}
interface IChartConfig {
    Main: {
        Title?: string;
        SubTitle?: string;
        TitlesMarginBottom: number,
        TitlesAlign: string,
        PageSizeMobile: number;
        PageSizeMediumScreen: number;
        PageSizeLargeScreen: number;
        BarWidth: number;
        RoundCorners: boolean;
        EnableGradient: boolean;
        UseSingleColor: boolean;
        BarTooltip: boolean;
        ShowDescription: "left" | "right" | "top" | "bottom";
        Height: number;
    };
    YAxis: {
        AxisTitle: string;
        Stacks: IStack[];
    };
    XAxis: {
        AxisTitle: string;
        QueryColumn: string;
        WrapXAxisLables: boolean;
        XAxisInnerPadding: number;
        XAxisOuterPadding: number;
    };
}

export const GrVerticalStackedBarChart = (props: GrVerticalStackedBarChartProps): React.ReactElement => {
    const { isMobile, isMediumScreen, isLargeScreen } = useScreenSizeDetector();
    const defaultHeight: number = 500;

    const [currentPage, setCurrentPage] = useState(-1);
    const [chartData, setChartData] = useState<IVerticalStackedChartProps[]>([]);
    const [allData, setAllData] = useState<IVerticalStackedChartProps[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [graphConfig, setGraphConfig] = useState<IChartConfig>();

    const rootStyle = { width: `100%`, height: `${graphConfig?.Main?.Height ?? defaultHeight}px` };

    useEffect(() => {
        const adjustData = () => {
            if (graphConfig) {
                const pageSize: number = getPageSize(graphConfig);
                const startIdx = currentPage * pageSize;
                const endIdx = startIdx + pageSize;
                const currentItems = allData.slice(startIdx, endIdx);

                setChartData(currentItems);
            } else {
                const jsonData = props.graphData.jSonProperties;
                const chartConfig: IChartConfig = JSON.parse(jsonData);
                const cData: IVerticalStackedChartProps[] = [];

                props.graphData.data.forEach((value) => {
                    const currentRowChartPoints: IVSChartDataPoint[] = [];
                    chartConfig.YAxis.Stacks.forEach((s) => {
                        currentRowChartPoints.push({
                            legend: s.LegendText,
                            data: value[s.QueryColumn],
                            color: s.Color,
                            gradient: [s.Color, s.Color2]
                        });
                    });

                    cData.push({
                        xAxisPoint: value[chartConfig.XAxis.QueryColumn],
                        chartData: currentRowChartPoints
                    });
                });

                const pageSize: number = getPageSize(chartConfig);
                const totPages = Math.ceil(cData.length / pageSize);
                setGraphConfig(chartConfig);
                setAllData(cData);
                setTotalPages(totPages);
                setCurrentPage(0);
            }
        };

        adjustData();
    }, [props.graphData, currentPage, totalPages]);

    useEffect(() => {
        if (graphConfig) {
            setCurrentPage(0);
            const pageSize = getPageSize(graphConfig);
            const totPages = Math.ceil(allData.length / pageSize);
            setTotalPages(totPages);
        }
    }, [isMobile, isMediumScreen, isLargeScreen, graphConfig]);

    const getPageSize = (config: IChartConfig): number => {
        if (config) {
            if (isMobile) return config.Main.PageSizeMobile;
            else if (isMediumScreen) return config.Main.PageSizeMediumScreen;
            else return config.Main.PageSizeLargeScreen;
        }
        return 50;
    };

    const getChartWidth = (): number => {
        if (isMobile) {
            return window.innerWidth * 0.9;
        } else if (isMediumScreen) {
            return window.innerWidth * 0.8;
        } else {
            return window.innerWidth * 0.7;
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages - 1) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevious = () => {
        if (currentPage > 0) {
            setCurrentPage(currentPage - 1);
        }
    };

    const shouldShowPaginationControls = totalPages > 1;

    const titleTextAlign: React.CSSProperties['textAlign'] =
        ['start', 'end', 'left', 'right', 'center', 'justify', 'match-parent'].includes(graphConfig?.Main?.TitlesAlign?.toLocaleLowerCase())
            ? graphConfig.Main.TitlesAlign as React.CSSProperties['textAlign']
            : 'left';

    const titleMarginBottom: string =
        graphConfig?.Main?.TitlesMarginBottom !== undefined
            ? `${graphConfig.Main.TitlesMarginBottom}px`
            : '20px';

    return (
        <div style={rootStyle}>
            {graphConfig && (
                <>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginBottom: titleMarginBottom,
                            //flexDirection: isMobile ? 'column' : 'row', // Change to column on mobile                            
                            //alignItems: isMobile ? 'flex-start' : 'center', // Align title and buttons separately on mobile

                        }}
                    >
                        {graphConfig.Main.Title && graphConfig.Main.Title.length > 0 && (
                            <div
                                style={{
                                    flex: shouldShowPaginationControls ? 1 : 'auto',
                                    //marginBottom: isMobile ? '10px' : '0'
                                }}>
                                <div style={{ marginBottom: '10px' }}>
                                    <div style={{ textAlign: titleTextAlign }}>{graphConfig.Main.Title}</div>
                                    <div style={{ fontSize: 'small', textAlign: titleTextAlign }}>{graphConfig.Main.SubTitle}</div>
                                </div>
                            </div>
                        )}

                        {shouldShowPaginationControls && (
                            <div
                                style={{
                                    maxWidth: '65px',
                                    textAlign: 'right',
                                    //alignSelf: isMobile ? 'flex-end' : 'center', // Right-align on mobile
                                }}
                                className="chart-nav-buttons-container">
                                <button
                                    onClick={handlePrevious}
                                    className="chart-button previous-chart-button"
                                    style={{
                                        width: '40px',
                                        marginRight: '10px',
                                        visibility: currentPage === 0 ? 'hidden' : 'visible'
                                    }}
                                >
                                    &#8592;
                                </button>
                                <button
                                    onClick={handleNext}
                                    className="chart-button next-chart-button"
                                    style={{
                                        width: '40px',
                                        visibility: currentPage >= totalPages - 1 ? 'hidden' : 'visible',
                                    }}
                                >
                                    &#8594;
                                </button>
                            </div>
                        )}
                    </div>


                    <VerticalStackedBarChart
                        chartTitle={props.graphData.title}
                        data={chartData}
                        height={graphConfig.Main?.Height ?? defaultHeight}
                        width={getChartWidth()}
                        showXAxisLablesTooltip={!graphConfig.XAxis.WrapXAxisLables}
                        wrapXAxisLables={graphConfig.XAxis.WrapXAxisLables ?? true}
                        enableReflow={true}
                        maxBarWidth={1000}
                        barWidth={graphConfig.Main.BarWidth ?? 'auto'}
                        enableGradient={graphConfig.Main.EnableGradient ?? false}
                        hideTooltip={!graphConfig.Main.BarTooltip}
                        roundCorners={graphConfig.Main.RoundCorners ?? false}
                        xAxisTitle={graphConfig.XAxis.AxisTitle}
                        yAxisTitle={graphConfig.YAxis.AxisTitle}
                        xAxisInnerPadding={graphConfig.XAxis.XAxisInnerPadding ?? undefined}
                        xAxisOuterPadding={graphConfig.XAxis.XAxisOuterPadding ?? undefined}
                    />
                </>
            )}
        </div>
    );
};
