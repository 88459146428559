import { EntityService } from './EntityService';
import { IEntity, IAutomationOption } from '../types';

export class ZCoreAutoFunctionLastRunService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/ZCoreAutoFunctionLastRuns`);
    }

    public getLastRunMsg(stage: string): Promise<string> {
        return super.readString(`?getLastRunMsg=${stage}`).then((result: string): string => {
            return result;
        });
    }
}