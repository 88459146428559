import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    EntityValidations, ISpecificEntityFormProps, IApp03WorkflowStage, IApp03Task, App03Task, IUser, App03TaskUser
} from '../../types';
import { DateService, LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrUserPicker } from '../cr/CrUserPicker';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { CrDatePicker } from '../cr/CrDatePicker';
import { TaskPriority } from '../../refData/WorkflowType';
import { CrCheckbox } from '../cr/CrCheckbox';
import { EntityFormPage } from '../EntityFormPage';
import useMobileDetector from '../useMobileDetector';
import { CrCommandBar } from '../cr/CrCommandBar';
import EvidenceList from './EV/EvidenceList';
import { OrbUserContext } from '../OrbUserContext';

class TaskFormValidations extends EntityValidations {
    public AssignedTo: string = null;
}

interface ITaskFormProps extends ISpecificEntityFormProps {
    workflowID: number;
    workflowTitle?: string;
    newTaskStageID: number;
}

export const TaskFormPage = (props: ITaskFormProps): React.ReactElement => {
    const { dataServices, loadLookupData: { entityStatuses, users: { all: allUsers } } } = useContext(DataContext);
    const { userPermissions } = useContext(OrbUserContext);
    const { userContext } = useContext(OrbUserContext);
    const currentUserId: number = userContext.UserId;
    const isMobile = useMobileDetector();
    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);

    const [workflowStages, setWorkflowStages] = useState<IApp03WorkflowStage[]>([]);
    const [workflowUsers, setWorkflowUsers] = useState<IUser[]>([]);
    const [showArchived, setShowArchived] = useState<boolean>(true);
    const [evidence_ListFilterText, setEvidence_ListFilterText] = useState<string>('');
    const [taskInfo, setTaskInfo] = useState<IApp03Task>();
    const [taskAddedOn, setTaskAddedOn] = useState<string>('');


    useEffect(() => {
        const fetchStages = async () => {
            try {
                const stages = await dataServices.app03WorkflowStageService.readWorkflowStages(props.workflowID);
                setWorkflowStages(stages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchWorkflowUsers = async () => {
            try {
                const users = (await dataServices.app03WorkflowUserService.readWorkflowUsers(props.workflowID)).map(u => u.User);
                setWorkflowUsers(users);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        const fetchTaskInfo = async () => {
            if (props.entityId > 0) {
                try {
                    const task = await dataServices.app03TaskService.readForView(props.entityId);
                    console.log('taskInfo', task);
                    setTaskInfo(task);

                    if (task?.['AddedOn'] !== null) {
                        const addedOn = DateService.dateToUkDateTime(new Date(new Date(task?.['AddedOn']).toLocaleString()));
                        setTaskAddedOn(addedOn);
                    }

                } catch (error) {
                    console.error('Error fetching data:', error);
                }
            } else {
                setTaskAddedOn(DateService.dateToUkDateTime(new Date()));
            }
        };

        fetchWorkflowUsers();
        fetchStages();
        fetchTaskInfo();
    }, [props.entityId]);


    const validateEntity = (task: IApp03Task): Promise<TaskFormValidations> => {
        const errors = new TaskFormValidations();

        if (task.Title === null || task.Title === '') {
            errors.Title = `Task name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (task.App03TaskUsers === undefined || task.App03TaskUsers === null || task.App03TaskUsers.length < 1) {
            errors.AssignedTo = `At least one assignee is required`;
            errors.Valid = false;
        }
        else {
            errors.AssignedTo = null;
        }


        return Promise.resolve(errors);
    };

    const isViewOnlyPermission = (): boolean => {
        return false;
    }

    const handleEvidence_ChangeFilterText = (value: string): void => {
        setEvidence_ListFilterText(value);
    }

    const taskPriorityArray = Object.keys(TaskPriority)
        .filter(key => !isNaN(Number(TaskPriority[key])))
        .map(key => ({ ID: TaskPriority[key], Title: key }));

    return (
        <>
            <EntityFormPage<IApp03Task, TaskFormValidations>
                {...props}
                entityName='Task'
                renderFormFields={(changeHandlers, formState) => {
                    const { FormData: formData, ValidationErrors: errors } = formState;


                    const commandBarItems: ICommandBarItemProps[] = [
                        {
                            key: 'Close',
                            text: 'Close',
                            iconProps: { iconName: 'Cancel' },
                            onClick: (ev, item) => {
                                props.onCancelled();
                            },
                        },
                    ].filter(Boolean);

                    //Labels row 3
                    const stageLabel = <div className='two-fields-sidebyside-lbl'>
                        <div className={styles.flexContainerSectionQuestion}>
                            <div className={styles.sectionQuestionCol1}><span>Stage</span></div>
                            <div className={styles.sectionQuestionCol2}>
                                {/* <img src={reqCostCentreValidationImg} /> */}
                            </div>
                        </div>
                    </div>;

                    const priotityLabel = <div className='two-fields-sidebyside-lbl'>
                        <div className={styles.flexContainerSectionQuestion}>
                            <div className={styles.sectionQuestionCol1}><span>Priority</span></div>
                            <div className={styles.sectionQuestionCol2}>
                                {/* <img src={reqDirectorateIdValidationImg} /> */}
                            </div>
                        </div>
                    </div>;

                    //Labels row 4
                    const startDateLabel = <div className='two-fields-sidebyside-lbl'>
                        <div className={styles.flexContainerSectionQuestion}>
                            <div className={styles.sectionQuestionCol1}><span>Start Date</span></div>
                            <div className={styles.sectionQuestionCol2}>
                                {/* <img src={reqCostCentreValidationImg} /> */}
                            </div>
                        </div>
                    </div>;

                    const endDateLabel = <div className='two-fields-sidebyside-lbl'>
                        <div className={styles.flexContainerSectionQuestion}>
                            <div className={styles.sectionQuestionCol1}><span>End Date</span></div>
                            <div className={styles.sectionQuestionCol2}>
                                {/* <img src={reqDirectorateIdValidationImg} /> */}
                            </div>
                        </div>
                    </div>;

                    //Labels row 5
                    const assignedToLabel = <div className='two-fields-sidebyside-lbl'>
                        <div className={styles.flexContainerSectionQuestion}>
                            <div className={styles.sectionQuestionCol1}><span>Assigned To</span></div>
                            <div className={styles.sectionQuestionCol2}>
                                {/* <img src={reqCostCentreValidationImg} /> */}
                            </div>
                        </div>
                    </div>;

                    const externalRefLabel = <div className='two-fields-sidebyside-lbl'>
                        <div className={styles.flexContainerSectionQuestion}>
                            <div className={styles.sectionQuestionCol1}><span>External Ref</span></div>
                            <div className={styles.sectionQuestionCol2}>
                                {/* <img src={reqDirectorateIdValidationImg} /> */}
                            </div>
                        </div>
                    </div>;


                    return (
                        <div>
                            {/* <CrLoadingOverlay isLoading={isLoading} opaque={true} />*/}
                            <CrCommandBar items={commandBarItems} />
                            <div className='tasks-info'>
                                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                                    <div className={styles.sectionATitle}>
                                        {props.entityId > 0 ? taskInfo?.Title : 'New Task'}
                                    </div>
                                </div>
                            </div>
                            <div className="info-table-container">
                                <table className="info-table" cellSpacing="0" cellPadding="10">
                                    <tbody>
                                        <tr>
                                            <td className="label">
                                                Workflow
                                            </td>
                                            <td className="value">
                                                {props.workflowTitle}
                                            </td>
                                            <td className="label">
                                                Added By
                                            </td>
                                            <td className="value">
                                                {props.entityId > 0
                                                    ? taskInfo?.['AddedBy']?.['Title']
                                                    : ''
                                                }
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="label">
                                                Stage
                                            </td>
                                            <td className="value">
                                                {props.entityId > 0
                                                    ? taskInfo?.WorkflowStage?.Title
                                                    : workflowStages?.find(s => s.ID === props.newTaskStageID)?.Title || ''}

                                            </td>
                                            <td className="label">
                                                Added On
                                            </td>
                                            <td className="value">
                                                {taskAddedOn}
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            {/* Form Fields */}
                            <div>
                                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                                    <div style={{ display: 'flex' }}>
                                        <div className={styles.sectionATitle}>Details</div>
                                    </div>
                                </div>
                                {
                                    <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                                        {/* 1st row */}
                                        <div className={styles.formField}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '100%', fontWeight: 'bold' }}>
                                                    <div className={styles.flexContainerSectionQuestion}>
                                                        <div className={styles.sectionQuestionCol1}><span>Task</span></div>
                                                        <div className={styles.sectionQuestionCol2}>

                                                            {/* inner flex to line 2 images */}
                                                            <div style={{ display: 'flex' }}>
                                                                {/* <div><img src={reqWorkPurposeValidationImg} /></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginTop: '5px' }}>
                                                <div style={{ width: '100%' }}>
                                                    <CrTextField
                                                        maxLength={300}
                                                        value={formData.Title}
                                                        onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                                                        errorMessage={errors.Title}
                                                        required={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* 2nd row */}
                                        <div className={styles.formField}>
                                            <div style={{ display: 'flex' }}>
                                                <div style={{ width: '100%', fontWeight: 'bold' }}>
                                                    <div className={styles.flexContainerSectionQuestion}>
                                                        <div className={styles.sectionQuestionCol1}><span>Details</span></div>
                                                        <div className={styles.sectionQuestionCol2}>

                                                            {/* inner flex to line 2 images */}
                                                            <div style={{ display: 'flex' }}>
                                                                {/* <div><img src={reqWorkPurposeValidationImg} /></div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: 'flex', marginTop: '5px' }}>
                                                <div style={{ width: '100%' }}>
                                                    <CrTextField
                                                        multiline
                                                        rows={6}
                                                        maxLength={10000}
                                                        value={formData.Details}
                                                        onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Details')}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* 3rd row */}

                                        <div className={styles.formField}>
                                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                                {stageLabel}
                                                {priotityLabel}
                                            </div>}
                                            <div className='two-fields-sidebyside-r2'>
                                                {isMobile && stageLabel}
                                                <div className='two-fields-sidebyside-field'>
                                                    <CrDropdown
                                                        options={LookupService.entitiesToSelectableOptions(workflowStages)}
                                                        selectedKey={formData.WorkflowStageID}
                                                        onChange={(_, o) => changeHandlers.changeDropdown(o, 'WorkflowStageID')}
                                                    />
                                                </div>
                                                {isMobile && priotityLabel}
                                                <div className='two-fields-sidebyside-field'>
                                                    <CrDropdown
                                                        options={LookupService.entitiesToSelectableOptions(taskPriorityArray)}
                                                        selectedKey={formData.TaskPriorityID}
                                                        onChange={(_, o) => changeHandlers.changeDropdown(o, 'TaskPriorityID')}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* 4th row */}
                                        <div className={styles.formField}>
                                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                                {startDateLabel}
                                                {endDateLabel}
                                            </div>}
                                            <div className='two-fields-sidebyside-r2'>
                                                {isMobile && startDateLabel}
                                                <div className='two-fields-sidebyside-field'>
                                                    <CrDatePicker
                                                        maxWidth='100%'
                                                        value={DateService.removeTimezoneOffset(formData.StartDate)}
                                                        onSelectDate={date => changeHandlers.changeDatePicker(date, 'StartDate')}
                                                    />
                                                </div>
                                                {isMobile && endDateLabel}
                                                <div className='two-fields-sidebyside-field'>
                                                    <CrDatePicker
                                                        maxWidth='100%'
                                                        value={DateService.removeTimezoneOffset(formData.EndDate)}
                                                        minDate={DateService.removeTimezoneOffset(formData.StartDate)}
                                                        onSelectDate={date => changeHandlers.changeDatePicker(date, 'EndDate')}
                                                    //errorMessage={errors.EndDate}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        {/* 5th row */}
                                        <div className={styles.formField}>
                                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                                {assignedToLabel}
                                                {externalRefLabel}
                                            </div>}
                                            <div className='two-fields-sidebyside-r2'>
                                                {isMobile && assignedToLabel}
                                                <div className='two-fields-sidebyside-field'>
                                                    <CrUserPicker
                                                        users={workflowUsers}
                                                        itemLimit={10}
                                                        selectedUsers={formData.App03TaskUsers?.map(u => u.UserID)}
                                                        onChange={v => changeHandlers.changeMultiUserPicker(v, 'App03TaskUsers', new App03TaskUser(), 'UserID')}
                                                        required={true}
                                                        errorMessage={errors.AssignedTo}
                                                    />
                                                </div>
                                                {isMobile && externalRefLabel}
                                                <div className='two-fields-sidebyside-field'>
                                                    <CrTextField
                                                        maxLength={300}
                                                        value={formData.ExternalRef}
                                                        onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'ExternalRef')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        {/* 6th row */}
                                        {/* <div>
                                        <div style={{ display: 'flex', marginTop: '5px' }}>
                                            <div style={{ width: '100%' }}>
                                                {props.entityId > 0 && showArchived === true && <CrCheckbox
                                                    className={`${styles.formField} ${styles.checkbox}`}
                                                    label="Move to archived"
                                                    checked={formData.Archived}
                                                    onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'Archived')}
                                                />}
                                            </div>
                                        </div>
                                    </div> */}
                                    </div>
                                }

                                {/* <div style={{ marginTop: '10px' }}>
                                <PrimaryButton text="Save" className={styles.formButton} style={{ marginRight: '5px' }}
                                    onClick={() => props.onCancelled()}
                                />
                                <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                                    onClick={() => props.onCancelled()}
                                />
                            </div> */}

                            </div>


                        </div>
                    );
                }}
                loadEntity={id => dataServices.app03TaskService.read(id, true, true)}
                onAfterLoad={(task) => {
                    setShowArchived(task.Archived === true ? false : true);
                }}
                loadNewEntity={() => new App03Task(props.workflowID, props.newTaskStageID)}
                loadEntityValidations={() => new TaskFormValidations()}
                onValidateEntity={validateEntity}
                onCreate={t => dataServices.app03TaskService.create(t)}
                onUpdate={t => dataServices.app03TaskService.update(t.ID, t)}
                parentEntities={dataServices.app03TaskService.parentEntities}
                childEntities={[
                    {
                        ObjectParentProperty: 'App03TaskUsers',
                        ParentIdProperty: 'TaskID',
                        ChildIdProperty: 'UserID',
                        ChildService: dataServices.app03TaskUserService
                    },
                ]}
            />
            {props.entityId > 0 &&
                <>
                    <div>
                        <div style={{ marginBottom: '20px', marginTop: '50px' }}>
                            <div style={{ display: 'flex' }}>
                                <div className={styles.sectionATitle}>Task Discussion, General Comments and File Attachments</div>
                            </div>
                        </div>
                    </div>
                    <div>

                        <div style={{ minHeight: '120px' }}>
                            <EvidenceList
                                taskID={props.entityId}
                                currentUserId={currentUserId}
                                superUserPermission={userPermissions.UserIsSystemAdmin() || userPermissions.UserIsCLSuperUser()}
                                isViewOnly={isViewOnlyPermission()}
                                filterText={evidence_ListFilterText}
                                onChangeFilterText={handleEvidence_ChangeFilterText}
                                evChangesCounter={1}
                                {...props}
                                errorHandling={props.errorHandling}
                            />
                        </div>

                    </div>
                </>
            }

        </>
    );
};
