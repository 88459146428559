import { EntityService } from './EntityService';
import { IEntity, INAOPublication, INAOPublicationInfo } from '../types';

export class NAOPublicationService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/NAOPublications`);
    }

    public readWithExpandDirectorates(ID: number): Promise<INAOPublication> {
        let entitiesToExpand: string[] = [];
        entitiesToExpand.push("NAOPublicationDirectorates($expand=Directorate)");

        return this.read(ID, false, false, entitiesToExpand).then((e: INAOPublication): INAOPublication => {
            return e;
        });
    }

    public readAllWithFilters(dgAreaId: number | string, incompleteOnly: boolean, justMine: boolean, isArchive: boolean): Promise<IEntity[]> {
        return this.readAll(`?dgAreaId=${dgAreaId}&incompleteOnly=${incompleteOnly}&justMine=${justMine}&isArchive=${isArchive}`);
    }

    public countAllWithFilters(dgAreaId: number | string, incompleteOnly: boolean, justMine: boolean, isArchive: boolean): Promise<string> {
        return this.readString(`?getUpdateCount=true&dgAreaId=${dgAreaId}&incompleteOnly=${incompleteOnly}&justMine=${justMine}&isArchive=${isArchive}`);
    }

    public getPublicationInfo(naoPublicationId: number): Promise<INAOPublicationInfo> {
        return this.readEntity(`?naoPublicationId=${naoPublicationId}&getInfo=true`);
    }

    public readOverAllUpdateStatus(isArchive: boolean, dgAreaId: number, naoPeriodId: number): Promise<string> {
        return super.readString(`?getOverallUpdateStatus=true&dgAreaId=${dgAreaId}&naoPeriodId=${naoPeriodId}&archived=${isArchive}`).then((result: string): string => {
            return result;
        });
    }
}