import React from 'react';
import styles from '../styles/cr.module.scss';
import { DashboardRenderer } from './dashboards/DashboardRenderer';

interface InsightsProps {
    dashboardRef: string;
}

export const Insights: React.FC<InsightsProps> = ({ dashboardRef }) => {
    return (
        <div className={`${styles.cr} dashboard-container`}>
            <DashboardRenderer dashboardRef={dashboardRef} />
        </div>
    );
};
