import React, { useContext } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import EmailSettingsCls from './EmailSettingsCls';
import { OrbUserContext } from '../OrbUserContext';


export interface IEmailSettingsProps extends IUseApiProps, IWithErrorHandlingProps {
}

const EmailSettings = (props: IEmailSettingsProps) => {
    const { userPermissions, userContext } = useContext(OrbUserContext);
    return (
        <ErrorBoundary>
            <EmailSettingsCls
                userPermissions={userPermissions}
                //userContext={userContext}
                {...props}
            />
        </ErrorBoundary>
    );

};

export default withErrorHandling(EmailSettings);
