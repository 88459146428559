import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class ZCoreEmailOutboxService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/ZCoreEmailOutboxes`);
    }

    public deleteItems(itemIds: string): Promise<string> {
        return super.readString(`?itemIds=${itemIds}`).then((result: string): string => {
            return result;
        });
    }
}