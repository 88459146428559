import React, { useContext, useState } from 'react';
import { ErrorBoundary } from '../ErrorBoundary';
import { IWithErrorHandlingProps, withErrorHandling } from '../withErrorHandling';
import { IUseApiProps } from '../useApi';
import { OrbUserContext } from '../OrbUserContext';
import { CrTextField } from '../cr/CrTextField';
import { FormButtons } from '../cr/FormButtons';
import { DataContext } from '../DataContext';
import styles from '../../styles/cr.module.scss';

export interface IDevTestProps extends IUseApiProps, IWithErrorHandlingProps {
}

const DevTest = (props: IDevTestProps) => {
  const { userPermissions, userContext } = useContext(OrbUserContext);
  const [url, setUrl] = useState<string>('');
  const { dataServices: { logService }, } = useContext(DataContext);


  const handlePrimaryClick = async (): Promise<void> => {
    const result = await logService.readAnyOData(url);
    console.log('result', result);
  }

  return (
    <ErrorBoundary>
      <div className={`${styles.cr} `}>
        <div style={{ maxWidth: '600px', marginRight: '10px', marginTop: '30px' }}>
          <div>
            Examples:
            <br/>
            /users
            <br/>
            /users?$top=2&$skip=1
            <br/><br/><br/>
          </div>
          <CrTextField
            label="Url"
            value={url}
            onChange={(ev, newValue) => setUrl(newValue)}
          />
          <FormButtons
            onPrimaryClick={handlePrimaryClick}
            primaryText='Run'
          />
        </div>
      </div>
    </ErrorBoundary>
  );

};

export default withErrorHandling(DevTest);
