import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataContext";
import { IDashboardData } from "../../types";
import { GraphRenderer } from "../charts/GraphRenderer";
import { useContentStyle } from "../ContentStyleContext";
import useScreenSizeDetector from "../useScreenSizeDetector";

interface DashboardRendererProps {
    dashboardRef: string;
}

export const DashboardRenderer = (props: DashboardRendererProps): React.ReactElement => {
    const { isMediumScreen, isLargeScreen } = useScreenSizeDetector();
    const { dataServices: { zCoreDashboardService } } = useContext(DataContext);
    const [dashboardData, setDashboardData] = useState<IDashboardData | null>(null);
    const [bgColor, setBGColor] = useState<string>('#ffffff');
    const { setContentStyle } = useContentStyle();
    const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);
    const [showNoDataMessage, setShowNoDataMessage] = useState<boolean>(false);

    useEffect(() => {
        if (setContentStyle) {
            setContentStyle({
                padding: '10px',
                width: 'calc(100% - 18px)',
                backgroundColor: bgColor,
                minHeight: '700px'
            });
        }

        return () => {
            if (setContentStyle) {
                setContentStyle(undefined);
            }
        };
    }, [setContentStyle, bgColor]);

    useEffect(() => {
        const fetchData = async () => {
            const dbData: IDashboardData = await zCoreDashboardService.getDashboardData(props.dashboardRef);
            if (!dbData) {
                console.log('Dashboard data not available');
                setShowNoDataMessage(true);
                return;
            }
            setBGColor(dbData.backgroundColor || '#ffffff');
            setDashboardData(dbData);
        };

        fetchData();
    }, [props.dashboardRef]);

    // Determine the correct slides to display based on screen size
    const slides = isLargeScreen
        ? dashboardData?.largeScreenSlides
        : dashboardData?.mediumScreenSlides;

    // Reset selected slide index when the screen size changes
    useEffect(() => {
        setSelectedSlideIndex(0);
    }, [isLargeScreen, isMediumScreen]);

    return (
        <>
            {showNoDataMessage && <div style={{ margin: '20px' }}>Currently, there are no insights available. Please check back later.</div>}

            {/* Render a button for each slide */}
            <div className="dashboard-header">
                <div>
                    {slides?.map((s, index) => (
                        <button
                            key={index}
                            onClick={() => setSelectedSlideIndex(index)}
                            className={index === selectedSlideIndex ? 'dashboard-num-btn-selected' : 'dashboard-num-btn'}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
                <div className="dashboard-header-title">
                    <span>{slides?.[selectedSlideIndex]?.slideTitle || ""}</span>
                </div>
            </div>

            {/* Render the selected slide based on screen size */}
            {slides?.[selectedSlideIndex]?.cells.map((cell, cellIndex) => (
                <div
                    key={`${selectedSlideIndex}-${cellIndex}`}
                    style={{
                        '--box-width': `${cell.cellWidth}px`,
                        '--box-height': `${cell.cellHeight}px`,
                        '--box-left': `${cell.leftMargin}px`,
                        '--box-top': `${cell.topMargin}px`,
                        backgroundColor: `${cell.cellBackgroundColor}`,
                    } as React.CSSProperties}
                    className='dashboard-box'
                >
                    <GraphRenderer graphShortname={cell.graphRef} />
                </div>
            ))}
        </>
    );
};






















// import React, { useContext, useEffect, useState } from "react";
// import { DataContext } from "../DataContext";
// import { IDashboardData } from "../../types";
// import { GraphRenderer } from "../charts/GraphRenderer";
// import { useContentStyle } from "../ContentStyleContext";
// import useScreenSizeDetector from "../useScreenSizeDetector";

// interface DashboardRendererProps {
//     dashboardRef: string;
// }

// export const DashboardRenderer = (props: DashboardRendererProps): React.ReactElement => {
//     const { isMediumScreen, isLargeScreen } = useScreenSizeDetector();
//     const { dataServices: { zCoreDashboardService } } = useContext(DataContext);
//     const [dashboardData, setDashboardData] = useState<IDashboardData | null>(null);
//     const [bgColor, setBGColor] = useState<string>('#ffffff');
//     const { setContentStyle } = useContentStyle();
//     const [selectedSlideIndex, setSelectedSlideIndex] = useState<number>(0);
//     const [showNoDataMessage, setShowNoDataMessage] = useState<boolean>(false);

//     useEffect(() => {
//         if (setContentStyle) {
//             setContentStyle({
//                 padding: '10px',
//                 width: 'calc(100% - 18px)',
//                 backgroundColor: bgColor,
//                 minHeight: '700px'
//             });
//         }

//         return () => {
//             if (setContentStyle) {
//                 setContentStyle(undefined);
//             }
//         };
//     }, [setContentStyle, bgColor]);

//     useEffect(() => {
//         const fetchData = async () => {
//             const dbData: IDashboardData = await zCoreDashboardService.getDashboardData(props.dashboardRef);
//             if (!dbData) {
//                 console.log('Dashboard data not available');
//                 setShowNoDataMessage(true);
//                 return;
//             }
//             setBGColor(dbData.backgroundColor || '#ffffff');
//             setDashboardData(dbData);
//         };

//         fetchData();
//     }, [props.dashboardRef]);

//     // Determine the correct slides to display based on screen size
//     const slides = isLargeScreen
//         ? dashboardData?.largeScreenSlides
//         : dashboardData?.mediumScreenSlides;

//     // Reset selected slide index when the screen size changes
//     useEffect(() => {
//         setSelectedSlideIndex(0);
//     }, [isLargeScreen, isMediumScreen]);

//     return (
//         <>
//             {showNoDataMessage && <div style={{ margin: '20px' }}>Currently, there are no insights available. Please check back later.</div>}
//             {/* Render a button for each slide */}
//             <div className="dashboard-header">
//                 <div>
//                     {slides?.map((s, index) => (
//                         <button
//                             key={index}
//                             onClick={() => setSelectedSlideIndex(index)}
//                             className={index === selectedSlideIndex ? 'dashboard-num-btn-selected' : 'dashboard-num-btn'}
//                         >
//                             {index + 1}
//                         </button>
//                     ))}
//                 </div>
//                 <div className="dashboard-header-title">
//                     <span>Here we need selected slide title ie s.slideTitle</span>
//                 </div>
//             </div>

//             {/* Render the selected slide based on screen size */}
//             {slides?.[selectedSlideIndex]?.cells.map((cell, cellIndex) => (
//                 <div
//                     key={`${selectedSlideIndex}-${cellIndex}`}
//                     style={{
//                         '--box-width': `${cell.cellWidth}px`,
//                         '--box-height': `${cell.cellHeight}px`,
//                         '--box-left': `${cell.leftMargin}px`,
//                         '--box-top': `${cell.topMargin}px`,
//                         backgroundColor: `${cell.cellBackgroundColor}`,
//                     } as React.CSSProperties}
//                     className='dashboard-box'
//                 >
//                     <GraphRenderer graphShortname={cell.graphRef} />
//                 </div>
//             ))}
//         </>
//     );
// };
