import React, { useContext, useEffect, useMemo, useState } from 'react';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import styles from '../../styles/cr.module.scss';
import axios from 'axios';
import { DataContext } from '../DataContext';
import { UserAppLaunchLog } from '../../types';
import { AppIDs } from '../../AppGlobals';
import { useHistory } from 'react-router-dom';
import { PrimaryButton } from '@fluentui/react';
import { GraphRenderer } from '../charts/GraphRenderer';
import { AppWelcomeButtons } from '../AppWelcomeButtons';

interface NaoWelcomeProps {
}

export interface IAppWelcomeText {
    CenteralHeading: string;
    CenteralBody: string;
    CustomerWelcomeHeading: string;
    CustomerWelcomeBody: string;
}

export const NaoWelcome: React.FC<NaoWelcomeProps> = ({ }) => {
    const { dataServices } = useContext(DataContext);
    const [formData, setFormData] = useState<IAppWelcomeText>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isGraphDataAvailable, setIsGraphDataAvailable] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const zedxNetapiURL = process.env.REACT_APP_ZedX_Net_API!;
                const zedxNetWelcomeTextApi: string = `${zedxNetapiURL}/availableapps/GetAppWelcomeText/NAO`;
                const centeralWelcomeText = (await axios.get(zedxNetWelcomeTextApi)).data;
                const centeralWelcomeHeading = centeralWelcomeText.WelcomeHeading;
                const centeralWelcomeBody = centeralWelcomeText.WelcomeBody;

                let customerWelcomeHeading: string = '';
                let customerWelcomeBody: string = '';

                const naoDefForm = await dataServices.naoDefFormService.readAll();
                if (naoDefForm.length > 0) {
                    customerWelcomeHeading = naoDefForm[0].Title ?? '';
                    customerWelcomeBody = naoDefForm[0].Details ?? '';
                }

                setFormData({
                    CenteralHeading: centeralWelcomeHeading,
                    CenteralBody: centeralWelcomeBody,
                    CustomerWelcomeHeading: customerWelcomeHeading,
                    CustomerWelcomeBody: customerWelcomeBody
                });

                dataServices.userAppLaunchLogService.create(new UserAppLaunchLog(AppIDs.NAO));

                setLoading(false);
            } catch (error) {
                console.error('Error loading entity:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const formattedCenteralBody = useMemo(() => {
        if (!formData) return '';

        return formData.CenteralBody ? formData.CenteralBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const formattedCustomerBody = useMemo(() => {
        if (!formData) return '';

        return formData.CustomerWelcomeBody ? formData.CustomerWelcomeBody.replace(/\n/g, '<br>') : '';
    }, [formData]);

    const handleUpdatesClick = () => {
        history.push('/nao-tracker/updates');
    };
    const handleInsightsClick = () => {
        history.push('/nao-tracker/insights');
    };

    return (
        <div style={{ maxWidth: '1200px' }} className={styles.cr}>
            <CrLoadingOverlay isLoading={loading} opaque={true} />
            {formData && (
                <>
                    <h1 style={{ textAlign: 'center', marginLeft: '20px' }} className={styles.welcomeL1Heading}>{formData.CenteralHeading}</h1>
                    <div className="welcomePage-container">
                        <div className="welcomePage-leftDiv">
                            <div>
                                <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCenteralBody }} />
                            </div>
                            <div>
                                {formData.CustomerWelcomeHeading?.length > 0 && <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} className={styles.welcomeBody}>{formData.CustomerWelcomeHeading}</div>}
                            </div>
                            <div>
                                {formattedCustomerBody?.length > 0 && <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCustomerBody }} />}
                            </div>
                            <br />
                            <AppWelcomeButtons
                                updatesButtonText='NAO/PAC Updates'
                                onUpdatesClick={handleUpdatesClick}
                                onInsightsClick={handleInsightsClick}
                            />
                        </div>
                        <div className="welcomePage-rightDiv">
                            {isGraphDataAvailable && (
                                <div className="welcomePage-chartHeading">
                                    Chart shows recommendation statuses for all the publications across the department
                                </div>
                            )}
                            <GraphRenderer
                                graphShortname={'NAORecStatusesDonutChart'}
                                onGraphDataFetch={(dataAvailable) => setIsGraphDataAvailable(dataAvailable)}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>

    );

    // return (

    //     <div className={styles.cr}>
    //         <CrLoadingOverlay isLoading={loading} opaque={true} />
    //         {formData && (
    //             <>
    //                 <h1 className={styles.welcomeL1Heading}>{formData.CenteralHeading}</h1>
    //                 <div>
    //                     <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCenteralBody }} />
    //                 </div>
    //                 <div>
    //                     {formData.CustomerWelcomeHeading?.length > 0 && <div style={{ fontWeight: 'bold', textDecoration: 'underline' }} className={styles.welcomeBody}>{formData.CustomerWelcomeHeading}</div>}
    //                 </div>
    //                 <div>
    //                     {formattedCustomerBody?.length > 0 && <div className={styles.welcomeBody} dangerouslySetInnerHTML={{ __html: formattedCustomerBody }} />}
    //                 </div>
    //             </>
    //         )}
    //     </div>

    // );
};