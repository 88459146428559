import { IconButton } from '@fluentui/react';
import React from 'react';
import { RagColours } from '../../refData/RagColours';
import { DateService } from '../../services';
import { PreviousRagIndicator } from './PreviousRagIndicator';
import { RagIndicator } from './RagIndicator';
import { ReviewListProgressUpdate } from './ReviewListProgressUpdate';
import styles from '../../styles/cr.module.scss';
import { WorkflowType } from '../../refData/WorkflowType';

export const renderEditButton = (onEdit: (entityId: number, entityUpdateId: number) => void, entityId: number, entityUpdateId: number, showEditButton: boolean): JSX.Element => {
    return showEditButton &&
        <IconButton iconProps={{ iconName: 'Edit' }} title='Edit' onClick={() => onEdit(entityId, entityUpdateId)} />;
}

export const renderProgressUpdate = ({ title, comment, author, date, toBeClosed, attributes, previousComment }
    : { title: string, comment: string, author: string, date: Date, toBeClosed: boolean, attributes?: string[], previousComment?: string }): JSX.Element => {
    return (
        <ReviewListProgressUpdate
            entityName={title}
            progressUpdate={comment}
            updateAuthor={author}
            updateDate={date}
            toBeClosed={toBeClosed}
            attributes={attributes}
            previousUpdate={previousComment}
        />
    );
}

export const renderDate = (date: Date | string, noDateText?: string): JSX.Element => {
    const parsedDate = date instanceof Date ? date : typeof date === 'string' ? new Date(date) : null;

    if (!isValidDate(parsedDate)) {
        return <span>{noDateText || 'N/A'}</span>;
    }

    return (
        <span>
            {DateService.dateToUkDate(parsedDate)}
        </span>
    );
};

export const renderDateWithTime = (date: Date | string, noDateText?: string): JSX.Element => {
    const parsedDate = date instanceof Date ? date : typeof date === 'string' ? new Date(date) : null;

    if (!isValidDate(parsedDate)) {
        return <span>{noDateText || 'N/A'}</span>;
    }

    return (
        <span>
            {DateService.dateToUkDateTime(parsedDate)}
        </span>
    );
};

const isValidDate = (date: Date | null): date is Date => {
    return date instanceof Date && !isNaN(date.getTime());
};


export const renderRag = (rag: RagColours): JSX.Element => <RagIndicator rag={rag} />

export const renderPreviousRag = (rag: RagColours): JSX.Element => <PreviousRagIndicator rag={rag} />

export const renderPortfolioIcons = (purpose: string, containsValue?: string): JSX.Element => {
    const imagesRoot: string = process.env.REACT_APP_ZedXImagesRoot!;
    const portfolioGeneralManagement: string = `${imagesRoot}portfolio-general-management.png`;
    const portfolioVisionRealisation: string = `${imagesRoot}portfolio-vision-realisation.png`;

    const portfolioContainsProjects: string = `${imagesRoot}portfolio-contains-projects.png`;
    const portfolioContainsPortfolios: string = `${imagesRoot}portfolio-contains-portfolios.png`;

    let portfolioPurposeIcon: string = '';
    let portfolioPurposeAlt: string = '';

    if (purpose === 'Vision realisation') {
        portfolioPurposeIcon = portfolioVisionRealisation;
        portfolioPurposeAlt = 'Vision realisation';
    }
    else {
        portfolioPurposeIcon = portfolioGeneralManagement;
        portfolioPurposeAlt = 'General Management';
    }

    let portfolioContainsIcon: string = '';
    let portfolioContainsAlt: string = '';

    if (containsValue === 'Portfolios') {
        portfolioContainsIcon = portfolioContainsPortfolios;
        portfolioContainsAlt = 'Portfolios';
    }
    else {
        portfolioContainsIcon = portfolioContainsProjects;
        portfolioContainsAlt = 'Programmes & Projects';
    }


    return (
        <div className={styles.indicatorsContainer}>
            <div className={styles.portfolioIconBox}><img src={portfolioPurposeIcon} alt={portfolioPurposeAlt} title={portfolioPurposeAlt} /></div>
            {containsValue && <div className={styles.portfolioIconBox}><img src={portfolioContainsIcon} alt={portfolioContainsAlt} title={portfolioContainsAlt} /></div>}
        </div>
    );
};

const indicatorCssMap = new Map<number, string>([
    [5, styles.greenIndicator],
    [4, styles.amberGreenIndicator],
    [3, styles.amberIndicator],
    [2, styles.amberRedIndicator],
    [1, styles.redIndicator],
]);

const getIndicatorInfo = (ratingFor: string, indicatorID: number): { cssClass: string, title: string } => {
    const cssClass = indicatorCssMap.get(indicatorID) || '';
    let title = `${ratingFor}: Undefined rating`;

    switch (indicatorID) {
        case 5:
            title = `${ratingFor}: Green\nLikely to meet the agreed outcome - no current outstanding issues affecting delivery`;
            break;
        case 4:
            title = `${ratingFor}: Amber Green\nWill probably meet the agreed outcome - no current major issues affecting delivery`;
            break;
        case 3:
            title = `${ratingFor}: Amber\nWill possibly meet the agreed outcome, but resolvable issues need management attention. Action these quickly to avoid major long-term problems`;
            break;
        case 2:
            title = `${ratingFor}: Amber Red\nDoubtful the agreed outcome will be met - major issues in a number of key areas. Urgent action required to find out if a resolution is possible`;
            break;
        case 1:
            title = `${ratingFor}: Red\nImpossible to meet the agreed outcome - major issues which do not appear to be currently manageable or resolvable`;
            break;
    }

    return { cssClass, title };
};

export const renderIndicators = (overallRagOptionID: number, financeRagOptionID: number, peopleRagOptionID: number, milestonesRagOptionID: number, benefitsRagOptionID: number): JSX.Element => {
    const indicatorsInfo = [
        getIndicatorInfo('Delivery confidence', overallRagOptionID),
        getIndicatorInfo('Finance', financeRagOptionID),
        getIndicatorInfo('People', peopleRagOptionID),
        getIndicatorInfo('Milestones', milestonesRagOptionID),
        getIndicatorInfo('Benefits', benefitsRagOptionID),
    ];

    return (
        <div className={styles.indicatorsContainer}>
            {indicatorsInfo.map((info, index) => (
                <div key={index} className={`${styles.indicatorBox} ${info.cssClass}`} title={info.title}></div>
            ))}
        </div>
    );
};

export const renderWorkflowIcon = (workflowTypeID): JSX.Element => {
    const imagesRoot: string = process.env.REACT_APP_ZedXImagesRoot!;
    const generalWorkflow: string = `${imagesRoot}general-workflow.png`;
    const linkedWorkflow: string = `${imagesRoot}linked-workflow.png`;


    let wfIcon: string = '';
    let wfIconAlt: string = '';

    if (workflowTypeID === WorkflowType.General) {
        wfIcon = generalWorkflow;
        wfIconAlt = 'General workflow';
    }
    else {
        wfIcon = linkedWorkflow;
        wfIconAlt = 'Linked workflow';
    }

    return (
        <div className={styles.indicatorsContainer}>
            <div className={styles.portfolioIconBox}><img src={wfIcon} alt={wfIconAlt} title={wfIconAlt} /></div>
        </div>
    );
};

export const renderStage = (stage: string, stageColor: string): JSX.Element => {
    if (stage == null || stage == undefined)
        return null;

    let stageClassName: string;

    switch (stage) {
        case 'Awaiting':
            stageClassName = styles.stageAwaiting;
            break;
        case 'InProgress':
            stageClassName = styles.stageInProgress;
            break;
        case 'Completed':
            stageClassName = styles.stageCompleted;
            break;
        default:
            stageClassName = '';
            break;
    }

    return (
        <div className={styles.stageContainer}>
            <div className={styles.stageText}>{stage}</div>
            <div className={`${styles.stageBottomBar}`} style={{ backgroundColor: stageColor }} ></div>
            {/* <div className={`${styles.stageBottomBar} ${stageClassName}`}></div> */}
        </div>
    );
};


export const renderDateRange = (startDate: Date | string, endDate: Date | string): JSX.Element => {
    const parsedStartDate = startDate instanceof Date ? startDate : typeof startDate === 'string' ? new Date(startDate) : null;
    const parsedEndtDate = endDate instanceof Date ? endDate : typeof endDate === 'string' ? new Date(endDate) : null;
    if (parsedStartDate === null && parsedEndtDate === null)
        return null;

    const startDateStr: string = parsedStartDate ? DateService.dateToUkShortDate(parsedStartDate) : '\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0'
    const endDateStr: string = parsedEndtDate ? DateService.dateToUkShortDate(parsedEndtDate) : ''
    return (
        <div className={styles.dateRangeContainer}>
            <div className={styles.dateRangeText}>{startDateStr} - {endDateStr}</div>
            <div className={styles.dateRangeBarsContainer}>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
                <div className={styles.dateRangeSmallBar}></div>
            </div>
        </div>
    );
};

export const renderWithBackgroundColor = (contents: string, backgroundColor: string, textColor: string): JSX.Element => {
    if (contents == null || contents == undefined || contents === '0/0')
        return null;

    return (
        <div style={{ backgroundColor: backgroundColor, color: textColor, textAlign: 'center' }}>{contents}</div>
    );
};

export const renderTaskPriority = (priority: string, priorityColor: string): JSX.Element => {
    if (priority == null || priority == undefined)
        return null;

    return (
        <div className={styles.stageContainer}>
            <div className={styles.stageText}>{priority}</div>
            <div className={`${styles.stageBottomBar}`} style={{ backgroundColor: priorityColor }} ></div>
        </div>
    );
};

export const renderAsLink = (ID: number, title: string, onTitleClick: (ID: number) => void): JSX.Element => {
    if (ID == null || title == undefined)
        return null;

    return (
        <span>
            <a
                className="titleLnk"
                onClick={(ev) => onTitleClick(ID)}
            >
                {title}
            </a>
        </span>
    );
};
