import { EntityService } from './EntityService';
import { IEntity, IAutomationOption } from '../types';

export class ZCoreAutomationOptionService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/ZCoreAutomationOptions`);
    }

    public processAsAutoFunction(): Promise<string> {
        return super.readString(`?processAsAutoFunction=`).then((result: string): string => {
            return result;
        });
    }

    public processAsAutoFunctionFromOutbox(): Promise<string> {
        return super.readString(`?processAsAutoFunctionFromOutbox=&sendFromOutbox=`).then((result: string): string => {
            return result;
        });
    }

    public readAllTemplateIds(): Promise<IEntity[]> {
        return super.readAll(`?$select=Title,NotifyTemplateId`);
    }
}