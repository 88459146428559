import { useState, useEffect } from 'react';

export interface ScreenSize {
  isMobile: boolean;
  isMediumScreen: boolean;
  isLargeScreen: boolean;
}

const useScreenSizeDetector = () => {
  const [screenSize, setScreenSize] = useState<ScreenSize>({
    isMobile: window.innerWidth < 768,
    isMediumScreen: window.innerWidth >= 768 && window.innerWidth <= 1366,
    isLargeScreen: window.innerWidth > 1366,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isMobile: window.innerWidth < 768,
        isMediumScreen: window.innerWidth >= 768 && window.innerWidth <= 1366,
        isLargeScreen: window.innerWidth > 1366,
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Runs only on mount

  return screenSize;
};

export default useScreenSizeDetector;
