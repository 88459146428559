import { IEntity } from '../types';
import { EntityService } from './EntityService';
import { AppGlobals } from '../AppGlobals'; // Assuming you have AppGlobals for reference

export class AccountService extends EntityService<IEntity> {
    private zedxApiURL: string = process.env.REACT_APP_ZedX_Non_Odata_API!;

    constructor() {
        super(`/Accounts`);
    }

    public async authenticateMSAuth(username: string, customerRef: string): Promise<any> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/AuthenticateMSAuth`;
        const requestBody = {
            username: username,
            password: '8kxk9223s', // dummy value, not used
            customerRef: customerRef
        };

        const response = await super.readAnyEndPointValueWithPost(endPoint, requestBody);
        return response;
    }

    public async emailExistOnCoreUsers(emailAddress: string): Promise<boolean> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/EmailExists`;
        const requestBody = { EmailAddress: emailAddress };
        const emailExist = await super.readAnyEndPointValueWithPost(endPoint, requestBody);
        return Boolean(emailExist);
    }

    public async changePassword(userID: number, currentPassword: string, newPassword: string): Promise<boolean> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/ChangePassword`;
        const requestBody = {
            UserID: userID,
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
        };
        const result = await super.readAnyEndPointValueWithPost(endPoint, requestBody);
        return Boolean(result);
    }

    public async getGraphData(): Promise<any> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/GetGraphData`;
        const emailExist = await super.readAnyEndPointValue(endPoint);
        return emailExist;
    }

    public async getCurrentCustomerNotifyApiKey(): Promise<string> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/GetCurrentCustomerNotifyApiKey`;
        const result = await super.readAnyEndPointValue(endPoint);
        return result;
    }


    public async updateNotificationTemplateIDs(
        notifyApiKey: string,
        notifyTemplateID_IC_NewPeriodToDD: string,
        notifyTemplateID_IC_NewPeriodToDirector: string,
        notifyTemplateID_IC_NewPeriodToDDDelegate: string,
        notifyTemplateID_IC_NewPeriodToDirectorDelegate: string,
        notifyTemplateID_IC_UpdateToSuperUsers: string,
        notifyTemplateID_IC_ReminderToDD: string,
        notifyTemplateID_IC_ReminderToDirector: string,
        notifyTemplateID_IC_ReminderToDDDelegate: string,
        notifyTemplateID_IC_ReminderToDirectorDelegate: string,
        notifyTemplateID_NP_NewAssignee: string,
        notifyTemplateID_NP_NewPeriod: string,
        notifyTemplateID_NP_UpdateReminder: string,
        notifyTemplateID_GIAA_NewActionOwner: string,
        notifyTemplateID_GIAA_UpdateReminder: string,
        notifyTemplateID_MA_NewAction: string,
        notifyTemplateID_MA_UpdateReminder: string,
        notifyTemplateID_CL_HiringManagerAndStaff: string,
        notifyTemplateID_CL_Approvers: string,
        notifyTemplateID_CL_Superusers: string
    ): Promise<boolean> {
        const endPoint: string = `${this.zedxApiURL}/Accounts/UpdateNotificationKeys`;

        const requestBody = {
            CustomerID: String(AppGlobals.CustomerID),
            NotifyApiKey: String(notifyApiKey), // Ensure this is a string
            ICNewPeriodToDD: String(notifyTemplateID_IC_NewPeriodToDD),
            ICNewPeriodToDirector: String(notifyTemplateID_IC_NewPeriodToDirector),
            ICNewPeriodToDDDelegate: String(notifyTemplateID_IC_NewPeriodToDDDelegate),
            ICNewPeriodToDirectorDelegate: String(notifyTemplateID_IC_NewPeriodToDirectorDelegate),
            ICUpdateToSuperUsers: String(notifyTemplateID_IC_UpdateToSuperUsers),
            ICReminderToDD: String(notifyTemplateID_IC_ReminderToDD),
            ICReminderToDirector: String(notifyTemplateID_IC_ReminderToDirector),
            ICReminderToDDDelegate: String(notifyTemplateID_IC_ReminderToDDDelegate),
            ICReminderToDirectorDelegate: String(notifyTemplateID_IC_ReminderToDirectorDelegate),
            NPNewAssignee: String(notifyTemplateID_NP_NewAssignee),
            NPNewPeriod: String(notifyTemplateID_NP_NewPeriod),
            NPUpdateReminder: String(notifyTemplateID_NP_UpdateReminder),
            GIAANewActionOwner: String(notifyTemplateID_GIAA_NewActionOwner),
            GIAAUpdateReminder: String(notifyTemplateID_GIAA_UpdateReminder),
            MANewAction: String(notifyTemplateID_MA_NewAction),
            MAUpdateReminder: String(notifyTemplateID_MA_UpdateReminder),
            CLHiringManagerAndStaff: String(notifyTemplateID_CL_HiringManagerAndStaff),
            CLApprovers: String(notifyTemplateID_CL_Approvers),
            CLSuperusers: String(notifyTemplateID_CL_Superusers),
        };
        

        const result = await super.readAnyEndPointValueWithPost(endPoint, requestBody);
        return Boolean(result);
    }

}
