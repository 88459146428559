import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { INavLink, Nav } from '@fluentui/react';
import { IWithErrorHandlingProps } from './withErrorHandling';
import { OrbUserContext } from './OrbUserContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';

interface IProgressUpdatesMenu extends IWithErrorHandlingProps {
    onLinkClicked?: () => void;
}

export const ProgressUpdatesMenu = ({ errorHandling: { clearErrors }, onLinkClicked }: IProgressUpdatesMenu): React.ReactElement => {
    const { userPermissions: up } = useContext(OrbUserContext);
    const history = useHistory();
    const { pathname } = useLocation();
    const [navs, setNavs] = useState([]);
    const resetErrors = useCallback(clearErrors, [clearErrors]);

    useEffect(() => {
        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const NamePathToNavLink = ({ name, path }): INavLink => {
            return { key: path, name: name, url: `#${path}`, onClick: () => routerNav(path) };
        };

        const links: INavLink[] = [
            { name: 'Home', path: '/' },
            { name: 'Welcome', path: '/progress-updates/welcome' },
            { name: 'My Updates', path: '/progress-updates/my-updates' },
            { name: 'Report archive', path: '/progress-updates/report-archive' },
        ].map(NamePathToNavLink);


        setNavs(links);
    }, [up, history, onLinkClicked, resetErrors]);

    return (
        <ThemeProvider theme={darkTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                selectedKey={pathname}
                groups={[{ links: navs }]}
            />
        </ThemeProvider>
    );
};
