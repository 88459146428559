import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    EntityValidations, ISpecificEntityFormProps, IApp03WorkflowStage, IApp03Task, App03Task, IUser, App03TaskUser
} from '../../types';
import { LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { TaskPriority } from '../../refData/WorkflowType';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrCheckbox } from '../cr/CrCheckbox';

class TaskShortFormValidations extends EntityValidations {
    public AssignedTo: string = null;
}

interface ITaskShortFormProps extends ISpecificEntityFormProps {
    workflowID: number;
    newTaskStageID: number;
}

export const TaskShortForm = (props: ITaskShortFormProps): React.ReactElement => {
    const { dataServices, loadLookupData: { entityStatuses, users: { all: allUsers } } } = useContext(DataContext);

    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);
    const [showArchived, setShowArchived] = useState<boolean>(true);

    const [workflowStages, setWorkflowStages] = useState<IApp03WorkflowStage[]>([]);

    useEffect(() => {
        const fetchStages = async () => {
            try {
                const stages = await dataServices.app03WorkflowStageService.readWorkflowStages(props.workflowID);
                setWorkflowStages(stages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchStages();
    }, [props.entityId]);


    const validateEntity = (task: IApp03Task): Promise<TaskShortFormValidations> => {
        const errors = new TaskShortFormValidations();

        if (task.Title === null || task.Title === '') {
            errors.Title = `Task name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (task.App03TaskUsers === undefined || task.App03TaskUsers === null || task.App03TaskUsers.length < 1) {
            errors.AssignedTo = `At least one assignee is required`;
            errors.Valid = false;
        }
        else {
            errors.AssignedTo = null;
        }

        return Promise.resolve(errors);
    };


    const taskPriorityArray = Object.keys(TaskPriority)
        .filter(key => !isNaN(Number(TaskPriority[key])))
        .map(key => ({ ID: TaskPriority[key], Title: key }));


    return (
        <EntityForm<IApp03Task, TaskShortFormValidations>
            {...props}
            entityName='Task Stage & Priority'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: task, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrChoiceGroup
                            label="Stage"
                            className={styles.formField}
                            options={LookupService.entitiesToChoiceGroupOptions(workflowStages)}
                            selectedKey={String(task.WorkflowStageID)}
                            onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'WorkflowStageID')}
                            required={true}
                        />
                        <CrChoiceGroup
                            label="Priority"
                            className={styles.formField}
                            options={LookupService.entitiesToChoiceGroupOptions(taskPriorityArray)}
                            selectedKey={String(task.TaskPriorityID)}
                            onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'TaskPriorityID')}
                            required={true}
                        />
                        {props.entityId > 0 && showArchived === true && <CrCheckbox
                            className={`${styles.formField} ${styles.checkbox}`}
                            label="Move to archived"
                            checked={task.Archived}
                            onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'Archived')}
                        />}
                    </div>
                );
            }}
            loadEntity={id => dataServices.app03TaskService.read(id, true, true)}
            onAfterLoad={(task)=> {
                setShowArchived(task.Archived === true ? false : true);
            }}
            loadNewEntity={() => new App03Task(props.workflowID, props.newTaskStageID)}
            loadEntityValidations={() => new TaskShortFormValidations()}
            onValidateEntity={validateEntity}
            onCreate={t => dataServices.app03TaskService.create(t)}
            onUpdate={t => dataServices.app03TaskService.update(t.ID, t)}
            parentEntities={dataServices.app03TaskService.parentEntities}
            childEntities={[
                {
                    ObjectParentProperty: 'App03TaskUsers',
                    ParentIdProperty: 'TaskID',
                    ChildIdProperty: 'UserID',
                    ChildService: dataServices.app03TaskUserService
                },
            ]}
        />
    );
};
