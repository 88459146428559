import { IEntity } from '../types';
import { EntityService } from './EntityService';

export class ZGraphDefnService extends EntityService<IEntity> {
    private zedxApiURL: string = process.env.REACT_APP_ZedX_Non_Odata_API!;

    constructor() {
        super(`/ZGraphDefns`);
    }

    public async getGraphData(shortname: string): Promise<any> {
        const endPoint: string = `${this.zedxApiURL}/ZGraphDefns/GetGraphData/${shortname}`;
        const data = await super.readAnyEndPointValue(endPoint);
        return data;
    }
}