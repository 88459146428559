import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { SelectionMode } from '@fluentui/react/lib/DetailsList';
import { ColumnType, IGenColumn } from '../../types/GenColumn';
import EntityListCat from '../entity/EntityListCat';
import { renderDate, renderDateRange, renderDateWithTime } from '../cr/ListRenderers';


interface ILookupData {
}
class LookupData implements ILookupData {
}

export interface ILogsClsProps extends types.IBaseComponentProps {
}

interface IAppSettingsState {
}

class AppSettingsState implements IAppSettingsState {
  constructor() {
  }
}

export default class LogsCls extends React.Component<ILogsClsProps, AppSettingsState> {
  constructor(props: ILogsClsProps, state: IAppSettingsState) {
    super(props);
    this.state = new AppSettingsState();
  }

  public render(): React.ReactElement<ILogsClsProps> {
    return (
      <React.Fragment>
        {this.renderLogsList()}
      </React.Fragment>
    );
  }

  private renderLogsList() {

    const listColumns: IGenColumn[] = [
      {
        key: '1',
        columnType: ColumnType.TextBox,
        name: 'Category',
        fieldName: 'Module',
        minWidth: 85,
        maxWidth: 100,
        isMultiline: true,
        isResizable: true,
        isRequired: true,
        fieldMaxLength: 50
      },
      {
        key: '2',
        columnType: ColumnType.TextBox,
        name: 'Title',
        fieldName: 'Title',
        minWidth: 120,
        maxWidth: 160,
        isMultiline: true,
        isResizable: true,
        isRequired: true,
        fieldMaxLength: 50,
        isCollapsible: true
      },

      {
        key: '4',
        columnType: ColumnType.TagPickerForUser,
        name: 'Display Name',
        fieldName: 'UserTitle',
        idFieldName: 'UserId',
        isParent: true,
        parentEntityName: 'User',
        parentColumnName: 'Title',
        parentService: new services.UserService(),
        minWidth: 100,
        maxWidth: 100,
        isMultiline: true,
        isResizable: true,
        isRequired: true
      },
      // {
      //   key: '5',
      //   columnType: ColumnType.DropDown,
      //   name: 'Period',
      //   fieldName: 'PeriodTitle',
      //   idFieldName: 'PeriodId',
      //   isParent: true,
      //   parentEntityName: 'Period',
      //   parentColumnName: 'Title',
      //   parentService: new services.CAPeriodService(),
      //   minWidth: 120,
      //   maxWidth: 120,
      //   isMultiline: true,
      //   isResizable: true,
      //   isRequired: true,
      //   isCollapsible: true,

      // },
      // {
      //   key: '6',
      //   columnType: ColumnType.DropDown,
      //   name: 'Division',
      //   fieldName: 'TeamTitle',
      //   idFieldName: 'TeamId',
      //   isParent: true,
      //   parentEntityName: 'Team',
      //   parentColumnName: 'Title',
      //   parentService: new services.OrgLevel3Service(),
      //   minWidth: 120,
      //   maxWidth: 130,
      //   isMultiline: true,
      //   isResizable: true,
      //   isCollapsible: true,
      // },
      {
        key: '7',
        columnType: ColumnType.TextBox,
        name: 'Date',
        fieldName: 'LogDate',
        minWidth: 70,
        maxWidth: 100,
        isMultiline: true,
        isResizable: true,
        isRequired: true,
        fieldMaxLength: 50,
        onRender: item => renderDateWithTime(item.LogDate)
      },
      {
        key: '3',
        columnType: ColumnType.TextBox,
        name: 'Details',
        fieldName: 'Details',
        minWidth: 160,
        maxWidth: 460,
        isResizable: true,
        isMultiline: true,
        isRequired: true,
        fieldMaxLength: 5000,
        numRows: 5,
        isCollapsible: true,
      }
    ];

    return (
      <React.Fragment>
        <EntityListCat
          selectionMode={SelectionMode.none}
          pageSize={100}
          allowAdd={false}
          columns={listColumns}
          entityReadAllExpandAll={true}
          {...this.props}
          errorHandling={this.props.errorHandling}
          entityService={new services.LogService()}
          entityNamePlural="Logs"
          entityNameSingular="Log"
          childEntityNameApi=""
          childEntityNamePlural=""
          childEntityNameSingular=""
        />
      </React.Fragment>
    );
  }

}
