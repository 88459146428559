import { EntityService } from './EntityService';
import { IEntity, ICLCaseEvidence } from '../types';

export class CLCaseEvidenceService extends EntityService<ICLCaseEvidence> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/CLCaseEvidences`);
    }

    public readAllForEvList(caseId: number, workerId: number): Promise<IEntity[]> {
        return this.readAll(`?getGeneralEvidencesForList=&caseId=${caseId}&workerId=${workerId}`);
    }

    public readIR35Evidence(caseId: number): Promise<IEntity[]> {
        return this.readAll(`?$orderby=ID&$filter=CLCaseId eq ${caseId} and EvidenceType eq 'IR35' and RecordCreated eq true`);
    }

    public readContractorSecurityCheckEvidence(workerId: number): Promise<IEntity[]> {
        return this.readAll(`?$orderby=ID&$filter=CLWorkerId eq ${workerId} and EvidenceType eq 'ContractorSecurityCheck' and RecordCreated eq true`);
    }
}