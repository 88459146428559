import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../DataContext";
import { IGraphData } from "../../types";
import { GrVerticalBarChart } from "./GrVerticalBarChart";
import { GrDonutChart } from "./GrDonutChart";
import { GrVerticalStackedBarChart } from "./GrVerticalStackedBarChart";
import { GrSingleNumber } from "./GrSingleNumber";

interface GraphRendererProps {
    graphShortname: string;
    menuData?: any[];
    onGraphDataFetch?: (dataAvailable: boolean) => void; // Make this optional
}

export const GraphRenderer = (props: GraphRendererProps): React.ReactElement => {
    const { dataServices: { zGraphDefnService }, } = useContext(DataContext);
    const [graphData, setGraphData] = useState<IGraphData>();

    useEffect(() => {
        const fetchData = async () => {
            const graphData: IGraphData = await zGraphDefnService.getGraphData(props.graphShortname);
            if (graphData?.data?.length > 0) {
                setGraphData(graphData);
                if (props.onGraphDataFetch) {
                    props.onGraphDataFetch(true); // Notify parent that data is available if callback exists
                }
            }
            else{
                //console.log('graph data not available');
                if (props.onGraphDataFetch) {
                    props.onGraphDataFetch(false); // Notify parent that data is not available if callback exists
                }
                return;
            }
        };

        fetchData();
    }, [props.graphShortname]);

    const graphComponentMap: { [key: string]: React.ElementType } = {
        'VerticalBarChart': GrVerticalBarChart,
        'VerticalStackedBarChart': GrVerticalStackedBarChart,
        'DonutChart': GrDonutChart,
        'SingleNumber': GrSingleNumber,
    };

    const GraphComponent = graphData ? graphComponentMap[graphData.graphType] : null;

    return (
        <>
            {GraphComponent ? <GraphComponent graphData={graphData} {...props} /> : null}
        </>
    );
};
