import { EntityWithStatusService } from './EntityWithStatusService';
import { IEntityChildren, IApp03Workflow, IEntityDescription1 } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import { AppGlobals } from '../AppGlobals';

export class App03WorkflowService extends EntityWithStatusService<IApp03Workflow> {
    //protected childrenEntities = ['Contributors($expand=ContributorUser)', 'Attributes($expand=AttributeType)'];
    protected childrenEntities = ['App03WorkflowUsers($expand=User)'];

    constructor() {
        super(`/App03Workflows`);
    }

    public readAllForList(includeClosed?: boolean): Promise<IApp03Workflow[]> {
        return this.readAll(
            `?$select=ID,Title,WorkflowTypeID`
            + `&$orderby=Title`
            + `&$expand=EntityStatus($select=Title),App03WorkflowUsers($filter=IsAdmin eq true;$expand=User($select=Title))`
            + `,App03Tasks($filter=Archived ne true;$select=ID,TaskPriorityID;$expand=App03TaskUsers($select=ID,UserID))`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public countAllForList(includeClosed?: boolean): Promise<IApp03Workflow[]> {
        return this.readAll(
            `?$select=ID`
            + `&$orderby=Title`
            + `&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForLookup(includeClosedEntities?: boolean, additionalFields?: string): Promise<IApp03Workflow[]> {
        return this.readAll(
            `?$select=ID,Title${additionalFields ? `,${additionalFields}` : ''}`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosedEntities ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        return this.entityChildrenSingle(id, 'App03 Tasks', 'App03Tasks', true);
    }
    public async create(workflow: IApp03Workflow): Promise<IApp03Workflow> {
        workflow.CustomerID = AppGlobals.CustomerID;           
        return super.create(workflow);
    }

    public async readTitlesForWorkflowUsers(workflowID: number): Promise<IEntityDescription1> {
        const entityDescription1: IEntityDescription1 = {
            Description1ForList: '',
            Description1ForForm: ''
        };
        const res = await this.readAll(`?$filter=ID eq ${workflowID}&$select=Title`);
        if (res.length > 0) {
            entityDescription1.Description1ForList = `Users with access to Workflow ${res[0].Title}`;
            entityDescription1.Description1ForForm = `Users with access to Workflow ${res[0].Title}`;
        }

        return entityDescription1;
    }

    public async readForView(workflowID: number): Promise<IApp03Workflow> {
        return this.read(
            workflowID,
            false,
            false,
            [
                'App03WorkflowUsers($expand=User($select=Title,EmailAddress))',
                'App03WorkflowStages($select=ID,Title)',
            ]
        );
    }
}