import React, { useContext, useEffect, useMemo, useState } from 'react';
import {
    EntityValidations, ISpecificEntityFormProps, IApp03WorkflowStage, App03WorkflowStage
} from '../../types';
import { LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrCheckbox } from '../cr/CrCheckbox';


interface IWFStageFormProps extends ISpecificEntityFormProps {
    workflowID: number;
    editRequest: boolean;
    onEditStageSelection?: (stageID: number) => void;
}
class WFStageFormValidations extends EntityValidations {
    public ReqStageID: string = null;
    public ReqDisplayOrder: string = null;
    public StageToEdit: string = null;
}

export const WFStageForm = (props: IWFStageFormProps): React.ReactElement => {

    const { dataServices, loadLookupData: { entityStatuses, users: { all: allUsers } } } = useContext(DataContext);

    const [selectedStageToEdit, setSelectedStageToEdit] = useState<number>(null);
    useMemo(() => entityStatuses(), [entityStatuses]);
    useMemo(() => allUsers(), [allUsers]);


    const [workflowStages, setWorkflowStages] = useState<IApp03WorkflowStage[]>([]);

    useEffect(() => {
        const fetchStages = async () => {
            try {
                const stages = await dataServices.app03WorkflowStageService.readWorkflowStages(props.workflowID);
                setWorkflowStages(stages);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchStages();
    }, []);



    const validateEntity = (stage: IApp03WorkflowStage): Promise<WFStageFormValidations> => {
        const errors = new WFStageFormValidations();

        //common validations for add/edit stage
        if (stage.Title === null || stage.Title === '') {
            errors.Title = `Stage name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }

        if(props.editRequest === true){
            //edit stage validations
            if(selectedStageToEdit === null){
                errors.StageToEdit = "Stage selection is required"
                errors.Valid = false;
            }
            else{
                errors.StageToEdit = null;
            }
            if (stage.ChangeDisplayOrder === true) {

                if (stage.ReqDisplayOrder === null) {
                    errors.ReqDisplayOrder = "Before or after selection is required to change display order";
                    errors.Valid = false;
                }
                else {
                    errors.ReqDisplayOrder = null;
                }
                if (stage.ReqStageID === null) {
                    errors.ReqStageID = "Stage selection is required";
                    errors.Valid = false;
                }
                else {
                    errors.ReqStageID = null;
                }
            }
        }
        else{
            //add stage validations
            if (stage.ReqStageID === null) {
                errors.ReqStageID = "Stage selection is required";
                errors.Valid = false;
            }
            else {
                errors.ReqStageID = null;
            }
        }

        return Promise.resolve(errors);
    };

    return (
        <EntityForm<IApp03WorkflowStage, WFStageFormValidations>
            {...props}
            entityName='Stage'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: stage, ValidationErrors: errors } = formState;

                return (
                    <div>
                        {props.editRequest === true &&
                            <>
                                <CrDropdown
                                    label="Select Stage to edit"
                                    className={styles.formField}
                                    options={LookupService.entitiesToSelectableOptions(workflowStages)}
                                    selectedKey={selectedStageToEdit}
                                    onChange={(_, o) => {
                                        const stageID: number = Number(o.key);
                                        setSelectedStageToEdit(stageID);
                                        props.onEditStageSelection(stageID);
                                    }}
                                    required={true}
                                    errorMessage={errors.StageToEdit}
                                />
                                {props.entityId > 0 &&
                                    <>
                                        <CrTextField
                                            label="Change Name"
                                            maxLength={100}
                                            className={styles.formField}
                                            required={true}
                                            value={stage.Title}
                                            onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                                            errorMessage={errors.Title}
                                        />
                                        <CrCheckbox
                                            className={`${styles.formField} ${styles.checkbox}`}
                                            label="Change Display Order?"
                                            checked={stage.ChangeDisplayOrder}
                                            onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'ChangeDisplayOrder')}
                                        />
                                        {stage.ChangeDisplayOrder && workflowStages.length > 0 &&
                                            <>
                                                <CrChoiceGroup
                                                    className={styles.formField}
                                                    options={[
                                                        { key: 'before_stage', text: `Before the stage below` },
                                                        { key: 'after_stage', text: `After the stage below` },
                                                    ]}
                                                    selectedKey={stage.ReqDisplayOrder}
                                                    onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'ReqDisplayOrder')}
                                                    required={true}
                                                    errorMessage={errors.ReqDisplayOrder}
                                                />
                                                <CrDropdown
                                                    label="Select Stage"
                                                    className={styles.formField}
                                                    options={LookupService.entitiesToSelectableOptions(workflowStages.filter(x => x.ID !== props.entityId))}
                                                    selectedKey={stage.ReqStageID}
                                                    onChange={(_, o) => changeHandlers.changeDropdown(o, 'ReqStageID')}
                                                    required={true}
                                                    errorMessage={errors.ReqStageID}
                                                />
                                            </>
                                        }



                                    </>
                                }

                            </>
                        }
                        {/* following code to add new stage */}
                        {
                            props.editRequest !== true &&
                            <>
                                <CrTextField
                                    label="Name"
                                    maxLength={100}
                                    className={styles.formField}
                                    required={true}
                                    value={stage.Title}
                                    onChange={(ev, newValue) => changeHandlers.changeTextField(newValue, 'Title')}
                                    errorMessage={errors.Title}
                                />
                                <CrChoiceGroup
                                    label="Where to add?"
                                    className={styles.formField}
                                    options={[
                                        { key: 'before_stage', text: 'Before the stage below' },
                                        { key: 'after_stage', text: 'After the stage below' },
                                    ]}
                                    selectedKey={stage.ReqDisplayOrder}
                                    onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'ReqDisplayOrder')}
                                />
                                <CrDropdown
                                    label="Select Stage"
                                    className={styles.formField}
                                    options={LookupService.entitiesToSelectableOptions(workflowStages)}
                                    selectedKey={stage.ReqStageID}
                                    onChange={(_, o) => changeHandlers.changeDropdown(o, 'ReqStageID')}
                                    required={true}
                                    errorMessage={errors.ReqStageID}
                                />







                                {/* {props.entityId > 0 && <CrCheckbox
                                    className={`${styles.formField} ${styles.checkbox}`}
                                    label="Change Display Order?"
                                    checked={stage.ChangeDisplayOrder}
                                    onChange={(_, isChecked) => changeHandlers.changeCheckbox(isChecked, 'ChangeDisplayOrder')}
                                />} */}
                                {/* {stage.ChangeDisplayOrder && workflowStages.length > 0 &&
                                    <>
                                        <CrDropdown
                                            label="Select Stage"
                                            className={styles.formField}
                                            options={LookupService.entitiesToSelectableOptions(workflowStages.filter(x => x.ID !== props.entityId))}
                                            selectedKey={stage.ReqStageID}
                                            onChange={(_, o) => changeHandlers.changeDropdown(o, 'ReqStageID')}
                                            required={true}
                                            errorMessage={errors.ReqStageID}
                                        />

                                        <CrChoiceGroup
                                            label="Where to move?"
                                            className={styles.formField}
                                            options={[
                                                { key: 'before_stage', text: `Before above stage` },
                                                { key: 'after_stage', text: `After above stage` },
                                            ]}
                                            selectedKey={stage.ReqDisplayOrder}
                                            onChange={(_, option) => changeHandlers.changeChoiceGroup(option, 'ReqDisplayOrder')}
                                            required={true}
                                            errorMessage={errors.ReqDisplayOrder}
                                        />
                                    </>
                                } */}
                            </>
                        }
                    </div>
                );
            }}
            loadEntity={id => dataServices.app03WorkflowStageService.read(id, true, true)}
            loadNewEntity={() => new App03WorkflowStage(props.workflowID, null, 'after_stage')}
            loadEntityValidations={() => new WFStageFormValidations()}
            onValidateEntity={validateEntity}
            onCreate={s => dataServices.app03WorkflowStageService.create(s)}
            onUpdate={s => dataServices.app03WorkflowStageService.update(s.ID, s)}
            parentEntities={dataServices.app03WorkflowStageService.parentEntities}
        />
    );
};
