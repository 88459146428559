import React, { useContext, useEffect, useState } from "react";
import styles from '../../styles/cr.module.scss';
import { OrbUserContext } from "../OrbUserContext";
import { CrTextField } from "../cr/CrTextField";
import { FormButtons } from "../cr/FormButtons";
import { useHistory } from "react-router-dom";
import { FieldErrorMessage, FieldSuccessMessage } from "../cr/FieldDecorators";
import { DataContext } from "../DataContext";
import { IEntityFormProps2 } from "../../types";

interface NotifySettingsFormProps extends IEntityFormProps2 {
    onCancelClick?: () => void;
}

interface IErrorMessage {
}
export class ErrorMessage implements IErrorMessage {
}

export const NotifySettingsForm = (props: NotifySettingsFormProps): React.ReactElement => {
    const { userContext } = useContext(OrbUserContext);
    const { dataServices: { accountService, zCoreAutomationOptionService }, } = useContext(DataContext);
    const history = useHistory();

    const [notifyApiKey, setNotifyApiKey] = useState<string>('');
    const [notifyTemplateID_IC_NewPeriodToDD, setNotifyTemplateID_IC_NewPeriodToDD] = useState<string>('');
    const [notifyTemplateID_IC_NewPeriodToDirector, setNotifyTemplateID_IC_NewPeriodToDirector] = useState<string>('');
    const [notifyTemplateID_IC_NewPeriodToDDDelegate, setNotifyTemplateID_IC_NewPeriodToDDDelegate] = useState<string>('');
    const [notifyTemplateID_IC_NewPeriodToDirectorDelegate, setNotifyTemplateID_IC_NewPeriodToDirectorDelegate] = useState<string>('');
    const [notifyTemplateID_IC_UpdateToSuperUsers, setNotifyTemplateID_IC_UpdateToSuperUsers] = useState<string>('');
    const [notifyTemplateID_IC_ReminderToDD, setNotifyTemplateID_IC_ReminderToDD] = useState<string>('');
    const [notifyTemplateID_IC_ReminderToDirector, setNotifyTemplateID_IC_ReminderToDirector] = useState<string>('');
    const [notifyTemplateID_IC_ReminderToDDDelegate, setNotifyTemplateID_IC_ReminderToDDDelegate] = useState<string>('');
    const [notifyTemplateID_IC_ReminderToDirectorDelegate, setNotifyTemplateID_IC_ReminderToDirectorDelegate] = useState<string>('');
    const [notifyTemplateID_NP_NewAssignee, setNotifyTemplateID_NP_NewAssignee] = useState<string>('');
    const [notifyTemplateID_NP_NewPeriod, setNotifyTemplateID_NP_NewPeriod] = useState<string>('');
    const [notifyTemplateID_NP_UpdateReminder, setNotifyTemplateID_NP_UpdateReminder] = useState<string>('');
    const [notifyTemplateID_GIAA_NewActionOwner, setNotifyTemplateID_GIAA_NewActionOwner] = useState<string>('');
    const [notifyTemplateID_GIAA_UpdateReminder, setNotifyTemplateID_GIAA_UpdateReminder] = useState<string>('');
    const [notifyTemplateID_MA_NewAction, setNotifyTemplateID_MA_NewAction] = useState<string>('');
    const [notifyTemplateID_MA_UpdateReminder, setNotifyTemplateID_MA_UpdateReminder] = useState<string>('');
    const [notifyTemplateID_CL_HiringManagerAndStaff, setNotifyTemplateID_CL_HiringManagerAndStaff] = useState<string>('');
    const [notifyTemplateID_CL_Approvers, setNotifyTemplateID_CL_Approvers] = useState<string>('');
    const [notifyTemplateID_CL_Superusers, setNotifyTemplateID_CL_Superusers] = useState<string>('');

    const [saveErrorMsg, setSaveErorMsg] = useState<string>('');
    const [saveSuccessMsg, setSaveSuccessMsg] = useState<string>('');
    const [errMessages, setErrMessages] = useState<IErrorMessage>(new ErrorMessage());

    useEffect(() => {
        const fetchCustomerNotifyApiKey = async () => {
            const notifyKey = await accountService.getCurrentCustomerNotifyApiKey();
            setNotifyApiKey(notifyKey);
        };

        const fetchAutoOptions = async () => {
            const autoOptions = await zCoreAutomationOptionService.readAllTemplateIds();

            const ICNewPeriodToDD: string = autoOptions.find(x => x.Title === 'IC-NewPeriodToDD')?.['NotifyTemplateId'] ?? '';
            const ICNewPeriodToDirector: string = autoOptions.find(x => x.Title === 'IC-NewPeriodToDirector')?.['NotifyTemplateId'] ?? '';
            const ICNewPeriodToDDDelegate: string = autoOptions.find(x => x.Title === 'IC-NewPeriodToDDDelegate')?.['NotifyTemplateId'] ?? '';
            const ICNewPeriodToDirectorDelegate: string = autoOptions.find(x => x.Title === 'IC-NewPeriodToDirectorDelegate')?.['NotifyTemplateId'] ?? '';
            const ICUpdateToSuperUsers: string = autoOptions.find(x => x.Title === 'IC-UpdateToSuperUsers')?.['NotifyTemplateId'] ?? '';
            const ICReminderToDD: string = autoOptions.find(x => x.Title === 'IC-ReminderToDD')?.['NotifyTemplateId'] ?? '';
            const ICReminderToDirector: string = autoOptions.find(x => x.Title === 'IC-ReminderToDirector')?.['NotifyTemplateId'] ?? '';
            const ICReminderToDDDelegate: string = autoOptions.find(x => x.Title === 'IC-ReminderToDDDelegate')?.['NotifyTemplateId'] ?? '';
            const ICReminderToDirectorDelegate: string = autoOptions.find(x => x.Title === 'IC-ReminderToDirectorDelegate')?.['NotifyTemplateId'] ?? '';
            const NPNewAssignee: string = autoOptions.find(x => x.Title === 'NP-NewAssignee')?.['NotifyTemplateId'] ?? '';
            const NPNewPeriod: string = autoOptions.find(x => x.Title === 'NP-NewPeriod')?.['NotifyTemplateId'] ?? '';
            const NPUpdateReminder: string = autoOptions.find(x => x.Title === 'NP-UpdateReminder')?.['NotifyTemplateId'] ?? '';
            const GIAANewActionOwner: string = autoOptions.find(x => x.Title === 'GIAA-NewActionOwner')?.['NotifyTemplateId'] ?? '';
            const GIAAUpdateReminder: string = autoOptions.find(x => x.Title === 'GIAA-UpdateReminder')?.['NotifyTemplateId'] ?? '';
            const MANewAction: string = autoOptions.find(x => x.Title === 'MA-NewAction')?.['NotifyTemplateId'] ?? '';
            const MAUpdateReminder: string = autoOptions.find(x => x.Title === 'MA-UpdateReminder')?.['NotifyTemplateId'] ?? '';
            const CLHiringManagerAndStaff: string = autoOptions.find(x => x.Title === 'CL-HiringManagerAndStaff')?.['NotifyTemplateId'] ?? '';
            const CLApprovers: string = autoOptions.find(x => x.Title === 'CL-Approvers')?.['NotifyTemplateId'] ?? '';
            const CLSuperusers: string = autoOptions.find(x => x.Title === 'CL-Superusers')?.['NotifyTemplateId'] ?? '';

            setNotifyTemplateID_IC_NewPeriodToDD(ICNewPeriodToDD);
            setNotifyTemplateID_IC_NewPeriodToDirector(ICNewPeriodToDirector);
            setNotifyTemplateID_IC_NewPeriodToDDDelegate(ICNewPeriodToDDDelegate);
            setNotifyTemplateID_IC_NewPeriodToDirectorDelegate(ICNewPeriodToDirectorDelegate);
            setNotifyTemplateID_IC_UpdateToSuperUsers(ICUpdateToSuperUsers);
            setNotifyTemplateID_IC_ReminderToDD(ICReminderToDD);
            setNotifyTemplateID_IC_ReminderToDirector(ICReminderToDirector);
            setNotifyTemplateID_IC_ReminderToDDDelegate(ICReminderToDDDelegate);
            setNotifyTemplateID_IC_ReminderToDirectorDelegate(ICReminderToDirectorDelegate);
            setNotifyTemplateID_NP_NewAssignee(NPNewAssignee);
            setNotifyTemplateID_NP_NewPeriod(NPNewPeriod);
            setNotifyTemplateID_NP_UpdateReminder(NPUpdateReminder);
            setNotifyTemplateID_GIAA_NewActionOwner(GIAANewActionOwner);
            setNotifyTemplateID_GIAA_UpdateReminder(GIAAUpdateReminder);
            setNotifyTemplateID_MA_NewAction(MANewAction);
            setNotifyTemplateID_MA_UpdateReminder(MAUpdateReminder);
            setNotifyTemplateID_CL_HiringManagerAndStaff(CLHiringManagerAndStaff);
            setNotifyTemplateID_CL_Approvers(CLApprovers);
            setNotifyTemplateID_CL_Superusers(CLSuperusers);
        };

        fetchCustomerNotifyApiKey();
        fetchAutoOptions();
    }, []);


    const validateEntity = (): boolean => {
        return true;
    }
    const handleNotifySettingsForm = async (): Promise<void> => {

        if (validateEntity()) {
            const result: boolean = await accountService.updateNotificationTemplateIDs(
                notifyApiKey,
                notifyTemplateID_IC_NewPeriodToDD,
                notifyTemplateID_IC_NewPeriodToDirector,
                notifyTemplateID_IC_NewPeriodToDDDelegate,
                notifyTemplateID_IC_NewPeriodToDirectorDelegate,
                notifyTemplateID_IC_UpdateToSuperUsers,
                notifyTemplateID_IC_ReminderToDD,
                notifyTemplateID_IC_ReminderToDirector,
                notifyTemplateID_IC_ReminderToDDDelegate,
                notifyTemplateID_IC_ReminderToDirectorDelegate,
                notifyTemplateID_NP_NewAssignee,
                notifyTemplateID_NP_NewPeriod,
                notifyTemplateID_NP_UpdateReminder,
                notifyTemplateID_GIAA_NewActionOwner,
                notifyTemplateID_GIAA_UpdateReminder,
                notifyTemplateID_MA_NewAction,
                notifyTemplateID_MA_UpdateReminder,
                notifyTemplateID_CL_HiringManagerAndStaff,
                notifyTemplateID_CL_Approvers,
                notifyTemplateID_CL_Superusers
            );

            if (result === true) {
                setSaveErorMsg("");
                setSaveSuccessMsg("Your notification keys are updated.");
                props.onSaved();
                // notifyApiKey("");
                // setNewPassword("");
                // setRepeatNewPassword("");
            }
            else {
                setSaveSuccessMsg("");
                setSaveErorMsg("Error in updating notification keys.");
            }
        }
    }

    const handleCancel = (): void => {
        props.onCancelClick();
    }

    return (
        <div className={`${styles.cr} `}>
            {/* <h2 style={{ flexGrow: 1 }} className={styles.listTitle}>Change Password</h2> */}
            <div style={{ maxWidth: '600px', marginRight: '10px', marginLeft: '10px', marginTop: '30px' }}>
                <CrTextField
                    label="Notify API Key"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyApiKey}
                    onChange={(ev, newValue) => setNotifyApiKey(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-NewPeriodToDD"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_NewPeriodToDD}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_NewPeriodToDD(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-NewPeriodToDirector"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_NewPeriodToDirector}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_NewPeriodToDirector(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-NewPeriodToDDDelegate"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_NewPeriodToDDDelegate}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_NewPeriodToDDDelegate(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-NewPeriodToDirectorDelegate"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_NewPeriodToDirectorDelegate}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_NewPeriodToDirectorDelegate(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-UpdateToSuperUsers"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_UpdateToSuperUsers}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_UpdateToSuperUsers(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-ReminderToDD"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_ReminderToDD}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_ReminderToDD(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-ReminderToDirector"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_ReminderToDirector}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_ReminderToDirector(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-ReminderToDDDelegate"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_ReminderToDDDelegate}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_ReminderToDDDelegate(newValue)}
                />
                <CrTextField
                    label="Template ID for IC-ReminderToDirectorDelegate"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_IC_ReminderToDirectorDelegate}
                    onChange={(ev, newValue) => setNotifyTemplateID_IC_ReminderToDirectorDelegate(newValue)}
                />
                <CrTextField
                    label="Template ID for NP-NewAssignee"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_NP_NewAssignee}
                    onChange={(ev, newValue) => setNotifyTemplateID_NP_NewAssignee(newValue)}
                />
                <CrTextField
                    label="Template ID for NP-NewPeriod"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_NP_NewPeriod}
                    onChange={(ev, newValue) => setNotifyTemplateID_NP_NewPeriod(newValue)}
                />
                <CrTextField
                    label="Template ID for NP-UpdateReminder"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_NP_UpdateReminder}
                    onChange={(ev, newValue) => setNotifyTemplateID_NP_UpdateReminder(newValue)}
                />
                <CrTextField
                    label="Template ID for GIAA-NewActionOwner"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_GIAA_NewActionOwner}
                    onChange={(ev, newValue) => setNotifyTemplateID_GIAA_NewActionOwner(newValue)}
                />
                <CrTextField
                    label="Template ID for GIAA-UpdateReminder"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_GIAA_UpdateReminder}
                    onChange={(ev, newValue) => setNotifyTemplateID_GIAA_UpdateReminder(newValue)}
                />
                <CrTextField
                    label="Template ID for MA-NewAction"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_MA_NewAction}
                    onChange={(ev, newValue) => setNotifyTemplateID_MA_NewAction(newValue)}
                />
                <CrTextField
                    label="Template ID for MA-UpdateReminder"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_MA_UpdateReminder}
                    onChange={(ev, newValue) => setNotifyTemplateID_MA_UpdateReminder(newValue)}
                />
                <CrTextField
                    label="Template ID for CL-HiringManagerAndStaff"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_CL_HiringManagerAndStaff}
                    onChange={(ev, newValue) => setNotifyTemplateID_CL_HiringManagerAndStaff(newValue)}
                />
                <CrTextField
                    label="Template ID for CL-Approvers"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_CL_Approvers}
                    onChange={(ev, newValue) => setNotifyTemplateID_CL_Approvers(newValue)}
                />
                <CrTextField
                    label="Template ID for CL-Superusers"
                    className={styles.formField}
                    maxLength={255}
                    autoComplete="off"
                    value={notifyTemplateID_CL_Superusers}
                    onChange={(ev, newValue) => setNotifyTemplateID_CL_Superusers(newValue)}
                />


                <FormButtons
                    onPrimaryClick={handleNotifySettingsForm}
                    onSecondaryClick={handleCancel}
                />

                <FieldErrorMessage value={saveErrorMsg} />
                <FieldSuccessMessage value={saveSuccessMsg} />

            </div>
        </div>
    );

};

