import { EntityService } from './EntityService';
import { IEntity, IApp03TaskEvidence } from '../types';

export class App03TaskEvidencesService extends EntityService<IApp03TaskEvidence> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/App03TaskEvidences`);
    }

    public readAllForEvList(taskID: number): Promise<IEntity[]> {
        return this.readAll(`?getGeneralEvidencesForList=&taskID=${taskID}`);
    }
}