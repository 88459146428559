import * as React from 'react';
import { IEntityFormProps2, IGoDefForm, SectionStatus } from '../../types';
import styles from '../../styles/cr.module.scss';
import { UpdateHeader2 } from '.././cr/UpdateHeader2';
import SpecificAreasList from './section2/SpecificAreasList';
import withMobileDetector from '../withMobileDetector';

export interface ISection2UpdateProps extends IEntityFormProps2 {
    goDefForm: IGoDefForm;
    goFormId: number;
    section2CompletionStatus: string;
    onItemTitleClick: (ID: number, goElementId: number, title: string, filteredItems: any[]) => void;
    incompleteOnly: boolean;
    onChangeIncompleteOnly: (value: boolean) => void;
    justMine: boolean;
    onChangeJustMine: (value: boolean) => void;
    listFilterText: string;
    onChangeFilterText: (value: string) => void;
    section2_IsOpen: boolean;
    onSection2_toggleOpen: () => void;
    isMobile: boolean;
}

export class Section2UpdateState {
}

class Section2Update extends React.Component<ISection2UpdateProps, Section2UpdateState> {
    constructor(props: ISection2UpdateProps, state: Section2UpdateState) {
        super(props);
        this.state = new Section2UpdateState();
    }

    public render(): React.ReactElement<ISection2UpdateProps> {
        let section2Title: string = this.props.isMobile ? this.props.goDefForm.Section2TitleMobile : this.props.goDefForm.Section2Title;

        const ShowForm = this.props.section2_IsOpen;

        return (
            <div className={styles.cr}>
                <UpdateHeader2 title={section2Title} isOpen={ShowForm}
                    leadUser=""
                    rag={this.props.section2CompletionStatus === SectionStatus.Completed ? 5 : this.props.section2CompletionStatus === SectionStatus.InProgress ? 3 : null}
                    ragLabel={this.props.section2CompletionStatus === SectionStatus.Completed ? "Completed" : this.props.section2CompletionStatus === SectionStatus.InProgress ? "In Progress" : null}
                    onClick={this.props.onSection2_toggleOpen}
                />
                {ShowForm && <div>
                    <div style={{ width: 'auto', minHeight: '120px', marginTop: '10px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <SpecificAreasList
                            {...this.props}
                            errorHandling={this.props.errorHandling}
                            onItemTitleClick={this.props.onItemTitleClick}
                            incompleteOnly={this.props.incompleteOnly}
                            onChangeIncompleteOnly={this.props.onChangeIncompleteOnly}
                            justMine={this.props.justMine}
                            onChangeJustMine={this.props.onChangeJustMine}
                            goFormId={this.props.goFormId}
                            filterText={this.props.listFilterText}
                            onChangeFilterText={this.props.onChangeFilterText}
                        />
                    </div>
                    <div style={{ paddingTop: "10px", paddingLeft: "10px", fontStyle: "italic" }}>
                        Please click on a title text to view or update.
                    </div>
                    <br /><br />

                </div>}
            </div>
        );
    }

    public componentDidMount(): void {
    }

    protected validateEntityUpdate = (): boolean => {
        return true;
    }

    protected cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }
}

export default withMobileDetector(Section2Update);
