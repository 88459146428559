import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class LogService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/Logs`);
    }

    public readAllExpandAll(querystring?: string): Promise<IEntity[]> {
        let fullQryString: string;
        if (querystring)
            fullQryString = `${querystring}&$orderby=ID desc&$expand=User,Team,CAPeriod`;
        else
            fullQryString = `?$orderby=ID desc&$expand=User,Team,CAPeriod`;
        return this.readAll(fullQryString);
    }

    public async readAnyOData(odataUrl: string): Promise<any> {
        const zedxApiURL: string = process.env.REACT_APP_ZedX_API!;
        const endPoint: string = `${zedxApiURL}/${odataUrl}`;
        const data = await super.readAnyEndPointValue(endPoint);
        return data;
    }
}