import React, { useState } from 'react';
import styles from '../../styles/cr.module.scss';
import { IIconProps, Icon } from '@fluentui/react/lib/Icon';
import { TooltipHost, TooltipOverflowMode } from '@fluentui/react/lib/Tooltip';
import { UpdateHeaderTitle } from '../cr/UpdateHeaderTitle';
import { ActionButton } from '@fluentui/react';
import { StageForm } from './StageForm';
import { IWithErrorHandlingProps } from '../withErrorHandling';
import { IApp03WorkflowStage } from '../../types';

export interface ITaskStageBarProps extends IWithErrorHandlingProps {
    workflowID: number;
    currentStage: IApp03WorkflowStage
    isOpen?: boolean;
    counts?: number;
    totalCritical?: number;
    totalUserCritical?: number;
    onClick?: () => void;     
    onSaveStage?: () => void;
    onNewTaskClick?: (defaultStageID?: number) => void;
}

export const TaskStageBar = (props: ITaskStageBarProps): React.ReactElement => {

    // const [showForm, setShowForm] = useState(false);
    // const [stageAction, setStageAction] = useState<string>(null);
    const addIcon: IIconProps = { iconName: 'Add' };
    // const editIcon: IIconProps = { iconName: 'Edit' };

    // const addStage = () => {
    //     setStageAction('Add');
    //     setShowForm(true);
    // }
    // const editStage = () => {
    //     setStageAction('Edit');
    //     setShowForm(true);
    // }
    // const showFormToggle = () => {
    //     setShowForm(!showForm);
    // }

    const stageTitle: string = props.currentStage !== null ? `${props.currentStage?.Title} (${props.counts})` : `Archived (${props.counts})`;
    return (
        <React.Fragment>
            {/* {
                (props.currentStage !== null) && showForm &&
                <StageForm
                    showForm={showForm}
                    requestStage={props.currentStage}
                    workflowID={props.workflowID}
                    entityId={stageAction === 'Edit' ? props.currentStage.ID : null}
                    onSaved={() => {
                        showFormToggle();
                        props.onSaveStage();
                    }}
                    onCancelled={showFormToggle}
                    {...props}
                />
            } */}

            <div className={`${styles.cr} ${styles.updateHeader}`} style={{ cursor: 'default', backgroundColor: '#F2F2F2', marginBottom: '18px', height: '42px' }}>
                <Icon iconName={props.isOpen ? 'ChevronDown' : 'ChevronRight'} className={styles.msIcon} style={{ cursor: 'pointer' }} onClick={props.onClick} />
                <div className={`${styles.grid} ${styles.updateHeaderContent}`}>
                    <div className={`${styles.gridRow} ${styles.rowColumns}`}>
                        <div onClick={props.onClick} style={{ cursor: 'pointer', border: '0px solid green' }} className={`${styles.gridCol} ${styles.sm7} ${styles.lg6} ${styles.column}`}>
                            <div className={styles.flexWidthFix} style={{ cursor: 'pointer' }}>
                                <div className={styles.grayBarTitle}>
                                    <TooltipHost overflowMode={TooltipOverflowMode.Parent} content={<UpdateHeaderTitle title={stageTitle} />}>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ marginTop: '3px' }}>{stageTitle}</div>
                                            {(props.totalCritical > 0) && <div style={{ marginLeft: '10px', marginTop: '3px', paddingTop: '2px', minWidth: '50px', height: '15px', fontSize: '12px', backgroundColor: 'red', color: 'white', textAlign: 'center' }}>
                                                {props.totalUserCritical}/{props.totalCritical}
                                            </div>}
                                        </div>
                                    </TooltipHost>
                                </div>
                            </div>
                        </div>
                        <div className={`${styles.gridCol} ${styles.sm5} ${styles.lg2} ${styles.column} ${styles.columnRightAlign}`} style={{ border: '0px solid red', marginRight: '10px', width: '50%' }}>
                            {props.currentStage !== null &&
                                <>
                                    <ActionButton iconProps={addIcon} title='Add new task' onClick={() => props.onNewTaskClick(props.currentStage?.ID)} />
                                    {/* <ActionButton iconProps={editIcon} onClick={editStage} /> */}
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
