import React from 'react';
import { VerticalBarChart1 } from './components/charts/VerticalBarChart1';
import { GraphRenderer } from './components/charts/GraphRenderer';

interface IHomeTilesProps {
    menuData: any[];
}

export const HomeTiles = ({ menuData }: IHomeTilesProps): React.ReactElement => {
    const imagesRoot: string = process.env.REACT_APP_ZedXImagesRoot!;
    const progressUpdatesIcon: string = `${imagesRoot}progress-updates.png`;
    const manageProjectsIcon: string = `${imagesRoot}manage_projects.png`;
    const trackRisksIcon: string = `${imagesRoot}track-risks.png`;
    const giaaactionsIcon: string = `${imagesRoot}giaaactions-apphome.png`;
    const naotrackerIcon: string = `${imagesRoot}naotracker-apphome.png`;
    const contingentLabourIcon: string = `${imagesRoot}contingent-labour-apphome.png`;
    const governanceIcon: string = `${imagesRoot}governance-apphome.png`;
    const controlsAssuranceIcon: string = `${imagesRoot}controls-assurance-apphome.png`;
    const individualActionsIcon: string = `${imagesRoot}individual-actions-apphome.png`;
    const corporateWorkflowsIcon: string = `${imagesRoot}corporate-workflows.png`;
    const agileSprintsIcon: string = `${imagesRoot}agile-sprints.png`;
    const knowledgeHubIcon: string = `${imagesRoot}knowledge-hub.png`;
    const groupNotificationsIcon: string = `${imagesRoot}group-notifications.png`;

    // - Corporate Workflows
    // - Project Portfolios
    // - Risk Registers
    // - GIAA Actions
    // - NAO/PAC Tracker
    // - Contingent Labour
    // - Governance Statements
    // - Controls and Assurance
    // - Management Actions
    // - Agile Sprints
    // - Knowledge Hub
    // - Group Notifications

    //console.log('home tiles menu', menuData);

    return (
        <div>
            <div className="apps-home">
                {menuData && menuData.map((m, index) => (
                    <div key={`homeTile_${index}`} className="rectangle">
                        <a href={`#${m?.AppLink}/welcome`}>
                            <img src={`${imagesRoot}${m?.AppImage}`} /*alt="Corporate Workflows"*/ />
                            <div className="rectangle-title">{m?.AppName}</div>
                        </a>
                    </div>
                ))}
            </div>
            <div style={{ marginTop: '30px', paddingLeft: '10px', paddingRight: '10px' }}>
                {/* {menuData &&
                    <GraphRenderer
                        menuData={menuData}
                        //graphShortname='CLCasesCountPerStageVerticalBarChart'
                        graphShortname={'test1'}
                    />
                } */}
                {/* {menuData &&
                    <GraphRenderer
                        graphShortname={'testDonutChart1'}
                    />
                } */}

            </div>
        </div>
    );
};

export default HomeTiles;
