import * as React from 'react';
import * as types from '../../types';
import * as services from '../../services';
import { CrTextField } from '../cr/CrTextField';
import { CrChoiceGroup } from '../cr/CrChoiceGroup';
import { CrDropdown } from '../cr/CrDropdown';
import { CrCheckbox } from '../cr/CrCheckbox';
import { MessageDialog } from '../cr/MessageDialog';
import { DefaultButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import EvidenceList from './EV/EvidenceList';
import EvidenceSaveForm from './EV/EvidenceSaveForm';
import styles from '../../styles/cr.module.scss';
import { CLCase, ClCaseInfo, ICLCase, ICLCaseEvidence, IClCaseInfo, IEntity, ICLDefForm, IUser, ICLWorker, CLWorker, CLHiringMember, ICLHiringMember } from '../../types';
import { ConfirmDialog } from '../cr/ConfirmDialog';
import { CrDatePicker } from '../cr/CrDatePicker';
import { changeDatePickerV2 } from '../../types/AppGlobals';
import { IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import withMobileDetector from '../withMobileDetector';
import { CrLoadingOverlay } from '../cr/CrLoadingOverlay';
import { CrUserPicker } from '../cr/CrUserPicker';

export interface INewCaseTabProps extends types.IBaseComponentProps {
    clWorkerId: number;
    clCaseId: number;
    stage: string;
    onShowList: (refreshCounters?: boolean) => void;
    currentUserId: number;
    currentUserName: string;
    superUserPermission: boolean;
    viewerPermission: boolean;
    defForm: ICLDefForm;
    onShowHistoricCase?: (workerId: number, caseId: number, stage: string) => void;
    historicCase?: boolean;
    onShowCaseTab?: () => void;
    users: IUser[];
    superUsersAndViewers: IUser[];
    fullControlFolderRoleId: number;
    currentUserPrincipalId: number;
    clGenders: IEntity[];
    clStaffGrades: IEntity[];
    directorates: IEntity[];
    clProfessionalCats: IEntity[];
    clWorkLocations: IEntity[];
    clComFrameworks: IEntity[];
    clIR35Scopes: IEntity[];
    clSecurityClearances: IEntity[];
    clDeclarationConflicts: IEntity[];
    personTitles: IEntity[];
    loadingCaseTabCoreData: boolean;
    isMobile: boolean;
}

export interface ILookupData {
}

export class LookupData implements ILookupData {
}

export interface INewCaseTabState {
    Loading: boolean;
    LookupData: ILookupData;
    FormData: ICLCase;
    FormDataBeforeChanges: ICLCase;
    FormDataWorker: ICLWorker;
    CaseInfo: IClCaseInfo;
    FormIsDirty: boolean;
    Evidence_ListFilterText: string;
    ShowIR35EvidenceForm: boolean;
    ShowContractorSecurityCheckEvidenceForm: boolean;
    IR35Evidence: ICLCaseEvidence;
    ContractorSecurityCheckEvidence: ICLCaseEvidence;
    HideIR35EvDeleteDialog: boolean;
    HideContractorSecurityCheckEvDeleteDialog: boolean;
    HideFormValidationMessage: boolean;
    HideSubmitApprovalDoneMessage: boolean;
    HideSubmitEngagedDoneMessage: boolean;
    EvidenceChangesCounter: number;
    Engaged_MoveToChecksDoneBtn: boolean;
    Leaving_MoveToArchiveBtn: boolean;
    Stage: string;
    HideRequirementInfoSection: boolean;
    ShowAllowChangeHM: boolean;
    ShowAllowChangeRequirement: boolean;
    ShowAllowChangeCommercial: boolean;
    ShowAllowChangeResourcingJustification: boolean;
    ShowAllowChangeFinance: boolean;
    ShowAllowChangeOther: boolean;
    ShowAllowChangeApprovers: boolean;
    ShowAllowChangeOnboarding: boolean;
    ShowHelpPanel: boolean;
    UserHelpText: string;
    DisableFinEstCost: boolean;
    CreateFolderWaitMessage: string;
}

export class NewCaseTabState implements INewCaseTabState {
    public Loading = false;
    public LookupData = new LookupData();
    public FormData;
    public FormDataBeforeChanges;
    public FormDataWorker;
    public CaseInfo;
    public FormIsDirty = false;
    public Evidence_ListFilterText: string = null;
    public ShowIR35EvidenceForm: boolean = false;
    public ShowContractorSecurityCheckEvidenceForm: boolean = false;
    public IR35Evidence: ICLCaseEvidence = null;
    public ContractorSecurityCheckEvidence: ICLCaseEvidence = null;
    public HideIR35EvDeleteDialog: boolean = true;
    public HideContractorSecurityCheckEvDeleteDialog: boolean = true;
    public HideFormValidationMessage: boolean = true;
    public HideSubmitApprovalDoneMessage: boolean = true;
    public HideSubmitEngagedDoneMessage: boolean = true;
    public EvidenceChangesCounter: number = 0;
    public Engaged_MoveToChecksDoneBtn = false;
    public Leaving_MoveToArchiveBtn: boolean = false;
    public Stage: string = ""; //set in componentDidMount
    public HideRequirementInfoSection = false;
    public ShowAllowChangeHM: boolean = true;
    public ShowAllowChangeRequirement: boolean = true;
    public ShowAllowChangeCommercial: boolean = true;
    public ShowAllowChangeResourcingJustification: boolean = true;
    public ShowAllowChangeFinance: boolean = true;
    public ShowAllowChangeOther: boolean = true;
    public ShowAllowChangeApprovers: boolean = true;
    public ShowAllowChangeOnboarding: boolean = true;
    public ShowHelpPanel = false;
    public UserHelpText = "";
    public DisableFinEstCost: boolean = true;
    public CreateFolderWaitMessage: string = "";

    constructor(caseType: string) {
        this.FormData = new CLCase(caseType);
        this.FormDataBeforeChanges = new CLCase(caseType);
        this.CaseInfo = new ClCaseInfo();
        this.FormDataWorker = new CLWorker();
    }
}

export interface IExtHistroyLink {
    CaseId: number;
    WorkerId: number;
    Stage: string;
    Text: string;
}

class NewCaseTab extends React.Component<INewCaseTabProps, INewCaseTabState> {
    private clCaseService: services.CLCaseService = new services.CLCaseService();
    private clWorkerService: services.CLWorkerService = new services.CLWorkerService();
    private clCaseEvidenceService: services.CLCaseEvidenceService = new services.CLCaseEvidenceService();
    private clHiringMemberService: services.CLHiringMemberService = new services.CLHiringMemberService();
    private zFileService: services.ZFileService = new services.ZFileService();

    private StayOnNewCaseTab: boolean = false;

    //IChoiceGroupOption
    private approvalDecisionItems: any[] = [
        { key: 'Approve', text: 'Approve\u00A0\u00A0\u00A0', afterDecisionText: 'Approved\u00A0\u00A0\u00A0' },
        { key: 'Reject', text: 'Reject\u00A0\u00A0\u00A0', afterDecisionText: 'Rejected\u00A0\u00A0\u00A0' },
        { key: 'RequireDetails', text: 'Require further details\u00A0\u00A0\u00A0', afterDecisionText: 'Require further details\u00A0\u00A0\u00A0' },
    ];

    private checkIconGreen: string = require('../../images/greentick1212.png');
    private checkIconRed: string = require('../../images/redtick1212.png');
    private helpIcon: string = require('../../images/help2.png');


    private childEntities: types.IFormDataChildEntities[] = [
        { ObjectParentProperty: 'CLHiringMembers', ParentIdProperty: 'CLCaseId', ChildIdProperty: 'UserId', ChildService: this.clHiringMemberService },
    ];

    constructor(props: INewCaseTabProps, state: INewCaseTabState) {
        super(props);
        this.state = new NewCaseTabState('New Case');

    }

    public render(): React.ReactElement<INewCaseTabProps> {

        //stages
        /*
            1: Draft
            2: Approval
            3: Onboarding
            4: Engaged
            5: Leaving
            6: Left
            7: Extended
        
        */

        const { FormData, FormDataWorker } = this.state;
        const { clWorkerId, loadingCaseTabCoreData } = this.props;

        const caseLoaded: boolean = FormData?.ID > 0;
        const workerLoaded: boolean = clWorkerId > 0 ? FormDataWorker?.ID > 0 : true;

        const loadingData: boolean = !(caseLoaded && workerLoaded);
        console.log('Loading data', loadingData);

        const caseCreated = this.checkCaseCreated();
        let isViewOnly: boolean = this.isViewOnlyPermission();

        const stage = this.state.Stage;
        const fdw = FormDataWorker;

        let archived: boolean = false;
        if (fdw.Archived === true) {
            archived = true;
        }

        // let loadingData: boolean = true;

        // const caseLoaded: boolean = this.state.FormData?.ID > 0;
        // const workerLoaded: boolean = this.props.clWorkerId > 0 ? this.state.FormDataWorker?.ID > 0 : true;

        // loadingData = !(caseLoaded && workerLoaded);
        // console.log('Loading data', loadingData);

        return (
            <div className={styles.cr} style={{ width: '98%', minHeight: '400px', position: 'relative' }}>
                {this.renderSectionTitle()}

                <CrLoadingOverlay isLoading={loadingData} />
                {
                    loadingCaseTabCoreData === false && loadingData === false && this.state.FormData?.ID > 0 &&
                    <React.Fragment>

                        {this.renderInfoTable()}

                        {isViewOnly === false && this.renderDetailsOfApplicant()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderDetailsOfApplicant_info()}

                        {isViewOnly === false && this.renderRequirement()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderRequirement_info()}

                        {isViewOnly === false && this.renderCommercial()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderCommercial_info()}

                        {isViewOnly === false && this.renderResourcingJustification()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderResourcingJustification_info()}

                        {isViewOnly === false && this.renderFinance()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderFinance_info()}

                        {isViewOnly === false && this.renderOther()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderOther_info()}

                        {isViewOnly === false && this.renderApprovers()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderApprovers_info()}

                        {stage === "Draft" && isViewOnly === false && this.renderFormButtons_DraftStage()}

                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderBudgetHolderApprovalDecision_info()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderFinanceBusinessPartnerApprovalDecision_info()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderHRBusinessPartnerApprovalDecision_info()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderCommercialPartnerApprovalDecision_info()}
                        {(stage === "Approval" || stage == "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended" || isViewOnly === true) && this.renderCLApprovalDecision_info()}

                        {archived === false && (stage === "Approval") && this.renderBudgetHolderApprovalDecision()}
                        {archived === false && (stage === "Approval") && this.renderFinanceBusinessPartnerApprovalDecision()}
                        {archived === false && (stage === "Approval") && this.renderHRBusinessPartnerApprovalDecision()}
                        {archived === false && (stage === "Approval") && this.renderCommercialBusinessPartnerApprovalDecision()}
                        {archived === false && (stage === "Approval") && this.renderCLApprovalDecision()}
                        {archived === false && stage === "Approval" && this.renderFormButtons_ApprovalStage()}

                        {/*stage === "Onboarding" && */isViewOnly === false && this.renderOnboarding()}

                        {stage === "Onboarding" && isViewOnly === false && this.renderFormButtons_OnboardingStage()}
                        {((stage === "Onboarding" && isViewOnly === true) || (stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended")) && this.renderOnboarding_info()}

                        {stage === "Engaged" && isViewOnly === false && this.renderEngaged()}
                        {stage === "Engaged" && isViewOnly === false && this.renderFormButtons_EngagedStage()}

                        {((stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended") && (isViewOnly === true || fdw.EngagedChecksDone === true)) && this.renderEngaged_info()}

                        {stage === "Leaving" && isViewOnly === false && this.renderLeaving()}
                        {stage === "Leaving" && isViewOnly === false && this.renderFormButtons_LeavingStage()}
                        {((stage === "Left") || (stage === "Leaving" && isViewOnly === true)) && this.renderLeaving_info()}

                        {(caseCreated === true) && this.renderListsMainTitle()}

                        {(caseCreated === true) && this.renderEvidencesList()}
                        {(caseCreated === true) && this.renderChangeLogs()}

                        {/*this.props.historicCase === true && */(caseCreated === true) && this.renderCloseButton_HistoricCase()}

                        {this.state.ShowIR35EvidenceForm && this.renderIR35EvidenceForm()}
                        <ConfirmDialog hidden={this.state.HideIR35EvDeleteDialog} title={`Are you sure you want to delete this IR35 assessment  evidence?`} content={`A deleted evidence cannot be un-deleted.`} confirmButtonText="Delete" handleConfirm={this.deleteIR35Evidence} handleCancel={this.toggleIR35EvDeleteConfirm} />

                        {this.state.ShowContractorSecurityCheckEvidenceForm && this.renderContractorSecurityCheckEvidenceForm()}
                        <ConfirmDialog hidden={this.state.HideContractorSecurityCheckEvDeleteDialog} title={`Are you sure you want to delete this security checks confirmation evidence?`} content={`A deleted evidence cannot be un-deleted.`} confirmButtonText="Delete" handleConfirm={this.deleteContractorSecurityCheckEvidence} handleCancel={this.toggleContractorSecurityCheckEvDeleteConfirm} />

                        {/* validation */}
                        <MessageDialog hidden={this.state.HideFormValidationMessage} title="Validation Failed" content="Failed validation checks. Please ensure all fields marked with a red asterisk are completed." handleOk={() => { this.setState({ HideFormValidationMessage: true }); }} />

                        {/* submit for approval - done */}
                        <MessageDialog hidden={this.state.HideSubmitApprovalDoneMessage} title="Validation Successful" content="Validation checks completed successfully. This case is being moved to the approvals stage." handleOk={() => { this.setState({ HideSubmitApprovalDoneMessage: true }, () => this.props.onShowList(true)); }} />

                        {/* submit to engaged - done */}
                        <MessageDialog hidden={this.state.HideSubmitEngagedDoneMessage} title="Validation Successful" content="Validation checks completed successfully. This case is being moved to the engaged stage." handleOk={() => { this.setState({ HideSubmitEngagedDoneMessage: true }, () => this.afterSubmitEngagedSuccessMsg()); }} />

                        <Panel isOpen={this.state.ShowHelpPanel} headerText="" type={PanelType.medium} onDismiss={this.hideHelpPanel} >
                            <div dangerouslySetInnerHTML={{ __html: this.state.UserHelpText }}></div>
                        </Panel>
                    </React.Fragment>
                }

            </div>
        );
    }

    private renderSectionTitle() {
        return (
            <React.Fragment>
                <h1 className={styles.headings2}>Contingent Labour Business Case</h1>
            </React.Fragment>
        );
    }

    private renderInfoTable() {
        const cInfo = this.state.CaseInfo;
        let arrLinks: IExtHistroyLink[] = [];
        //unpack publication links from single value
        if (this.props.historicCase !== true && cInfo.ExtensionHistory !== null && cInfo.ExtensionHistory !== '') {
            let arr1 = cInfo.ExtensionHistory.split('^');
            for (let i = 0; i < arr1.length; i++) {
                let itemStr: string = arr1[i];
                if (itemStr.trim() === '') {
                    continue;
                }
                let arr2 = itemStr.split('|');
                let item: IExtHistroyLink = { CaseId: 0, WorkerId: 0, Stage: '', Text: '' };
                item.CaseId = Number(arr2[0]);
                item.WorkerId = Number(arr2[1]);
                item.Stage = arr2[2];
                item.Text = arr2[3];
                arrLinks.push(item);
                arrLinks = arrLinks.reverse();
            }
        }

        return (

            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '50px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Case Details</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '8px' }} >{(this.props.defForm?.CaseDetailsHelpText && this.props.defForm?.CaseDetailsHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.CaseDetailsHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>


                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Stage
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.Stage}
                                </td>
                                <td className="label">
                                    Case Ref
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.CaseRef}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Case ID
                                </td>
                                <td className="value">
                                    {this.state.FormDataWorker && (this.state.FormDataWorker.ID > 0) && this.state.FormData.ID}
                                </td>
                                <td className="label">
                                    Worker ID
                                </td>
                                <td className="value">
                                    {this.state.FormDataWorker && (this.state.FormDataWorker.ID > 0) && this.state.FormDataWorker.ID}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Created By
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.CreatedBy}
                                </td>
                                <td className="label">
                                    Created On
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.CreatedOn}
                                </td>
                            </tr>
                            {arrLinks.length > 0 &&
                                <tr>
                                    <td className="label">
                                        Extension History
                                    </td>
                                    <td colSpan={3} className="value">
                                        {arrLinks.map((c, i) =>
                                            <span style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue', marginRight: '15px' }} key={`span_ExtHis_${i}`} onClick={() => this.onViewExtHistroyClick(c.CaseId, c.WorkerId, c.Stage)} >{c.Text}</span>
                                        )}
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>





            </React.Fragment>
        );
    }

    private renderDetailsOfApplicant() {
        const fd = this.state.FormData;

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeHM === false)) {
            //no action
        }
        else return null;

        const fd_users: ICLHiringMember[] = this.state.FormData['CLHiringMembers'];
        const applHMUserIdValidationImg = this.state.FormData.ApplHMUserId !== null ? this.checkIconGreen : this.checkIconRed;
        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Details of Applicant</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.DetailsOfApplicantEditHelpText && this.props.defForm?.DetailsOfApplicantEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.DetailsOfApplicantEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>

                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Name of hiring manager</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={applHMUserIdValidationImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%', }}>
                                <CrUserPicker
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={this.state.FormData.ApplHMUserId && [this.state.FormData.ApplHMUserId]}
                                    onChange={(v) => this.changeUserPicker(v, 'ApplHMUserId')}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}

                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Hiring team member</span></div>
                                    <div className={styles.sectionQuestionCol2}>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%', }}>
                                <CrUserPicker                                 
                                    users={this.props.users}
                                    itemLimit={10}
                                    selectedUsers={fd_users && fd_users.map((owner) => { return owner.UserId; })}
                                    onChange={(v) => this.changeMultiUserPicker(v, 'CLHiringMembers', new CLHiringMember(), 'UserId')}
                                />
                            </div>
                        </div>
                        <div style={{ fontSize: '14px', color: 'navy', fontStyle: 'italic', paddingTop: '0px', marginTop: '2px' }}>
                            Include only essential team members as this form contains personal information.
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeHM === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeHM();
                                    this.saveData(false, false, true);
                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeHM}>Cancel</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderRequirement() {

        if (this.props.defForm === null) return;

        const fd = this.state.FormData;
        const isMobile = this.props.isMobile;

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeRequirement === false)) {
            //no action
        }
        else return null;

        //dont show this section if user is viewer

        const reqVacancyTitleValidationImg = fd.ReqVacancyTitle !== null && fd.ReqVacancyTitle.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const reqGradeIdValidationImg = fd.ReqGradeId !== null ? this.checkIconGreen : this.checkIconRed;
        const reqWorkPurposeValidationImg = fd.ReqWorkPurpose !== null && fd.ReqWorkPurpose.length > 9 ? this.checkIconGreen : this.checkIconRed;
        const reqCostCentreValidationImg = fd.ReqCostCentre !== null && fd.ReqCostCentre.length === 6 ? this.checkIconGreen : this.checkIconRed;
        const reqDirectorateIdValidationImg = fd.ReqDirectorateId !== null ? this.checkIconGreen : this.checkIconRed;
        const reqEstStartDateValidationImg = fd.ReqEstStartDate !== null ? this.checkIconGreen : this.checkIconRed;
        const reqEstEndDateValidationImg = fd.ReqEstEndDate !== null && fd.ReqEstEndDate > fd.ReqEstStartDate ? this.checkIconGreen : this.checkIconRed;
        const reqProfessionalCatIdValidationImg = fd.ReqProfessionalCatId !== null ? this.checkIconGreen : this.checkIconRed;
        const reqWorkLocationIdValidationImg = fd.ReqWorkLocationId !== null ? this.checkIconGreen : this.checkIconRed;
        const reqNumPositionsValidationImg = fd.ReqNumPositions !== null && fd.ReqNumPositions > 0 ? this.checkIconGreen : this.checkIconRed;

        //Labels row 1
        const titleOfVacancyLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Title of vacancy</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqVacancyTitleValidationImg} />
                </div>
            </div>
        </div>;

        const gradeOfVacancyLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Grade of vacancy</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqGradeIdValidationImg} />
                </div>
            </div>
        </div>;

        //Labels row 3
        const costCenterLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Cost Centre for this role</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqCostCentreValidationImg} />
                </div>
            </div>
        </div>;

        const directorateRoleLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Directorate this role will be in</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqDirectorateIdValidationImg} />
                </div>
            </div>
        </div>;

        //Labels row 4
        const estStartDateLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Estimated start date</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqEstStartDateValidationImg} />
                </div>
            </div>
        </div>;

        const estEndDateLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Estimated end date</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqEstEndDateValidationImg} />
                </div>
            </div>
        </div>;

        //Labels row 5
        const professionalCategoryLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Professional Category</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqProfessionalCatIdValidationImg} />
                </div>
            </div>
        </div>;

        const workLocationLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Work location</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqWorkLocationIdValidationImg} />
                </div>
            </div>
        </div>;

        //Label row 6
        const numPositionsLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Number of positions</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={reqNumPositionsValidationImg} />
                </div>
            </div>
        </div>



        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Requirement</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.RequirementEditHelpText && this.props.defForm?.RequirementEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.RequirementEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                {
                    <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                        <div className={styles.formField}>
                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                {titleOfVacancyLabel}
                                {gradeOfVacancyLabel}
                            </div>}
                            <div className='two-fields-sidebyside-r2'>
                                {isMobile && titleOfVacancyLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrTextField
                                        onChange={(_, v) => this.changeTextField(v, "ReqVacancyTitle")}
                                        value={fd.ReqVacancyTitle}
                                    />
                                </div>
                                {isMobile && gradeOfVacancyLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrDropdown
                                        placeholder="Select an Option"
                                        options={this.props.clStaffGrades.map((p) => { return { key: p.ID, text: p.Title }; })}
                                        selectedKey={fd.ReqGradeId}
                                        onChange={(_, v) => this.changeDropdown(v, "ReqGradeId")}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 2nd row */}

                        <div className={styles.formField}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '100%', fontWeight: 'bold' }}>
                                    <div className={styles.flexContainerSectionQuestion}>
                                        <div className={styles.sectionQuestionCol1}><span>Work proposal (what will they be doing? )</span></div>
                                        <div className={styles.sectionQuestionCol2}>

                                            {/* inner flex to line 2 images */}
                                            <div style={{ display: 'flex' }}>
                                                <div><img src={reqWorkPurposeValidationImg} /></div>
                                                {/* <div style={{ paddingLeft: '10px', paddingTop: '2px' }} >{(this.props.defForm?.WorkProposalHelpText && this.props.defForm?.WorkProposalHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.WorkProposalHelpText)}><img src={this.helpIcon} /></a>}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', marginTop: '5px' }}>
                                <div style={{ width: '100%' }}>
                                    <CrTextField
                                        multiline={true}
                                        rows={6}
                                        onChange={(_, v) => this.changeTextField(v, "ReqWorkPurpose")}
                                        value={fd.ReqWorkPurpose}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 3rd row */}

                        <div className={styles.formField}>
                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                {costCenterLabel}
                                {directorateRoleLabel}
                            </div>}
                            <div className='two-fields-sidebyside-r2'>
                                {isMobile && costCenterLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrTextField
                                        maxLength={6}
                                        onChange={(_, v) => this.changeTextField_number(v, "ReqCostCentre")}
                                        value={fd.ReqCostCentre}
                                    />
                                </div>
                                {isMobile && directorateRoleLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrDropdown
                                        placeholder="Select an Option"
                                        options={this.props.directorates.map((p) => { return { key: p.ID, text: p.Title }; })}
                                        selectedKey={fd.ReqDirectorateId}
                                        onChange={(_, v) => this.changeDropdown(v, "ReqDirectorateId")}
                                    />
                                </div>
                            </div>
                        </div>


                        {/* 4th row */}

                        <div className={styles.formField}>
                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                {estStartDateLabel}
                                {estEndDateLabel}
                            </div>}
                            <div className='two-fields-sidebyside-r2'>
                                {isMobile && estStartDateLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrDatePicker
                                        maxWidth='100%'
                                        value={services.DateService.removeTimezoneOffset(fd.ReqEstStartDate)}
                                        onSelectDate={(v) => changeDatePickerV2(this, 'FormData', v, "ReqEstStartDate", this.calculateTotalDays)}
                                    />
                                </div>
                                {isMobile && estEndDateLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrDatePicker
                                        maxWidth='100%'
                                        value={services.DateService.removeTimezoneOffset(fd.ReqEstEndDate)}
                                        onSelectDate={(v) => changeDatePickerV2(this, 'FormData', v, "ReqEstEndDate", this.calculateTotalDays)}
                                    />
                                </div>
                            </div>
                            {
                                (fd.ReqEstStartDate !== null && fd.ReqEstEndDate !== null && (fd.ReqEstEndDate <= fd.ReqEstStartDate)) &&
                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: '50%' }}>&nbsp;</div>
                                    <div style={{ width: '50%', fontSize: '12px', fontStyle: 'italic', paddingTop: '5px', marginTop: '0px', paddingLeft: '0px' }}>
                                        End date should be greater than start date
                                    </div>
                                </div>
                            }
                        </div>

                        {/* 5th row */}
                        <div className={styles.formField}>
                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                {professionalCategoryLabel}
                                {workLocationLabel}
                            </div>}
                            <div className='two-fields-sidebyside-r2'>
                                {isMobile && professionalCategoryLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrDropdown
                                        placeholder="Select an Option"
                                        options={this.props.clProfessionalCats.map((p) => { return { key: p.ID, text: p.Title }; })}
                                        selectedKey={fd.ReqProfessionalCatId}
                                        onChange={(_, v) => this.changeDropdown(v, "ReqProfessionalCatId")}
                                    />
                                </div>
                                {isMobile && workLocationLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrDropdown
                                        placeholder="Select an Option"
                                        options={this.props.clWorkLocations.map((p) => { return { key: p.ID, text: p.Title }; })}
                                        selectedKey={fd.ReqWorkLocationId}
                                        onChange={(_, v) => this.changeDropdown(v, "ReqWorkLocationId")}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* 6th row */}

                        <div className={styles.formField}>

                            {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                                {numPositionsLabel}
                                <div style={{ width: '50%', fontWeight: 'bold' }}>
                                    <span>&nbsp;</span>
                                </div>
                            </div>}
                            <div className='two-fields-sidebyside-r2'>
                                {isMobile && numPositionsLabel}
                                <div className='two-fields-sidebyside-field'>
                                    <CrTextField
                                        onChange={(_, v) => this.changeTextField_ReqNumPositions(v, "ReqNumPositions")}
                                        value={String(fd.ReqNumPositions)}
                                        onBlur={(ev) => this.blurFinBillableRate(ev, "FinBillableRate")}
                                        maxLength={2}
                                        readonly={fd.CaseType === "Extension" ? true : false}
                                    />
                                </div>
                                {
                                    fd.CaseType !== "Extension" &&
                                    <div className='two-fields-sidebyside-field'>
                                        <div style={{ fontSize: '14px', color: 'navy', fontStyle: 'italic', paddingTop: '0px', marginTop: '0px', paddingLeft: '10px' }}>
                                            Note: if case has multiple positions, the system will only show it as one case to the approvers. Once the case has been approved, it will create multiple records for onboarding each worker individually. Max limit is set to 30. Contact Internal Controls if you require more.
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ marginBottom: '10px' }}>
                            {this.state.ShowAllowChangeRequirement === false &&
                                <div>
                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                        this.toggleAllowChangeRequirement();
                                        this.saveData(false, false, true);

                                    }}>Save</span>&nbsp;&nbsp;
                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeRequirement}>Cancel</span>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        );
    }

    private renderCommercial() {

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeCommercial === false)) {
            //no action
        }
        else return null;

        const isMobile = this.props.isMobile;
        const fd = this.state.FormData;
        const comFrameworkIdValidationImg = fd.ComFrameworkId !== null ? this.checkIconGreen : this.checkIconRed;
        const comPSRAccountIdValidationImg = fd.ComPSRAccountId !== null ? this.checkIconGreen : this.checkIconRed;

        let comJustificationValidationImg: string = "";
        if (fd.ComFrameworkId > 1) {
            if (fd.ComJustification !== null && fd.ComJustification.length > 9) {
                comJustificationValidationImg = this.checkIconGreen;
            }
            else {
                comJustificationValidationImg = this.checkIconRed;
            }
        }

        let yesNoNaOptions: IDropdownOption[] = [];

        if (this.state.FormData.ComFrameworkId === 1) {
            //PSR
            yesNoNaOptions = [
                { key: 'Yes', text: 'Yes' },
                { key: 'No', text: 'No' },
            ];
        }
        else {
            yesNoNaOptions = [
                { key: 'NA', text: 'N/A' },
            ];
        }

        const frameworkLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Framework</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={comFrameworkIdValidationImg} />
                </div>
            </div>
        </div>;

        const confirmFieldglassAccountLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Confirm if you have a Fieldglass account</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={comPSRAccountIdValidationImg} />
                </div>
            </div>
        </div>;

        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Commercial</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.CommercialEditHelpText && this.props.defForm?.CommercialEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.CommercialEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {frameworkLabel}
                            {fd.ComFrameworkId === 1 && confirmFieldglassAccountLabel}
                        </div>}

                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && frameworkLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.clComFrameworks.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={this.state.FormData.ComFrameworkId}
                                    onChange={(_, v) => this.changeDropdown(v, "ComFrameworkId")}
                                />
                            </div>
                            {fd.ComFrameworkId === 1 && <> {isMobile && confirmFieldglassAccountLabel} <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={yesNoNaOptions}
                                    selectedKey={this.state.FormData.ComPSRAccountId}
                                    onChange={(_, v) => this.changeDropdown(v, "ComPSRAccountId")}
                                />

                                {fd.ComPSRAccountId === 'No' && <div style={{ color: 'navy', fontSize: '14px', fontStyle: 'italic', paddingTop: '5px', marginTop: '0px', paddingLeft: '0px' }}>
                                    Note: Please contact PSR help desk to have one arranged, you will have to raise a worker requirement on Fieldglass. Email: helpdesk@publicsectorresourcing.co.uk  Phone: 0203 862 2487"
                                </div>}
                            </div></>}
                        </div>
                    </div>

                    {/* 2nd row */}

                    {fd.ComFrameworkId > 1 && <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>

                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Justification if not PSR</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={comJustificationValidationImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%' }}>
                                <CrTextField
                                    multiline={true}
                                    rows={6}
                                    onChange={(_, v) => this.changeTextField(v, "ComJustification")}
                                    value={fd.ComJustification}
                                />
                            </div>
                        </div>
                    </div>}

                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeCommercial === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeCommercial();
                                    this.saveData(false, false, true);

                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeCommercial}>Cancel</span>
                            </div>
                        }
                    </div>



                </div>





            </div>
        );
    }

    private renderResourcingJustification() {

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeResourcingJustification === false)) {
            //no action
        }
        else return null;

        const fd = this.state.FormData;

        const justAltOptionsValidationImg = fd.JustAltOptions !== null && fd.JustAltOptions.length > 9 ? this.checkIconGreen : this.checkIconRed;
        const justSuccessionPlanningValidationImg = fd.JustSuccessionPlanning !== null && fd.JustSuccessionPlanning.length > 9 ? this.checkIconGreen : this.checkIconRed;

        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }} >
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Resourcing Justification</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.ResourcingJustificationEditHelpText && this.props.defForm?.ResourcingJustificationEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.ResourcingJustificationEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Alternative resourcing options: set out what other options have been considered and why these are not suitable</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={justAltOptionsValidationImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%' }}>
                                <CrTextField
                                    multiline={true}
                                    rows={6}
                                    onChange={(_, v) => this.changeTextField(v, "JustAltOptions")}
                                    value={fd.JustAltOptions}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}

                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Succession planning: explain how you plan to manage knowledge transfer and reduce reliance on contingent labour</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={justSuccessionPlanningValidationImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%' }}>
                                <CrTextField
                                    multiline={true}
                                    rows={6}
                                    onChange={(_, v) => this.changeTextField(v, "JustSuccessionPlanning")}
                                    value={fd.JustSuccessionPlanning}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeResourcingJustification === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeResourcingJustification();
                                    this.saveData(false, false, true);

                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeResourcingJustification}>Cancel</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderFinance() {

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeFinance === false)) {
            //no action
        }
        else return null;

        const isMobile = this.props.isMobile;
        const caseCreated = this.checkCaseCreated();

        const fd = this.state.FormData;

        const finBillableRateValidationImg = fd.FinBillableRate !== null && fd.FinBillableRate > 0 ? this.checkIconGreen : this.checkIconRed;
        const finTotalDaysValidationImg = fd.FinTotalDays !== null && fd.FinTotalDays > 0 ? this.checkIconGreen : this.checkIconRed;
        const finMaxRateValidationImg = fd.FinMaxRate !== null && fd.FinMaxRate > 0 ? this.checkIconGreen : this.checkIconRed;
        const finEstCostValidationImg = fd.FinEstCost !== null && fd.FinEstCost > 0 ? this.checkIconGreen : this.checkIconRed;
        const finCostPerWorkerValidationImg = fd.FinCostPerWorker !== null && fd.FinCostPerWorker > 0 ? this.checkIconGreen : this.checkIconRed;
        const finIR35ScopeIdValidationImg = fd.FinIR35ScopeId !== null ? this.checkIconGreen : this.checkIconRed;
        const finCalcTypeValidationImg = fd.FinCalcType !== null ? this.checkIconGreen : this.checkIconRed;
        const iR35EvidenceValidationImg = this.state.IR35Evidence !== null ? this.checkIconGreen : this.checkIconRed;
        const approachToAgreeingRateValidationImg = fd.FinApproachAgreeingRate !== null && fd.FinApproachAgreeingRate.length > 9 ? this.checkIconGreen : this.checkIconRed;
        const summaryIR35JustificatValidationImg = fd.FinSummaryIR35Just !== null && fd.FinSummaryIR35Just.length > 9 ? this.checkIconGreen : this.checkIconRed;

        //first row labels
        const billableDayRateLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Billable day rate (pay rate plus fees)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finBillableRateValidationImg} />
                </div>
            </div>
        </div>;

        const rateForApprovalLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}>
                    <span>Rate for approval (billable day rate plus non-recoverable VAT)</span>
                    {this.state.FormData.FinMaxRate >= 750 && <span style={{ color: 'rgb(254,138,53)', fontStyle: 'italic', paddingLeft: '15px' }}>Additional approval required</span>}
                </div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finMaxRateValidationImg} />
                </div>
            </div>
        </div>;

        //2nd row labels
        const totalDaysLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Total days (Will be taken from start and end dates. Change if required)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finTotalDaysValidationImg} />
                </div>
            </div>
        </div>;

        const totalAssignmentCostPerWorkerCalcLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Total assignment cost per worker calculation</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finCalcTypeValidationImg} />
                </div>
            </div>
        </div>;

        //3rd row labels
        const totalAssignmentCostLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Total assignment cost</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finEstCostValidationImg} />
                </div>
            </div>
        </div>;

        const totalAssignmentCostPerWorkerLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Total assignment cost per worker</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finCostPerWorkerValidationImg} />
                </div>
            </div>
        </div>;

        //5th row labels
        const confirmInIR35Label = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Confirm whether in-scope of IR35</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={finIR35ScopeIdValidationImg} />
                </div>
            </div>
        </div>;

        const attachIR35Label = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Attach IR35 evidence</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={iR35EvidenceValidationImg} />
                </div>
            </div>
        </div>;



        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Finance</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.FinanceEditHelpText && this.props.defForm?.FinanceEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.FinanceEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {billableDayRateLabel}
                            {rateForApprovalLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && billableDayRateLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onBlur={(ev) => this.blurFinBillableRate(ev, "FinBillableRate")}
                                    onChange={(_, v) => this.changeTextField_number(v, "FinBillableRate")}
                                    value={fd.FinBillableRate && String(fd.FinBillableRate)}
                                />
                            </div>
                            {isMobile && rateForApprovalLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    disabled={true}
                                    onChange={(_, v) => this.changeTextField_number(v, "FinMaxRate")}
                                    value={fd.FinMaxRate && String(fd.FinMaxRate)}
                                    style={{ border: '1px solid gray' }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {totalDaysLabel}
                            {totalAssignmentCostPerWorkerCalcLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && totalDaysLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onBlur={(ev) => this.blurFinBillableRate(ev, "FinBillableRate")}
                                    onChange={(_, v) => this.changeTextField_number(v, "FinTotalDays")}
                                    value={fd.FinTotalDays && String(fd.FinTotalDays)}
                                />
                            </div>
                            {isMobile && totalAssignmentCostPerWorkerCalcLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    options={[{ key: 'Automatic', text: 'Automatic' }, { key: 'Manual', text: 'Manual' }]}
                                    onChange={(_, v) => this.changeDropdown_FinCalcType(v, "FinCalcType")}
                                    selectedKey={this.state.FormData.FinCalcType}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 3rd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {totalAssignmentCostLabel}
                            {totalAssignmentCostPerWorkerLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && totalAssignmentCostLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onBlur={(ev) => this.blurRateTextField(ev, "FinEstCost")}
                                    onChange={(_, v) => this.changeTextField_number(v, "FinEstCost")}
                                    value={fd.FinEstCost && String(fd.FinEstCost)}
                                    disabled={this.state.DisableFinEstCost}
                                    style={{ border: '1px solid gray' }}
                                />
                            </div>
                            {isMobile && totalAssignmentCostPerWorkerLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_number(v, "FinCostPerWorker")}
                                    value={fd.FinCostPerWorker && String(fd.FinCostPerWorker)}
                                    disabled={this.state.DisableFinEstCost}
                                    style={{ border: '1px solid gray' }}
                                />
                            </div>
                        </div>
                    </div>


                    {/* 4th row */}

                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Approach to agreeing rate</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={approachToAgreeingRateValidationImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%' }}>
                                <CrTextField
                                    multiline={true}
                                    rows={6}
                                    onChange={(_, v) => this.changeTextField(v, "FinApproachAgreeingRate")}
                                    value={fd.FinApproachAgreeingRate}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 5th row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {confirmInIR35Label}
                            {attachIR35Label}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && confirmInIR35Label}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.clIR35Scopes.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={this.state.FormData.FinIR35ScopeId}
                                    onChange={(_, v) => this.changeDropdown(v, "FinIR35ScopeId")}
                                />
                            </div>

                            {(caseCreated === true) && <>{isMobile && attachIR35Label} <div className='two-fields-sidebyside-field' style={isMobile != true ? { width: 'calc(100% - 50% - 130px)', paddingRight: '5px' } : {}}>
                                <CrTextField
                                    disabled={true}
                                    style={{ border: '1px solid gray' }}
                                    value={this.state.IR35Evidence && (this.state.IR35Evidence.AttachmentType === "Link" ? "Linked evidence available" : this.state.IR35Evidence.AttachmentType === "PDF" ? "PDF evidence available to download" : "")}
                                />
                            </div></>}
                            {(caseCreated === true) && <div style={{ width: '130px', marginTop: '5px' }}>
                                {
                                    <span>
                                        {this.state.IR35Evidence === null && <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.addIr35Evidence} >Add</span>}
                                        {this.state.IR35Evidence !== null && <span style={{ marginRight: '5px', cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.viewIR35Evidence} >View</span>}
                                        {this.state.IR35Evidence !== null && <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.toggleIR35EvDeleteConfirm} >Delete</span>}
                                    </span>
                                }

                            </div>}
                        </div>
                        {
                            this.state.IR35Evidence !== null &&
                            <div style={{ display: 'flex' }}>
                                {isMobile != true && <div style={{ width: '50%' }}>&nbsp;</div>}
                                <div style={{ width: isMobile ? '100%' : '50%', fontSize: '12px', fontStyle: 'italic', paddingTop: '5px', marginTop: '0px', paddingLeft: '0px' }}>
                                    {this.state.IR35Evidence.Details}
                                </div>
                            </div>
                        }
                    </div>

                    {/* 4th row */}
                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Summary IR35 justification</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={summaryIR35JustificatValidationImg} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%' }}>
                                <CrTextField
                                    multiline={true}
                                    rows={6}
                                    onChange={(_, v) => this.changeTextField(v, "FinSummaryIR35Just")}
                                    value={fd.FinSummaryIR35Just}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeFinance === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeFinance();
                                    this.saveData(false, false, true);
                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeFinance}>Cancel</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderOther() {

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeOther === false)) {
            //no action
        }
        else return null;

        const fd = this.state.FormData;

        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Other</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.OtherEditHelpText && this.props.defForm?.OtherEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.OtherEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField}>

                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <span>Any additional comments</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%' }}>
                                <CrTextField
                                    multiline={true}
                                    rows={6}
                                    onChange={(_, v) => this.changeTextField(v, "OtherComments")}
                                    value={fd.OtherComments}
                                />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeOther === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeOther();
                                    this.saveData(false, false, true);
                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeOther}>Cancel</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderApprovers() {

        if (this.props.stage === "Draft" || (this.state.ShowAllowChangeApprovers === false)) {
            //no action
        }
        else return null;

        const isMobile = this.props.isMobile;

        const bhUserIdValidationImg = this.state.FormData.BHUserId !== null ? this.checkIconGreen : this.checkIconRed;
        const fbpUserIdValidationImg = this.state.FormData.FBPUserId !== null ? this.checkIconGreen : this.checkIconRed;
        const hrbpUserIdValidationImg = this.state.FormData.HRBPUserId !== null ? this.checkIconGreen : this.checkIconRed;
        const cbpUserIdValidationImg = this.state.FormData.CBPUserId !== null ? this.checkIconGreen : this.checkIconRed;

        //first row labels
        const budgetHolderLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Budget holder</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={bhUserIdValidationImg} />
                </div>
            </div>
        </div>;

        const financeBusinessPartnerLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Finance business partner</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={fbpUserIdValidationImg} />
                </div>
            </div>
        </div>;

        //2nd row labels
        const hrBusinessPartnerLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>HR business partner</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={hrbpUserIdValidationImg} />
                </div>
            </div>
        </div>;

        const commercialBusinessPartnerLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Commercial business partner</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={cbpUserIdValidationImg} />
                </div>
            </div>
        </div>;



        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Approvers</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.ApproversEditHelpText && this.props.defForm?.ApproversEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.ApproversEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {budgetHolderLabel}
                            {financeBusinessPartnerLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && budgetHolderLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                 
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={this.state.FormData.BHUserId && [this.state.FormData.BHUserId]}
                                    onChange={(v) => this.changeUserPicker(v, 'BHUserId')}
                                />
                            </div>
                            {isMobile && financeBusinessPartnerLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={this.state.FormData.FBPUserId && [this.state.FormData.FBPUserId]}
                                    onChange={(v) => this.changeUserPicker(v, 'FBPUserId')}
                                />
                            </div>
                        </div>
                    </div>


                    {/* 2nd row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {hrBusinessPartnerLabel}
                            {this.state.FormData.ComFrameworkId !== 1 && commercialBusinessPartnerLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && hrBusinessPartnerLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={this.state.FormData.HRBPUserId && [this.state.FormData.HRBPUserId]}
                                    onChange={(v) => this.changeUserPicker(v, 'HRBPUserId')}
                                />
                            </div>
                            {isMobile && this.state.FormData.ComFrameworkId !== 1 && commercialBusinessPartnerLabel}
                            {this.state.FormData.ComFrameworkId !== 1 && (
                                <div className='two-fields-sidebyside-field'>
                                    <CrUserPicker                                        
                                        users={this.props.users}
                                        itemLimit={1}
                                        selectedUsers={this.state.FormData.CBPUserId && [this.state.FormData.CBPUserId]}
                                        onChange={(v) => this.changeUserPicker(v, 'CBPUserId')}
                                    />
                                </div>
                            )}
                        </div>
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeApprovers === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeApprovers();
                                    this.saveData(false, false, true);

                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeApprovers}>Cancel</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderFormButtons_DraftStage() {

        const caseCreated = this.checkCaseCreated();

        return (
            <div>
                {
                    <React.Fragment>
                        {<PrimaryButton text="Save as Draft" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData(false, false)}
                        />}
                        {(caseCreated === true) && <PrimaryButton text="Submit for Approval" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData(true, false)}
                        />}
                        <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.props.onShowList()}
                        />
                    </React.Fragment>
                }

            </div>
        );
    }

    private renderIR35EvidenceForm() {

        if (this.state.FormData === null || this.state.FormData.ID === 0) return null;

        return (
            <EvidenceSaveForm
                showForm={this.state.ShowIR35EvidenceForm}
                caseId={this.state.FormData.ID}
                evidenceId={null}
                onSaved={this.ir35EvidenceSaved}
                onCancelled={this.closeIR35EvidencePanel}
                evidenceType="IR35"
                {...this.props}
            />
        );
    }

    private renderContractorSecurityCheckEvidenceForm() {

        if (this.state.FormData === null || this.state.FormData.ID === 0) return null;

        return (

            <EvidenceSaveForm
                showForm={this.state.ShowContractorSecurityCheckEvidenceForm}
                caseId={this.state.FormData.ID}
                workerId={this.props.clWorkerId}
                evidenceId={null}
                onSaved={this.contractorSecurityCheckEvidenceSaved}
                onCancelled={this.closeContractorSecurityCheckEvidencePanel}
                evidenceType="ContractorSecurityCheck"
                {...this.props}
            />
        );
    }

    private renderChangeLogs() {
        const fdw = this.state.FormDataWorker;
        const fd = this.state.FormData;
        let changeLog = fd.CaseChangeLog ? fd.CaseChangeLog : "";
        let changeLogArr = changeLog.split(',');
        let changeLogs = "";

        changeLogArr.reverse().forEach(log => {
            if (log.length > 0) {
                changeLogs += `${log}<br />`;
            }
        });

        return (
            <React.Fragment>
                {this.props.superUserPermission === true && <div style={{ marginTop: '30px' }}>
                    {fdw.CasePdfStatus !== "Working... Please Wait" && <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.createCasePdf()}>Create Case PDF</div>}
                    {fdw.CasePdfStatus === "Working... Please Wait" && <div>Creating Case PDF... Please Wait.. To refresh status <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.refreshCasePdfStatus()}>Click Here</span></div>}
                    {fdw.CasePdfStatus === "Cr" && <div>Last PDF created by {fdw.CasePdfLastActionUser} on {services.DateService.dateToUkDateTime(fdw.CasePdfDate)} <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.downloadCasePdf()}>Download</span>  </div>}
                    {fdw.CasePdfStatus && fdw.CasePdfStatus.search("Err:") === 0 && <div>Last PDF creation error. Attempted by {fdw.CasePdfLastActionUser} on {services.DateService.dateToUkDateTime(fdw.CasePdfDate)} <br />{fdw.CasePdfStatus}  </div>}
                </div>}
                <div style={{ marginTop: "30px" }}>
                    <div style={{ fontWeight: 'bold' }}>Change Log:</div>
                    <div style={{ marginTop: "20px" }} dangerouslySetInnerHTML={{ __html: changeLogs }} />
                </div>
            </React.Fragment>
        );
    }

    private renderListsMainTitle() {
        return (
            <div style={{ marginBottom: '20px', marginTop: '50px' }}>
                <div style={{ display: 'flex' }}>
                    <div className={styles.sectionATitle}>Case Discussion, General Comments and File Attachments</div>
                    <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.CaseDiscussionHelpText && this.props.defForm?.CaseDiscussionHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.CaseDiscussionHelpText)}><img src={this.helpIcon} /></a>}</div>
                </div>
            </div>
        );
    }

    private renderEvidencesList() {
        return (
            <React.Fragment>
                {this.state.FormData?.ID > 0 && this.state.FormDataWorker?.ID > 0 &&
                    <div style={{ minHeight: '120px' }}>
                        <EvidenceList
                            caseId={this.state.FormData.ID}
                            workerId={this.state.FormDataWorker.ID}
                            currentUserId={this.props.currentUserId}
                            superUserPermission={this.props.superUserPermission}
                            isViewOnly={this.isViewOnlyPermission()}
                            filterText={this.state.Evidence_ListFilterText}
                            onChangeFilterText={this.handleEvidence_ChangeFilterText}
                            evChangesCounter={this.state.EvidenceChangesCounter}
                            {...this.props}
                            errorHandling={this.props.errorHandling}
                        />
                    </div>
                }
            </React.Fragment>
        );
    }

    private renderDetailsOfApplicant_info() {

        const fd = this.state.FormData;
        let allowChange: boolean = false;
        if (this.props.superUserPermission === true || fd.ApplHMUserId === this.props.currentUserId) {
            allowChange = true;
        }
        else {
            let isHiringMember: boolean = false;
            if (fd['CLHiringMembers']) {
                //loop array
                const arrM: any[] = fd['CLHiringMembers'];
                for (let i = 0; i < arrM.length; i++) {
                    if (Number(arrM[i]['UserId']) === this.props.currentUserId) {
                        isHiringMember = true;
                        allowChange = true;
                        break;
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '50px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Details of Applicant</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.DetailsOfApplicantViewHelpText && this.props.defForm?.DetailsOfApplicantViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.DetailsOfApplicantViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Name of hiring manager
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.ApplHMUser}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Hiring team member
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.ApplHMembers}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeHM === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeHM}>Change hiring manager/team member(s) </span>}
                </div>
            </React.Fragment>
        );
    }

    private renderRequirement_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px', }}>

                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Requirement</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.RequirementViewHelpText && this.props.defForm?.RequirementViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.RequirementViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>

                {(this.state.HideRequirementInfoSection === false) &&
                    <div className="info-table-container">
                        <table className="info-table" cellSpacing="0" cellPadding="10">
                            <tbody>
                                <tr>
                                    <td className="label">
                                        Title of vacancy
                                    </td>
                                    <td className="value">
                                        {this.state.FormData.ReqVacancyTitle}
                                    </td>
                                    <td className="label">
                                        Grade of vacancy
                                    </td>
                                    <td className="value">
                                        {this.state.CaseInfo.ReqGrade}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">
                                        Work proposal (what will they be doing?)
                                    </td>
                                    <td colSpan={3} className="value">
                                        <div dangerouslySetInnerHTML={{ __html: this.state.FormData.ReqWorkPurpose && this.state.FormData.ReqWorkPurpose.split('\n').join('<br/>') }} ></div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">
                                        Cost Centre for this role
                                    </td>
                                    <td className="value">
                                        {this.state.FormData.ReqCostCentre}
                                    </td>
                                    <td className="label">
                                        Directorate this role will be in
                                    </td>
                                    <td className="value">
                                        {this.state.CaseInfo.Directorate}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">
                                        Estimated start date
                                    </td>
                                    <td className="value">
                                        {this.state.CaseInfo.ReqEstStartDate}
                                    </td>
                                    <td className="label">
                                        Estimated end date
                                    </td>
                                    <td className="value">
                                        {this.state.CaseInfo.ReqEstEndDate}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">
                                        Professional Category
                                    </td>
                                    <td className="value">
                                        {this.state.CaseInfo.ReqProfessionalCat}
                                    </td>
                                    <td className="label">
                                        Work location
                                    </td>
                                    <td className="value">
                                        {this.state.CaseInfo.ReqWorkLocation}
                                    </td>
                                </tr>
                                <tr>
                                    <td className="label">
                                        Number of positions
                                    </td>
                                    <td colSpan={3} className="value">
                                        {this.state.FormData.ReqNumPositions}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                }
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeRequirement === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeRequirement}>Change Requirement</span>}
                </div>
            </React.Fragment>
        );
    }

    private renderCommercial_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>

                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Commercial</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.CommercialViewHelpText && this.props.defForm?.CommercialViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.CommercialViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Framework
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.ComFramework}
                                </td>
                                <td className="label">
                                    Confirm if you have a Fieldglass account
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.ComPSRAccount}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Justification if not PSR
                                </td>
                                <td colSpan={3} className="value">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.FormData.ComJustification && this.state.FormData.ComJustification.split('\n').join('<br/>') }} ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeCommercial === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeCommercial}>Change Commercial</span>}
                </div>
            </React.Fragment>
        );
    }

    private renderResourcingJustification_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }
        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Resourcing Justification</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.ResourcingJustificationViewHelpText && this.props.defForm?.ResourcingJustificationViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.ResourcingJustificationViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Alternative resourcing options: set out what other options have been considered and why these are not suitable
                                </td>
                                <td className="value" colSpan={3}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.FormData.JustAltOptions && this.state.FormData.JustAltOptions.split('\n').join('<br/>') }} ></div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Succession planning: explain how you plan to manage knowledge transfer and reduce reliance on contingent labour
                                </td>
                                <td className="value" colSpan={3}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.FormData.JustSuccessionPlanning && this.state.FormData.JustSuccessionPlanning.split('\n').join('<br/>') }} ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeResourcingJustification === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeResourcingJustification}>Change Resourcing Justification</span>}
                </div>
            </React.Fragment>
        );
    }

    private renderFinance_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }
        const caseCreated = this.checkCaseCreated();

        return (
            <React.Fragment>

                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Finance</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.FinanceViewHelpText && this.props.defForm?.FinanceViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.FinanceViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Billable day rate (pay rate plus fees)
                                </td>
                                <td className="value">
                                    {this.state.FormData.FinBillableRate}
                                </td>
                                <td className="label">
                                    <span>Rate for approval (billable day rate plus non-recoverable VAT)</span>
                                    {this.state.FormData.FinMaxRate >= 750 && <span style={{ color: 'rgb(254,138,53)', fontStyle: 'italic', paddingLeft: '15px' }}>Additional approval required</span>}
                                </td>
                                <td className="value" style={{ backgroundColor: this.state.FormData.FinMaxRate >= 750 ? 'rgb(255,242,230)' : 'white' }}>
                                    {this.state.FormData.FinMaxRate}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Total days
                                </td>
                                <td className="value">
                                    {this.state.FormData.FinTotalDays}
                                </td>
                                <td className="label">
                                    Total assignment cost per worker calculation
                                </td>
                                <td className="value">
                                    {this.state.FormData.FinCalcType}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Total assignment cost
                                </td>
                                <td className="value">
                                    {this.state.FormData.FinEstCost}
                                </td>
                                <td className="label">
                                    Total assignment cost per worker
                                </td>
                                <td className="value">
                                    {this.state.FormData.FinCostPerWorker}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Approach to agreeing rate
                                </td>
                                <td className="value" colSpan={3}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.FormData.FinApproachAgreeingRate && this.state.FormData.FinApproachAgreeingRate.split('\n').join('<br/>') }} ></div>
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Confirm whether in-scope of IR35
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.FinIR35Scope}
                                </td>
                                {(caseCreated === true) && <td className="label">
                                    Attach IR35 evidence
                                </td>}
                                {(caseCreated === true) && <td className="value">
                                    {this.state.IR35Evidence &&
                                        <div>
                                            <div>
                                                <span>{this.state.IR35Evidence.AttachmentType === "Link" ? "Linked evidence available" : "PDF evidence available to download"}&nbsp;<span style={{ marginLeft: '5px', cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.viewIR35Evidence} >View</span></span>
                                            </div>
                                            <div style={{ fontSize: '12px', fontStyle: 'italic', paddingTop: '5px' }}>
                                                {this.state.IR35Evidence.Details}
                                            </div>
                                        </div>
                                    }
                                </td>}
                            </tr>
                            <tr>
                                <td className="label">
                                    Summary IR35 justification
                                </td>
                                <td className="value" colSpan={3}>
                                    <div dangerouslySetInnerHTML={{ __html: this.state.FormData.FinSummaryIR35Just && this.state.FormData.FinSummaryIR35Just.split('\n').join('<br/>') }} ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeFinance === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeFinance}>Change Finance</span>}
                </div>
            </React.Fragment>
        );
    }

    private renderOther_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Other</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.OtherViewHelpText && this.props.defForm?.OtherViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.OtherViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Any additional comments
                                </td>
                                <td className="value">
                                    <div dangerouslySetInnerHTML={{ __html: this.state.FormData.OtherComments && this.state.FormData.OtherComments.split('\n').join('<br/>') }} ></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeOther === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeOther}>Change Other</span>}
                </div>
            </React.Fragment>
        );
    }

    private renderApprovers_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Approvers</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.ApproversViewHelpText && this.props.defForm?.ApproversViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.ApproversViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Budget holder
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.BHUser}
                                </td>
                                <td className="label">
                                    Finance business partner
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.FBPUser}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    HR business partner
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.HRBPUser}
                                </td>
                                <td className="label">
                                    {(this.state.FormData.ComFrameworkId === 1) === false && <span>Commercial business partner</span>}
                                </td>
                                <td className="value">
                                    {(this.state.FormData.ComFrameworkId === 1) === false && <span>{this.state.CaseInfo.CBPUser}</span>}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeApprovers === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeApprovers}>Change Approvers</span>}
                </div>
            </React.Fragment>
        );
    }

    private renderBudgetHolderApprovalDecision_info() {

        //hide this section if user is BH
        if (this.props.superUserPermission === false && this.props.currentUserId === this.state.FormData.BHUserId) {
            //no action
            return null;
        }

        let decision: string = "";
        if (this.state.FormData.BHApprovalDecision !== null) {
            const x1 = this.approvalDecisionItems.filter(x => x.key === this.state.FormData.BHApprovalDecision);
            if (x1.length > 0) {
                decision = x1[0].afterDecisionText;
            }
        }

        if (decision === "") {
            decision = "Decision not made yet";
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Budget Holder Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.BHApprovalDecisionViewHelpText && this.props.defForm?.BHApprovalDecisionViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.BHApprovalDecisionViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Decision
                                </td>
                                <td className="value">
                                    {decision}
                                </td>
                                <td className="label">
                                    By/Date
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.BHDecisionByAndDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </React.Fragment>
        );
    }

    private renderFinanceBusinessPartnerApprovalDecision_info() {

        //hide this section if user is FBP
        if (this.props.superUserPermission === false && this.props.currentUserId === this.state.FormData.FBPUserId) {
            //no action
            return null;
        }

        let decision: string = "";
        if (this.state.FormData.FBPApprovalDecision !== null) {
            const x1 = this.approvalDecisionItems.filter(x => x.key === this.state.FormData.FBPApprovalDecision);
            if (x1.length > 0) {
                decision = x1[0].afterDecisionText;
            }
        }

        if (decision === "") {
            decision = "Decision not made yet";
        }
        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Finance Business Partner Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.FBPApprovalDecisionViewHelpText && this.props.defForm?.FBPApprovalDecisionViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.FBPApprovalDecisionViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Decision
                                </td>
                                <td className="value">
                                    {decision}
                                </td>
                                <td className="label">
                                    By/Date
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.FBPDecisionByAndDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    private renderHRBusinessPartnerApprovalDecision_info() {

        //hide this section if user is HRBP
        if (this.props.superUserPermission === false && this.props.currentUserId === this.state.FormData.HRBPUserId) {
            //no action
            return null;
        }

        let decision: string = "";
        if (this.state.FormData.HRBPApprovalDecision !== null) {
            const x1 = this.approvalDecisionItems.filter(x => x.key === this.state.FormData.HRBPApprovalDecision);
            if (x1.length > 0) {
                decision = x1[0].afterDecisionText;
            }
        }

        if (decision === "") {
            decision = "Decision not made yet";
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>HR Business Partner Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.HRBPApprovalDecisionViewHelpText && this.props.defForm?.HRBPApprovalDecisionViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.HRBPApprovalDecisionViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Decision
                                </td>
                                <td className="value">
                                    {decision}
                                </td>
                                <td className="label">
                                    By/Date
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.HRBPDecisionByAndDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    private renderCommercialPartnerApprovalDecision_info() {

        if (this.state.FormData.ComFrameworkId === 1)
            return null;

        //hide this section if user is HRBP
        if (this.props.superUserPermission === false && this.props.currentUserId === this.state.FormData.CBPUserId) {
            //no action
            return null;
        }

        let decision: string = "";
        if (this.state.FormData.CBPApprovalDecision !== null) {
            const x1 = this.approvalDecisionItems.filter(x => x.key === this.state.FormData.CBPApprovalDecision);
            if (x1.length > 0) {
                decision = x1[0].afterDecisionText;
            }
        }

        if (decision === "") {
            decision = "Decision not made yet";
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Commercial Business Partner Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.CBPApprovalDecisionViewHelpText && this.props.defForm?.HRBPApprovalDecisionViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.CBPApprovalDecisionViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Decision
                                </td>
                                <td className="value">
                                    {decision}
                                </td>
                                <td className="label">
                                    By/Date
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.CBPDecisionByAndDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    private renderCLApprovalDecision_info() {

        let decision: string = "";
        if (this.state.FormData.CLApprovalDecision !== null) {
            const x1 = this.approvalDecisionItems.filter(x => x.key === this.state.FormData.CLApprovalDecision);
            if (x1.length > 0) {
                decision = x1[0].afterDecisionText;
            }
        }
        if (decision === "") {
            decision = "Decision not made yet";
        }

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Internal Controls Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.ICApprovalDecisionViewHelpText && this.props.defForm?.ICApprovalDecisionViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.ICApprovalDecisionViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Decision
                                </td>
                                <td className="value">
                                    {decision}
                                </td>
                                <td className="label">
                                    By/Date
                                </td>
                                <td className="value">
                                    {this.state.CaseInfo.CLDecisionByAndDate}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </React.Fragment>
        );
    }

    private renderBudgetHolderApprovalDecision() {

        //show this section if user is BH/super user
        if (this.props.superUserPermission === true || this.props.currentUserId === this.state.FormData.BHUserId) {
            //no action
        }
        else {
            return null;
        }

        const fd = this.state.FormData;

        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Budget Holder Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.BHApprovalDecisionEditHelpText && this.props.defForm?.BHApprovalDecisionEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.BHApprovalDecisionEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>

                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <span>Case Decision</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ minWidth: '50%', }}>
                                <CrChoiceGroup
                                    styles={{ flexContainer: { display: this.props.isMobile ? 'block' : 'flex' } }}
                                    style={{ marginBottom: '25px' }}
                                    options={this.approvalDecisionItems}
                                    selectedKey={fd.BHApprovalDecision}
                                    onChange={(ev, option) => this.changeChoiceGroup(ev, option, "BHApprovalDecision")}
                                />
                            </div>
                            {fd.BHApprovalDecision === 'RequireDetails' && <div style={{ width: 'auto' }}>

                                <div style={{ textAlign: 'right', color: 'navy', fontSize: '14px', fontStyle: 'italic', paddingTop: '0px', marginTop: '0px', paddingLeft: '0px', paddingRight: '10px' }}>
                                    Note: Please use the discussion box at the bottom of the page to specify what further information you require.
                                </div>

                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderFinanceBusinessPartnerApprovalDecision() {

        //show this section if user is FBP/super user
        if (this.props.superUserPermission === true || this.props.currentUserId === this.state.FormData.FBPUserId) {
            //no action
        }
        else {
            return null;
        }

        const fd = this.state.FormData;
        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Finance Business Partner Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.FBPApprovalDecisionEditHelpText && this.props.defForm?.FBPApprovalDecisionEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.FBPApprovalDecisionEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <span>Case Decision</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ minWidth: '50%', }}>
                                <CrChoiceGroup
                                    styles={{ flexContainer: { display: this.props.isMobile ? 'block' : 'flex' } }}
                                    style={{ marginBottom: '25px' }}
                                    options={this.approvalDecisionItems}
                                    selectedKey={fd.FBPApprovalDecision}
                                    onChange={(ev, option) => this.changeChoiceGroup(ev, option, "FBPApprovalDecision")}
                                />
                            </div>
                            {fd.FBPApprovalDecision === 'RequireDetails' && <div style={{ width: 'auto' }}>

                                <div style={{ textAlign: 'right', color: 'navy', fontSize: '14px', fontStyle: 'italic', paddingTop: '0px', marginTop: '0px', paddingLeft: '0px', paddingRight: '10px' }}>
                                    Note: Please use the discussion box at the bottom of the page to specify what further information you require.
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderHRBusinessPartnerApprovalDecision() {

        //show this section if user is HRBP/super user
        if (this.props.superUserPermission === true || this.props.currentUserId === this.state.FormData.HRBPUserId) {
            //no action
        }
        else {
            return null;
        }

        const fd = this.state.FormData;
        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>HR Business Partner Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.HRBPApprovalDecisionEditHelpText && this.props.defForm?.HRBPApprovalDecisionEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.HRBPApprovalDecisionEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>

                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>

                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <span>Case Decision</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ minWidth: '50%', }}>
                                <CrChoiceGroup
                                    styles={{ flexContainer: { display: this.props.isMobile ? 'block' : 'flex' } }}
                                    style={{ marginBottom: '25px' }}
                                    options={this.approvalDecisionItems}
                                    selectedKey={fd.HRBPApprovalDecision}
                                    onChange={(ev, option) => this.changeChoiceGroup(ev, option, "HRBPApprovalDecision")}
                                />
                            </div>
                            {fd.HRBPApprovalDecision === 'RequireDetails' && <div style={{ width: 'auto' }}>

                                <div style={{ textAlign: 'right', color: 'navy', fontSize: '14px', fontStyle: 'italic', paddingTop: '0px', marginTop: '0px', paddingLeft: '0px', paddingRight: '10px' }}>
                                    Note: Please use the discussion box at the bottom of the page to specify what further information you require.
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderCommercialBusinessPartnerApprovalDecision() {

        if (this.state.FormData.ComFrameworkId === 1)
            return null;

        //show this section if user is HRBP/super user
        if (this.props.superUserPermission === true || this.props.currentUserId === this.state.FormData.CBPUserId) {
            //no action
        }
        else {
            return null;
        }

        const fd = this.state.FormData;
        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Commercial Business Partner Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.CBPApprovalDecisionEditHelpText && this.props.defForm?.HRBPApprovalDecisionEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.CBPApprovalDecisionEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <span>Case Decision</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ minWidth: '50%', }}>
                                <CrChoiceGroup
                                    styles={{ flexContainer: { display: this.props.isMobile ? 'block' : 'flex' } }}
                                    style={{ marginBottom: '25px' }}
                                    options={this.approvalDecisionItems}
                                    selectedKey={fd.CBPApprovalDecision}
                                    onChange={(ev, option) => this.changeChoiceGroup(ev, option, "CBPApprovalDecision")}
                                />
                            </div>
                            {fd.CBPApprovalDecision === 'RequireDetails' && <div style={{ width: 'auto' }}>
                                <div style={{ textAlign: 'right', color: 'navy', fontSize: '14px', fontStyle: 'italic', paddingTop: '0px', marginTop: '0px', paddingLeft: '0px', paddingRight: '10px' }}>
                                    Note: Please use the discussion box at the bottom of the page to specify what further information you require.
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderCLApprovalDecision() {

        //show this section if user is super user
        if (this.props.superUserPermission === true) {
            //no action
        }
        else {
            return null;
        }

        const fd = this.state.FormData;
        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Internal Controls Approval Decision</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.ICApprovalDecisionEditHelpText && this.props.defForm?.ICApprovalDecisionEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.ICApprovalDecisionEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <span>Case Decision</span>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ minWidth: '50%', }}>
                                <CrChoiceGroup
                                    styles={{ flexContainer: { display: this.props.isMobile ? 'block' : 'flex' } }}
                                    style={{ marginBottom: '25px' }}
                                    options={this.approvalDecisionItems}
                                    selectedKey={fd.CLApprovalDecision}
                                    onChange={(ev, option) => this.changeChoiceGroup(ev, option, "CLApprovalDecision")}
                                />
                            </div>
                            {fd.CLApprovalDecision === 'RequireDetails' && <div style={{ width: 'auto' }}>

                                <div style={{ textAlign: 'right', color: 'navy', fontSize: '14px', fontStyle: 'italic', paddingTop: '0px', marginTop: '0px', paddingLeft: '0px', paddingRight: '10px' }}>
                                    Note: Please use the discussion box at the bottom of the page to specify what further information you require.
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderFormButtons_ApprovalStage() {

        const isSuperUserOrApproverPermission: boolean = this.isSuperUserOrApprover();

        //show this section if user is HRBP/super user
        if (isSuperUserOrApproverPermission === true) {
            //no action
        }
        else {
            return null;
        }

        return (
            <div>
                {
                    <React.Fragment>
                        {<PrimaryButton text="Submit Decision" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData(false, true)}
                        />}

                        <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.props.onShowList()}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }

    private renderOnboarding() {
        if (this.props.defForm === null) return;

        if (this.props.stage === "Onboarding" || (this.state.ShowAllowChangeOnboarding === false)) {
            //no action
        }
        else return null;

        const isMobile = this.props.isMobile;
        const fd = this.state.FormDataWorker;
        const req_OnbContractorGender_Img = fd.OnbContractorGenderId !== null ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorTitleId_Img = fd.OnbContractorTitleId !== null ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorFirstname_Img = fd.OnbContractorFirstname !== null && fd.OnbContractorFirstname.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorSurname_Img = fd.OnbContractorSurname !== null && fd.OnbContractorSurname.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorPhone_Img = fd.OnbContractorPhone !== null && fd.OnbContractorPhone.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorEmail_Img = fd.OnbContractorEmail !== null && fd.OnbContractorEmail.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorHomeAddress_Img = fd.OnbContractorHomeAddress !== null && fd.OnbContractorHomeAddress.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbContractorPostCode_Img = fd.OnbContractorPostCode !== null && fd.OnbContractorPostCode.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbStartDate_Img = fd.OnbStartDate !== null ? this.checkIconGreen : this.checkIconRed;
        const req_OnbEndDate_Img = fd.OnbEndDate !== null && fd.OnbEndDate > fd.OnbStartDate ? this.checkIconGreen : this.checkIconRed;
        const req_OnbDayRate_Img = fd.OnbDayRate !== null && fd.OnbDayRate > 0 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbSecurityClearanceId_Img = fd.OnbSecurityClearanceId !== null ? this.checkIconGreen : this.checkIconRed;
        const req_ContractorSecurityCheckEvidence_Img = this.state.ContractorSecurityCheckEvidence !== null ? this.checkIconGreen : this.checkIconRed;

        //workdays
        let totalWorkingDays: number = 0;
        if (fd.OnbWorkingDayMon === true) totalWorkingDays++;
        if (fd.OnbWorkingDayTue === true) totalWorkingDays++;
        if (fd.OnbWorkingDayWed === true) totalWorkingDays++;
        if (fd.OnbWorkingDayThu === true) totalWorkingDays++;
        if (fd.OnbWorkingDayFri === true) totalWorkingDays++;
        if (fd.OnbWorkingDaySat === true) totalWorkingDays++;
        if (fd.OnbWorkingDaySun === true) totalWorkingDays++;

        const req_workDays_Img = totalWorkingDays > 0 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbDecConflictId_Img = fd.OnbDecConflictId !== null ? this.checkIconGreen : this.checkIconRed;
        const req_OnbLineManagerUserId_Img = fd.OnbLineManagerUserId !== null ? this.checkIconGreen : this.checkIconRed;
        const req_OnbWorkOrderNumber_Img = fd.OnbWorkOrderNumber !== null && fd.OnbWorkOrderNumber.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_OnbRecruitersEmail_Img = fd.OnbRecruitersEmail !== null && fd.OnbRecruitersEmail.length > 1 ? this.checkIconGreen : this.checkIconRed;

        //1st row labels
        const contractorGenderLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor gender</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorGender_Img} />
                </div>
            </div>
        </div>;

        const contractorTitleLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor title</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorTitleId_Img} />
                </div>
            </div>
        </div>;

        //2nd row labels
        const contractorFirstNameLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor first name</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorFirstname_Img} />
                </div>
            </div>
        </div>;

        const contractorSurnameLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor surname</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorSurname_Img} />
                </div>
            </div>
        </div>;

        //3rd row labels
        const contractorDobLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor date of birth</span></div>
            </div>
        </div>;

        const contractorNINumberLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor NI Number</span></div>
            </div>
        </div>;

        //4th row labels
        const contractorPhoneLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor telephone (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorPhone_Img} />
                </div>
            </div>
        </div>;

        const contractorEmailLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor email (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorEmail_Img} />
                </div>
            </div>
        </div>;

        //5th row labels
        const contractorHomeAddressLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor home address (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorHomeAddress_Img} />
                </div>
            </div>
        </div>;

        const contractorPostCodeLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor post code (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbContractorPostCode_Img} />
                </div>
            </div>
        </div>;

        //Vacancy Details labels
        // 1st row labels
        const startDateLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Start date</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbStartDate_Img} />
                </div>
            </div>
        </div>;

        const endDateLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>End date</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbEndDate_Img} />
                </div>
            </div>
        </div>;

        // 2nd row labels
        const dailyRateLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Daily rate (including fee) agreed</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbDayRate_Img} />
                </div>
            </div>
        </div>;

        const purchaseOrderNumberLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Purchase order number</span></div>
                <div className={styles.sectionQuestionCol2}>
                </div>
            </div>
        </div>;

        // 3rd row labels
        const securityClearanceLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Security clearance</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbSecurityClearanceId_Img} />
                </div>
            </div>
        </div>;

        const securityChecksConfirmationEvidenceLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Security checks confirmation evidence</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_ContractorSecurityCheckEvidence_Img} />
                </div>
            </div>
        </div>;

        //Line Manager labels
        //first row labels
        const nameOfLineManagerLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Name of Line Manager</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbLineManagerUserId_Img} />
                </div>
            </div>
        </div>;

        const lineManagerGradeLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Line Manager grade</span></div>
            </div>
        </div>;

        // row row labels
        const lineManagerEmployeeNumberLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Line Manager Employee Number</span></div>
            </div>
        </div>;

        const lineManagerTelephoneNumberLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Line Manager telephone number</span></div>
            </div>
        </div>;

        // Recruitment 1st row Labels
        const workOrderNumberLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Work Order Number</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbWorkOrderNumber_Img} />
                </div>
            </div>
        </div>;

        const recruitersEmailLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Recruiters email</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_OnbRecruitersEmail_Img} />
                </div>
            </div>
        </div>;



        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Onboarding</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.OnboardingEditHelpText && this.props.defForm?.OnboardingEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.OnboardingEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField} dangerouslySetInnerHTML={{ __html: this.props.defForm?.OnboardingStageFormText && this.props.defForm?.OnboardingStageFormText }}></div>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline', paddingBottom: '25px' }}>
                        Contractor Details
                    </div>
                    {/* 1st row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorGenderLabel}
                            {contractorTitleLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorGenderLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.clGenders.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={fd.OnbContractorGenderId}
                                    onChange={(_, v) => this.changeDropdown_Worker(v, "OnbContractorGenderId")}
                                />
                            </div>
                            {isMobile && contractorTitleLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.personTitles.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={fd.OnbContractorTitleId}
                                    onChange={(_, v) => this.changeDropdown_Worker(v, "OnbContractorTitleId")}
                                />
                            </div>
                        </div>
                    </div>


                    {/* 2nd row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorFirstNameLabel}
                            {contractorSurnameLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorFirstNameLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorFirstname")}
                                    value={fd.OnbContractorFirstname}
                                />
                            </div>
                            {isMobile && contractorSurnameLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorSurname")}
                                    value={fd.OnbContractorSurname}
                                />
                            </div>
                        </div>
                    </div>


                    {/* 3rd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorDobLabel}
                            {contractorNINumberLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorDobLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.OnbContractorDob)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "OnbContractorDob")}
                                />
                            </div>
                            {isMobile && contractorNINumberLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorNINum")}
                                    value={fd.OnbContractorNINum}
                                />
                            </div>
                        </div>
                        {/* <div className='two-fields-sidebyside-info'>
                            Enter manually as dd/mm/yyyy or use date picker
                        </div> */}
                    </div>

                    {/* 4th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorPhoneLabel}
                            {contractorEmailLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorPhoneLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorPhone")}
                                    value={fd.OnbContractorPhone}
                                />
                            </div>
                            {isMobile && contractorEmailLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorEmail")}
                                    value={fd.OnbContractorEmail}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 5th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorHomeAddressLabel}
                            {contractorPostCodeLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorHomeAddressLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorHomeAddress")}
                                    value={fd.OnbContractorHomeAddress}
                                />
                            </div>
                            {isMobile && contractorPostCodeLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbContractorPostCode")}
                                    value={fd.OnbContractorPostCode}
                                />
                            </div>
                        </div>
                    </div>


                    <div style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline', paddingBottom: '25px' }}>
                        Vacancy Details
                    </div>

                    {/* 1st row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {startDateLabel}
                            {endDateLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && startDateLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.OnbStartDate)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "OnbStartDate")}
                                />
                            </div>
                            {isMobile && endDateLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.OnbEndDate)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "OnbEndDate")}
                                />
                            </div>
                        </div>
                        {
                            (fd.OnbStartDate !== null && fd.OnbEndDate !== null && (fd.OnbEndDate <= fd.OnbStartDate)) &&
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '50%' }}>&nbsp;</div>
                                <div style={{ width: '50%', fontSize: '12px', fontStyle: 'italic', paddingTop: '5px', marginTop: '0px', paddingLeft: '0px' }}>
                                    End date should be greater than start date
                                </div>
                            </div>
                        }
                    </div>

                    {/* 2rd row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {dailyRateLabel}
                            {purchaseOrderNumberLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && dailyRateLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onBlur={(ev) => this.blurRateTextField_Worker(ev, "OnbDayRate")}
                                    onChange={(_, v) => this.changeTextField_number_Worker(v, "OnbDayRate")}
                                    value={fd.OnbDayRate && String(fd.OnbDayRate)}
                                />
                            </div>
                            {isMobile && purchaseOrderNumberLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "PurchaseOrderNum")}
                                    value={fd.PurchaseOrderNum}
                                />
                            </div>
                        </div>
                    </div>



                    {/* 3rd row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {securityClearanceLabel}
                            {securityChecksConfirmationEvidenceLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && securityClearanceLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.clSecurityClearances.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={fd.OnbSecurityClearanceId}
                                    onChange={(_, v) => this.changeDropdown_Worker(v, "OnbSecurityClearanceId")}
                                />
                            </div>
                            {isMobile && securityChecksConfirmationEvidenceLabel}
                            <div className='two-fields-sidebyside-field' style={isMobile != true ? { width: 'calc(100% - 50% - 130px)' } : {}}>
                                <CrTextField
                                    disabled={true}
                                    style={{ border: '1px solid gray' }}
                                    value={this.state.ContractorSecurityCheckEvidence && (this.state.ContractorSecurityCheckEvidence.AttachmentType === "Link" ? "Linked evidence available" : this.state.ContractorSecurityCheckEvidence.AttachmentType === "PDF" ? "PDF evidence available to download" : "")}
                                />
                            </div>
                            <div style={{ width: '130px', marginTop: '5px' }}>
                                {
                                    <span>
                                        {this.state.ContractorSecurityCheckEvidence === null && <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.addContractorSecurityCheckEvidence} >Add</span>}
                                        {this.state.ContractorSecurityCheckEvidence !== null && <span style={{ marginRight: '5px', cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.viewContractorSecurityCheckEvidence} >View</span>}
                                        {this.state.ContractorSecurityCheckEvidence !== null && <span style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.toggleContractorSecurityCheckEvDeleteConfirm} >Delete</span>}
                                    </span>
                                }
                            </div>
                        </div>
                        {
                            this.state.ContractorSecurityCheckEvidence !== null &&

                            <div style={{ display: 'flex' }}>
                                {isMobile != true && <div style={{ width: '50%' }}>&nbsp;</div>}
                                <div style={{ width: isMobile ? '100%' : '50%', fontSize: '12px', fontStyle: 'italic', paddingTop: '5px', marginTop: '0px', paddingLeft: '0px' }}>
                                    {this.state.ContractorSecurityCheckEvidence.Details}
                                </div>
                            </div>
                        }
                    </div>


                    {/* 4th row */}

                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Work days</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={req_workDays_Img} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px', flexWrap: 'wrap', gap: '10px' }}>
                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Monday"
                                    checked={fd.OnbWorkingDayMon}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDayMon")}
                                />
                            </div>
                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Tuesday"
                                    checked={fd.OnbWorkingDayTue}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDayTue")}
                                />
                            </div>
                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Wednesday"
                                    checked={fd.OnbWorkingDayWed}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDayWed")}
                                />
                            </div>
                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Thursday"
                                    checked={fd.OnbWorkingDayThu}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDayThu")}
                                />
                            </div>

                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Friday"
                                    checked={fd.OnbWorkingDayFri}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDayFri")}
                                />
                            </div>

                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Saturday"
                                    checked={fd.OnbWorkingDaySat}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDaySat")}
                                />
                            </div>

                            <div style={{ paddingRight: '15px' }}>
                                <CrCheckbox
                                    label="Sunday"
                                    checked={fd.OnbWorkingDaySun}
                                    onChange={(ev, isChecked) => this.changeCheckbox_Worker(isChecked, "OnbWorkingDaySun")}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 5th row */}
                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>Declaration of conflict of interest</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={req_OnbDecConflictId_Img} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%', paddingRight: isMobile ? '0' : '5px' }}>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.clDeclarationConflicts.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={fd.OnbDecConflictId}
                                    onChange={(_, v) => this.changeDropdown_Worker(v, "OnbDecConflictId")}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline', paddingBottom: '25px' }}>
                        Line Manager
                    </div>

                    {/* 1st row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {nameOfLineManagerLabel}
                            {lineManagerGradeLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && nameOfLineManagerLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker
                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.OnbLineManagerUserId && [fd.OnbLineManagerUserId]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'OnbLineManagerUserId')}
                                />
                            </div>
                            {isMobile && lineManagerGradeLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDropdown
                                    placeholder="Select an Option"
                                    options={this.props.clStaffGrades.map((p) => { return { key: p.ID, text: p.Title }; })}
                                    selectedKey={fd.OnbLineManagerGradeId}
                                    onChange={(_, v) => this.changeDropdown_Worker(v, "OnbLineManagerGradeId")}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {lineManagerEmployeeNumberLabel}
                            {lineManagerTelephoneNumberLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && lineManagerEmployeeNumberLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbLineManagerEmployeeNum")}
                                    value={fd.OnbLineManagerEmployeeNum}
                                    autoComplete='*'
                                />
                            </div>
                            {isMobile && lineManagerTelephoneNumberLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbLineManagerPhone")}
                                    value={fd.OnbLineManagerPhone}
                                />
                            </div>
                        </div>
                    </div>


                    <div style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline', paddingBottom: '25px' }}>
                        Recruitment
                    </div>

                    {/* 1st row */}

                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {workOrderNumberLabel}
                            {recruitersEmailLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && workOrderNumberLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbWorkOrderNumber")}
                                    value={fd.OnbWorkOrderNumber}
                                    autoComplete='*'
                                />
                            </div>
                            {isMobile && <div style={{ fontSize: '14px', fontStyle: 'italic', paddingTop: '10px', marginTop: '0px', paddingLeft: '10px', marginBottom: '15px' }}>
                                - If your contractor is from PSR, the work order number will begin with “PSR1WO” followed by 8 digits. Please insert this into this field. Please do not use any other order number such as “PSR1JP. This will not be accepted.
                                <br /> <br />
                                -  If you are using RM6160 framework, please type on the initials of the worker e.g. for example, joe blogs, worker order number will be “JB”.
                            </div>}

                            {isMobile && recruitersEmailLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "OnbRecruitersEmail")}
                                    value={fd.OnbRecruitersEmail}
                                />
                            </div>
                            {isMobile && <div style={{ fontSize: '14px', fontStyle: 'italic', paddingTop: '10px', marginTop: '0px', paddingLeft: '10px', marginBottom: '15px' }}>
                                - Please inform us of your recruiters email address from the agency. We will need this email address to send the SDS letter to. The recruiter will be the person who deals with your candidate/CV short listing, interview set up, candidate set up, etc.
                                <br /> <br />
                                - If you are extending a current contractor on PSR, please type in “Extensions@publicsectorresourcing.co.uk”, else please refer to the step above for all other instances.
                            </div>}
                        </div>
                        {isMobile != true && <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', fontSize: '14px', fontStyle: 'italic', paddingTop: '10px', marginTop: '0px', paddingLeft: '10px' }}>
                                - If your contractor is from PSR, the work order number will begin with “PSR1WO” followed by 8 digits. Please insert this into this field. Please do not use any other order number such as “PSR1JP. This will not be accepted.
                                <br /> <br />
                                -  If you are using RM6160 framework, please type on the initials of the worker e.g. for example, joe blogs, worker order number will be “JB”.
                            </div>
                            <div style={{ width: '50%', fontSize: '14px', fontStyle: 'italic', paddingTop: '10px', marginTop: '0px', paddingLeft: '10px' }}>
                                - Please inform us of your recruiters email address from the agency. We will need this email address to send the SDS letter to. The recruiter will be the person who deals with your candidate/CV short listing, interview set up, candidate set up, etc.
                                <br /> <br />
                                - If you are extending a current contractor on PSR, please type in “Extensions@publicsectorresourcing.co.uk”, else please refer to the step above for all other instances.
                            </div>
                        </div>}
                    </div>


                    <div style={{ marginBottom: '10px' }}>
                        {this.state.ShowAllowChangeOnboarding === false &&
                            <div>
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => {
                                    this.toggleAllowChangeOnboarding();
                                    this.saveData_Worker(false, false, false, false, false, true);

                                }}>Save</span>&nbsp;&nbsp;
                                <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeOnboarding}>Cancel</span>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }

    private renderFormButtons_OnboardingStage() {

        return (
            <div>
                {
                    <React.Fragment>
                        {<PrimaryButton text="Save as Draft" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData_Worker(false, false, false, false, false)}
                        />}

                        <PrimaryButton text="Submit to Engaged" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData_Worker(true, false, false, false, false)}
                        />

                        <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.props.onShowList()}
                        />

                    </React.Fragment>
                }

            </div>
        );
    }

    private renderOnboarding_info() {

        let allowChange: boolean = false;
        if (this.props.superUserPermission === true) {
            allowChange = true;
        }

        const fd = this.state.FormDataWorker;
        const caseInfo = this.state.CaseInfo;

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Onboarding</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.OnboardingViewHelpText && this.props.defForm?.OnboardingViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.OnboardingViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Contractor gender
                                </td>
                                <td className="value">
                                    {caseInfo.OnbContractorGender}
                                </td>
                                <td className="label">
                                    Contractor title
                                </td>
                                <td className="value">
                                    {caseInfo.OnbContractorTitle}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Contractor first name
                                </td>
                                <td className="value">
                                    {fd.OnbContractorFirstname}
                                </td>
                                <td className="label">
                                    Contractor surname
                                </td>
                                <td className="value">
                                    {fd.OnbContractorSurname}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Contractor telephone (personal)
                                </td>
                                <td className="value">
                                    {fd.OnbContractorPhone}
                                </td>
                                <td className="label">
                                    Contractor email (personal)
                                </td>
                                <td className="value">
                                    {fd.OnbContractorEmail}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Contractor home address (personal)
                                </td>
                                <td className="value">
                                    {fd.OnbContractorHomeAddress}
                                </td>
                                <td className="label">
                                    Contractor post code (personal)
                                </td>
                                <td className="value">
                                    {fd.OnbContractorPostCode}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Start date
                                </td>
                                <td className="value">
                                    {caseInfo.OnbStartDateStr}
                                </td>
                                <td className="label">
                                    End date
                                </td>
                                <td className="value">
                                    {caseInfo.OnbEndDateStr}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Daily rate (including fee) agreed
                                </td>
                                <td className="value">
                                    {fd.OnbDayRate}
                                </td>
                                <td className="label">
                                    Purchase order number
                                </td>
                                <td className="value">
                                    {fd.PurchaseOrderNum}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Security clearance
                                </td>
                                <td className="value">
                                    {caseInfo.OnbSecurityClearance}
                                </td>
                                <td className="label">
                                    Security checks confirmation evidence
                                </td>
                                <td className="value">
                                    {this.state.ContractorSecurityCheckEvidence &&
                                        <div>
                                            <div>
                                                <span>{this.state.ContractorSecurityCheckEvidence.AttachmentType === "Link" ? "Linked evidence available" : "PDF evidence available to download"}&nbsp;<span style={{ marginLeft: '5px', cursor: 'pointer', color: 'blue', textDecoration: 'underline' }} onClick={this.viewContractorSecurityCheckEvidence}>View</span></span>
                                            </div>
                                            <div style={{ fontSize: '12px', fontStyle: 'italic', paddingTop: '5px' }}>
                                                {this.state.ContractorSecurityCheckEvidence.Details}
                                            </div>
                                        </div>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Work days
                                </td>
                                <td colSpan={3} className="value">
                                    {caseInfo.WorkDays}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Declaration of conflict of interest
                                </td>
                                <td colSpan={3} className="value">
                                    {caseInfo.OnbDecConflict}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Name of Line Manager
                                </td>
                                <td className="value">
                                    {caseInfo.OnbLineManagerUser}
                                </td>
                                <td className="label">
                                    Line Manager grade
                                </td>
                                <td className="value">
                                    {caseInfo.OnbLineManagerGrade}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Line Manager Employee Number
                                </td>
                                <td className="value">
                                    {fd.OnbLineManagerEmployeeNum}
                                </td>
                                <td className="label">
                                    Line Manager telephone number
                                </td>
                                <td className="value">
                                    {fd.OnbLineManagerPhone}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Work Order Number
                                </td>
                                <td className="value">
                                    {caseInfo.OnbWorkOrderNumber}
                                </td>
                                <td className="label">
                                    Recruiters email
                                </td>
                                <td className="value">
                                    {caseInfo.OnbRecruitersEmail}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ paddingTop: '5px' }}>
                    {this.isViewOnlyPermission() === false && this.state.ShowAllowChangeOnboarding === true && allowChange === true && <span style={{ cursor: 'pointer', color: 'blue' }} onClick={this.toggleAllowChangeOnboarding}>Change Onboarding</span>}
                </div>
                <div style={{ marginTop: '5px' }}>
                    {fd.SDSPdfStatus !== "Working... Please Wait" && <div style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.createSDSPdf()}>Create SDS PDF</div>}
                    {fd.SDSPdfStatus === "Working... Please Wait" && <div>Creating SDS... Please Wait.. To refresh status <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.refreshSDSPdfStatus()}>Click Here</span></div>}
                    {fd.SDSPdfStatus === "Cr" && <div>Last PDF created by {fd.SDSPdfLastActionUser} on {services.DateService.dateToUkDateTime(fd.SDSPdfDate)} <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => this.downloadSDSPdf()}>Download</span>  </div>}
                    {fd.SDSPdfStatus && fd.SDSPdfStatus.search("Err:") === 0 && <div>Last PDF creation error. Attempted by {fd.SDSPdfLastActionUser} on {services.DateService.dateToUkDateTime(fd.SDSPdfDate)} <br />{fd.SDSPdfStatus}  </div>}
                </div>
            </React.Fragment>
        );
    }

    private renderEngaged() {

        if (this.props.defForm === null) return;

        const fd = this.state.FormDataWorker;
        if (fd.EngagedChecksDone === true) return;

        const isMobile = this.props.isMobile;

        const req_BPSSCheckedById_Img = fd.BPSSCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_BPSSCheckedOn_Img = fd.BPSSCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_POCheckedById_Img = fd.POCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_POCheckedOn_Img = fd.POCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_ITCheckedById_Img = fd.ITCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_ITCheckedOn_Img = fd.ITCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_UKSBSCheckedById_Img = fd.UKSBSCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_UKSBSCheckedByOn_Img = fd.UKSBSCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_SDSCheckedById_Img = fd.SDSCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_SDSCheckedOn_Img = fd.SDSCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_SDSNotes_Img = fd.SDSNotes !== null && fd.SDSNotes.length > 5 ? this.checkIconGreen : this.checkIconRed;

        // 1st row labels
        const securityClearanceCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Security clearance checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_BPSSCheckedById_Img} />
                </div>
            </div>
        </div>;

        const securityClearanceCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Security clearance checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_BPSSCheckedOn_Img} />
                </div>
            </div>
        </div>;

        //2nd row labels
        const poCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>PO checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_POCheckedById_Img} />
                </div>
            </div>
        </div>;

        const poCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>PO checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_POCheckedOn_Img} />
                </div>
            </div>
        </div>;

        // 3rd row labels
        const poNumberLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>PO Number</span></div>
            </div>
        </div>;

        const poNoteLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>PO Note</span></div>
            </div>
        </div>;

        //4rh row labels
        const itCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>IT checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_ITCheckedById_Img} />
                </div>
            </div>
        </div>;

        const itCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>IT checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_ITCheckedOn_Img} />
                </div>
            </div>
        </div>;

        //5th row labels
        const itSystemReferenceLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>IT System Reference</span></div>
            </div>
        </div>;

        const itSystemNotesLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>IT System notes</span></div>
            </div>
        </div>;

        //6th row labels
        const uksbsOracleCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>UKSBS/Oracle checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_UKSBSCheckedById_Img} />
                </div>
            </div>
        </div>;

        const uksbsOracleCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>UKSBS/Oracle checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_UKSBSCheckedByOn_Img} />
                </div>
            </div>
        </div>;

        //7th row Labels
        const uksbsReferenceLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>UKSBS Reference</span></div>
            </div>
        </div>;

        const uksbsNotesLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>UKSBS notes</span></div>
            </div>
        </div>;

        //8th row labels
        const passCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Pass checked by</span></div>
            </div>
        </div>;

        const passCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Pass checked on</span></div>
            </div>
        </div>;

        //9th row labels
        const sdsCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>SDS checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_SDSCheckedById_Img} />
                </div>
            </div>
        </div>;

        const sdsCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>SDS checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_SDSCheckedOn_Img} />
                </div>
            </div>
        </div>;

        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Engaged</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.EngagedEditHelpText && this.props.defForm?.EngagedEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.EngagedEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField} dangerouslySetInnerHTML={{ __html: this.props.defForm?.EngagedStageFormText && this.props.defForm?.EngagedStageFormText }}></div>

                    {/* 1st row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {securityClearanceCheckedByLabel}
                            {securityClearanceCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && securityClearanceCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.BPSSCheckedById && [fd.BPSSCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'BPSSCheckedById', true)}
                                />
                            </div>
                            {isMobile && securityClearanceCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.BPSSCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "BPSSCheckedOn", this.engaged_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {poCheckedByLabel}
                            {poCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && poCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.POCheckedById && [fd.POCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'POCheckedById', true)}
                                />
                            </div>
                            {isMobile && poCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.POCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "POCheckedOn", this.engaged_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 3rd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {poNumberLabel}
                            {poNoteLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && poNumberLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "EngPONumber")}
                                    value={fd.EngPONumber}
                                    autoComplete='*'
                                />
                            </div>
                            {isMobile && poNoteLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "EngPONote")}
                                    value={fd.EngPONote}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 4th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {itCheckedByLabel}
                            {itCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && itCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.ITCheckedById && [fd.ITCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'ITCheckedById', true)}
                                />
                            </div>
                            {isMobile && itCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.ITCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "ITCheckedOn", this.engaged_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 5th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {itSystemReferenceLabel}
                            {itSystemNotesLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && itSystemReferenceLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "ITSystemRef")}
                                    value={fd.ITSystemRef}
                                />
                            </div>
                            {isMobile && itSystemNotesLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "ITSystemNotes")}
                                    value={fd.ITSystemNotes}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 6th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {uksbsOracleCheckedByLabel}
                            {uksbsOracleCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && uksbsOracleCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.UKSBSCheckedById && [fd.UKSBSCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'UKSBSCheckedById', true)}
                                />
                            </div>
                            {isMobile && uksbsOracleCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.UKSBSCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "UKSBSCheckedOn", this.engaged_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 7th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {uksbsReferenceLabel}
                            {uksbsNotesLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && uksbsReferenceLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "UKSBSRef")}
                                    value={fd.UKSBSRef}
                                />
                            </div>
                            {isMobile && uksbsNotesLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "UKSBSNotes")}
                                    value={fd.UKSBSNotes}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 8th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {passCheckedByLabel}
                            {passCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && passCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.PassCheckedById && [fd.PassCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'PassCheckedById', true)}
                                />
                            </div>
                            {isMobile && passCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.PassCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "PassCheckedOn", this.engaged_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 9th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {sdsCheckedByLabel}
                            {sdsCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && sdsCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.SDSCheckedById && [fd.SDSCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'SDSCheckedById', true)}
                                />
                            </div>
                            {isMobile && sdsCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.SDSCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "SDSCheckedOn", this.engaged_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 10th row */}
                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', fontWeight: 'bold' }}>
                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>SDS notes</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={req_SDSNotes_Img} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%', }}>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "SDSNotes", true)}
                                    value={fd.SDSNotes}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderFormButtons_EngagedStage() {

        if (this.state.FormDataWorker.EngagedChecksDone === true) return;

        return (
            <div>
                {
                    <React.Fragment>
                        {<PrimaryButton text="Save" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData_Worker(false, true, false, false, false)}
                        />}

                        <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.props.onShowList()}
                        />

                        {this.state.Engaged_MoveToChecksDoneBtn && <PrimaryButton text="Move to Checks Done" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData_Worker(false, true, true, false, false)}
                        />}


                    </React.Fragment>
                }

            </div>
        );
    }

    private renderEngaged_info() {

        const caseInfo = this.state.CaseInfo;
        const fd = this.state.FormDataWorker;

        return (
            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Engaged</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.EngagedViewHelpText && this.props.defForm?.EngagedViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.EngagedViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>


                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Security clearance checked by
                                </td>
                                <td className="value">
                                    {caseInfo.BPSSCheckedBy}
                                </td>
                                <td className="label">
                                    Security clearance checked on
                                </td>
                                <td className="value">
                                    {caseInfo.BPSSCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    PO checked by
                                </td>
                                <td className="value">
                                    {caseInfo.POCheckedBy}
                                </td>
                                <td className="label">
                                    PO checked on
                                </td>
                                <td className="value">
                                    {caseInfo.POCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    PO Number
                                </td>
                                <td className="value">
                                    {caseInfo.EngPONumber}
                                </td>
                                <td className="label">
                                    PO Note
                                </td>
                                <td className="value">
                                    {caseInfo.EngPONote}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    IT checked by
                                </td>
                                <td className="value">
                                    {caseInfo.ITCheckedBy}
                                </td>
                                <td className="label">
                                    IT checked on
                                </td>
                                <td className="value">
                                    {caseInfo.ITCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    IT System Reference
                                </td>
                                <td className="value">
                                    {fd.ITSystemRef}
                                </td>
                                <td className="label">
                                    IT System notes
                                </td>
                                <td className="value">
                                    {fd.ITSystemNotes}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    UKSBS/Oracle checked by
                                </td>
                                <td className="value">
                                    {caseInfo.UKSBSCheckedBy}
                                </td>
                                <td className="label">
                                    UKSBS/Oracle checked on
                                </td>
                                <td className="value">
                                    {caseInfo.UKSBSCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    UKSBS Reference
                                </td>
                                <td className="value">
                                    {fd.UKSBSRef}
                                </td>
                                <td className="label">
                                    UKSBS notes
                                </td>
                                <td className="value">
                                    {fd.UKSBSNotes}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Pass checked by
                                </td>
                                <td className="value">
                                    {caseInfo.PassCheckedBy}
                                </td>
                                <td className="label">
                                    Pass checked on
                                </td>
                                <td className="value">
                                    {caseInfo.PassCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    SDS checked by
                                </td>
                                <td className="value">
                                    {caseInfo.SDSCheckedBy}
                                </td>
                                <td className="label">
                                    SDS checked on
                                </td>
                                <td className="value">
                                    {caseInfo.SDSCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    SDS notes
                                </td>
                                <td colSpan={3} className="value">
                                    {caseInfo.SDSNotes}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>



            </React.Fragment>
        );
    }

    private renderLeaving() {

        if (this.props.defForm === null) return;

        const isMobile = this.props.isMobile;

        const fd = this.state.FormDataWorker;
        const req_LeEndDate_Img = fd.LeEndDate !== null ? this.checkIconGreen : this.checkIconRed;
        const req_LeContractorPhone_Img = fd.LeContractorPhone !== null && fd.LeContractorPhone.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_LeContractorEmail_Img = fd.LeContractorEmail !== null && fd.LeContractorEmail.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_LeContractorHomeAddress_Img = fd.LeContractorHomeAddress !== null && fd.LeContractorHomeAddress.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_LeContractorPostCode_Img = fd.LeContractorPostCode !== null && fd.LeContractorPostCode.length > 1 ? this.checkIconGreen : this.checkIconRed;
        const req_LeContractorDetailsCheckedById_Img = fd.LeContractorDetailsCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_LeContractorDetailsCheckedOn_Img = fd.LeContractorDetailsCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_LeITCheckedById_Img = fd.LeITCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_LeITCheckedOn_Img = fd.LeITCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;
        const req_LeUKSBSCheckedById_Img = fd.LeUKSBSCheckedById !== null ? this.checkIconGreen : this.checkIconRed;
        const req_LeUKSBSCheckedOn_Img = fd.LeUKSBSCheckedOn !== null ? this.checkIconGreen : this.checkIconRed;

        //Confirm Contractor Details Section
        //1st row Labels
        const contractorHomeAddressLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor home address (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeContractorHomeAddress_Img} />
                </div>
            </div>
        </div>;

        const contractorPostCodeLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor post code (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeContractorPostCode_Img} />
                </div>
            </div>
        </div>;

        //2nd row Labels
        const contractorPhoneLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor telephone (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeContractorPhone_Img} />
                </div>
            </div>
        </div>;

        const contractorEmailLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor email (personal)</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeContractorEmail_Img} />
                </div>
            </div>
        </div>;

        //Checks Section
        //1st rpw Labels
        const contractorDetailsCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor details above checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeContractorDetailsCheckedById_Img} />
                </div>
            </div>
        </div>;

        const contractorDetailsCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Contractor details above checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeContractorDetailsCheckedOn_Img} />
                </div>
            </div>
        </div>;

        //2nd row Labels
        const itCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>IT checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeITCheckedById_Img} />
                </div>
            </div>
        </div>;

        const itCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>IT checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeITCheckedOn_Img} />
                </div>
            </div>
        </div>;

        //3rd row Labels
        const uksbsCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>UKSBS/Oracle checked by</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeUKSBSCheckedById_Img} />
                </div>
            </div>
        </div>;

        const uksbsCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>UKSBS/Oracle checked on</span></div>
                <div className={styles.sectionQuestionCol2}>
                    <img src={req_LeUKSBSCheckedOn_Img} />
                </div>
            </div>
        </div>;

        //4th row Labels
        const passCheckedByLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Pass checked by</span></div>
            </div>
        </div>;

        const passCheckedOnLabel = <div className='two-fields-sidebyside-lbl'>
            <div className={styles.flexContainerSectionQuestion}>
                <div className={styles.sectionQuestionCol1}><span>Pass checked on</span></div>
            </div>
        </div>;

        return (
            <div>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Leaving</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.LeavingEditHelpText && this.props.defForm?.LeavingEditHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.LeavingEditHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>
                <div style={{ paddingRight: '10px', paddingLeft: '10px', paddingTop: '20px', paddingBottom: '0px', backgroundColor: 'rgb(245,245,245)', border: '1px solid rgb(230,230,230)', }}>
                    <div className={styles.formField} dangerouslySetInnerHTML={{ __html: this.props.defForm?.LeavingStageFormText && this.props.defForm?.LeavingStageFormText }}></div>
                    <div style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline', paddingBottom: '25px' }}>
                        Confirm Contractor Details
                    </div>

                    {/* 1st row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorHomeAddressLabel}
                            {contractorPostCodeLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorHomeAddressLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "LeContractorHomeAddress")}
                                    value={fd.LeContractorHomeAddress}
                                />
                            </div>
                            {isMobile && contractorPostCodeLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "LeContractorPostCode")}
                                    value={fd.LeContractorPostCode}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorPhoneLabel}
                            {contractorEmailLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorPhoneLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "LeContractorPhone")}
                                    value={fd.LeContractorPhone}
                                />
                            </div>
                            {isMobile && contractorEmailLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrTextField
                                    onChange={(_, v) => this.changeTextField_Worker(v, "LeContractorEmail")}
                                    value={fd.LeContractorEmail}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 3rd row */}
                    <div className={styles.formField}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ width: '50%', fontWeight: 'bold' }}>

                                <div className={styles.flexContainerSectionQuestion}>
                                    <div className={styles.sectionQuestionCol1}><span>End date</span></div>
                                    <div className={styles.sectionQuestionCol2}>
                                        <img src={req_LeEndDate_Img} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: '5px' }}>
                            <div style={{ width: '100%', }}>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.LeEndDate)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "LeEndDate")}
                                />
                            </div>
                        </div>
                    </div>

                    <div style={{ fontSize: '18px', fontWeight: 'bold', textDecoration: 'underline', paddingBottom: '25px' }}>
                        Checks
                    </div>
                    {/* 1st row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {contractorDetailsCheckedByLabel}
                            {contractorDetailsCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && contractorDetailsCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.LeContractorDetailsCheckedById && [fd.LeContractorDetailsCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'LeContractorDetailsCheckedById', false, true)}
                                />
                            </div>
                            {isMobile && contractorDetailsCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.LeContractorDetailsCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "LeContractorDetailsCheckedOn", this.leaving_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 2nd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {itCheckedByLabel}
                            {itCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && itCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.LeITCheckedById && [fd.LeITCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'LeITCheckedById', false, true)}
                                />
                            </div>
                            {isMobile && itCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.LeITCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "LeITCheckedOn", this.leaving_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 3rd row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {uksbsCheckedByLabel}
                            {uksbsCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && uksbsCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.LeUKSBSCheckedById && [fd.LeUKSBSCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'LeUKSBSCheckedById', false, true)}
                                />
                            </div>
                            {isMobile && uksbsCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.LeUKSBSCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "LeUKSBSCheckedOn", this.leaving_Checks)}
                                />
                            </div>
                        </div>
                    </div>

                    {/* 4th row */}
                    <div className={styles.formField}>
                        {isMobile !== true && <div className='two-fields-sidebyside-r1'>
                            {passCheckedByLabel}
                            {passCheckedOnLabel}
                        </div>}
                        <div className='two-fields-sidebyside-r2'>
                            {isMobile && passCheckedByLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrUserPicker                                    
                                    users={this.props.users}
                                    itemLimit={1}
                                    selectedUsers={fd.LePassCheckedById && [fd.LePassCheckedById]}
                                    onChange={(v) => this.changeUserPicker_Worker(v, 'LePassCheckedById', false, true)}
                                />
                            </div>
                            {isMobile && passCheckedOnLabel}
                            <div className='two-fields-sidebyside-field'>
                                <CrDatePicker
                                    maxWidth='100%'
                                    value={services.DateService.removeTimezoneOffset(fd.LePassCheckedOn)}
                                    onSelectDate={(v) => changeDatePickerV2(this, 'FormDataWorker', v, "LePassCheckedOn", this.leaving_Checks)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private renderFormButtons_LeavingStage() {

        return (
            <div>
                {
                    <React.Fragment>
                        {<PrimaryButton text="Save" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData_Worker(false, false, false, true, false)}
                        />}

                        <DefaultButton text="Close" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.props.onShowList()}
                        />

                        {this.state.Leaving_MoveToArchiveBtn && <PrimaryButton text="Move to Archive" className={styles.formButton} style={{ marginRight: '5px' }}
                            onClick={() => this.saveData_Worker(false, false, false, true, true)}
                        />}

                    </React.Fragment>
                }

            </div>
        );
    }

    private renderLeaving_info() {

        const caseInfo = this.state.CaseInfo;
        const fd = this.state.FormDataWorker;

        return (

            <React.Fragment>
                <div style={{ marginBottom: '10px', marginTop: '30px' }}>
                    <div style={{ display: 'flex' }}>
                        <div className={styles.sectionATitle}>Leaving</div>
                        <div style={{ paddingLeft: '10px', paddingTop: '6px' }} >{(this.props.defForm?.LeavingViewHelpText && this.props.defForm?.LeavingViewHelpText.length > 0) && <a style={{ cursor: "pointer" }} onClick={() => this.showHelpPanel(this.props.defForm?.LeavingViewHelpText)}><img src={this.helpIcon} /></a>}</div>
                    </div>
                </div>

                <div className="info-table-container">
                    <table className="info-table" cellSpacing="0" cellPadding="10">
                        <tbody>
                            <tr>
                                <td className="label">
                                    Contractor telephone (personal)
                                </td>
                                <td className="value">
                                    {fd.LeContractorPhone}
                                </td>
                                <td className="label">
                                    Contractor email (personal)
                                </td>
                                <td className="value">
                                    {fd.LeContractorEmail}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Contractor home address (personal)
                                </td>
                                <td className="value">
                                    {fd.LeContractorHomeAddress}
                                </td>
                                <td className="label">
                                    Contractor post code (personal)
                                </td>
                                <td className="value">
                                    {fd.LeContractorPostCode}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Contractor details above checked by
                                </td>
                                <td className="value">
                                    {caseInfo.LeContractorDetailsCheckedBy}
                                </td>
                                <td className="label">
                                    Contractor details above checked on
                                </td>
                                <td className="value">
                                    {caseInfo.LeContractorDetailsCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    IT checked by
                                </td>
                                <td className="value">
                                    {caseInfo.LeITCheckedBy}
                                </td>
                                <td className="label">
                                    IT checked on
                                </td>
                                <td className="value">
                                    {caseInfo.LeITCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    UKSBS/Oracle checked by
                                </td>
                                <td className="value">
                                    {caseInfo.LeUKSBSCheckedBy}
                                </td>
                                <td className="label">
                                    UKSBS/Oracle checked on
                                </td>
                                <td className="value">
                                    {caseInfo.LeUKSBSCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    Pass checked by
                                </td>
                                <td className="value">
                                    {caseInfo.LePassCheckedBy}
                                </td>
                                <td className="label">
                                    Pass checked on
                                </td>
                                <td className="value">
                                    {caseInfo.LePassCheckedOn}
                                </td>
                            </tr>
                            <tr>
                                <td className="label">
                                    End Date
                                </td>
                                <td colSpan={3} className="value">
                                    {caseInfo.LeEndDateStr}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


            </React.Fragment>
        );
    }

    private renderCloseButton_HistoricCase() {

        return (
            <div>
                {

                    <DefaultButton text="Back" className={styles.formButton} style={{ marginRight: '5px' }}
                        onClick={() => this.props.onShowList()}
                    />
                }

            </div>
        );

    }

    private validateEntity = (submitForApproval: boolean, submitDecision): boolean => {
        const fd = this.state.FormData;

        if (submitForApproval === true) {
            //details of application
            if (this.state.FormData.ApplHMUserId === null) return false;

            //requirement
            if (fd.ReqVacancyTitle !== null && fd.ReqVacancyTitle.length > 1) { } else { return false; }
            if (fd.ReqGradeId !== null) { } else { return false; }
            if (fd.ReqWorkPurpose !== null && fd.ReqWorkPurpose.length > 9) { } else { return false; }
            if (fd.ReqCostCentre !== null && fd.ReqCostCentre.length === 6) { } else { return false; }
            if (fd.ReqDirectorateId !== null) { } else { return false; }
            if (fd.ReqEstStartDate !== null) { } else { return false; }
            if (fd.ReqEstEndDate !== null && fd.ReqEstEndDate > fd.ReqEstStartDate) { } else { return false; }
            if (fd.ReqProfessionalCatId !== null) { } else { return false; }
            if (fd.ReqWorkLocationId !== null) { } else { return false; }
            if (fd.ReqNumPositions !== null && fd.ReqNumPositions > 0) { } else { return false; }

            //Commercial
            if (fd.ComFrameworkId !== null) { } else { return false; }
            if (fd.ComFrameworkId === 1 && fd.ComPSRAccountId === null) { return false; }

            if (fd.ComFrameworkId > 1) {
                if (fd.ComJustification !== null && fd.ComJustification.length > 9) {
                    //validation ok
                }
                else {
                    return false;
                }
            }

            //Resourcing Justification
            if (fd.JustAltOptions !== null && fd.JustAltOptions.length > 9) { } else { return false; }
            if (fd.JustSuccessionPlanning !== null && fd.JustSuccessionPlanning.length > 9) { } else { return false; }

            //Finance
            if (fd.FinBillableRate !== null && fd.FinBillableRate > 0) { } else { return false; }
            if (fd.FinMaxRate !== null && fd.FinMaxRate > 0) { } else { return false; }
            if (fd.FinTotalDays !== null && fd.FinTotalDays > 0) { } else { return false; }
            if (fd.FinCalcType !== null) { } else { return false; }
            if (fd.FinEstCost !== null && fd.FinEstCost > 0) { } else { return false; }
            if (fd.FinCostPerWorker !== null && fd.FinCostPerWorker > 0) { } else { return false; }
            if (fd.FinIR35ScopeId !== null) { } else { return false; }
            if (this.state.IR35Evidence === null) { return false; }
            if (fd.FinApproachAgreeingRate !== null && fd.FinApproachAgreeingRate.length > 9) { } else { return false; }
            if (fd.FinSummaryIR35Just !== null && fd.FinSummaryIR35Just.length > 9) { } else { return false; }

            //Approvers
            if (this.state.FormData.BHUserId === null) return false;
            if (this.state.FormData.FBPUserId === null) return false;
            if (this.state.FormData.HRBPUserId === null) return false;

        }

        //at the end return true
        return true;
    }

    private validateEntity_Onboarding = (submitToEngaged: boolean): boolean => {
        if (submitToEngaged === true) {

            const fd = this.state.FormDataWorker;
            if (fd.OnbContractorGenderId === null) return false;
            if (fd.OnbContractorTitleId === null) return false;
            if (fd.OnbContractorFirstname !== null && fd.OnbContractorFirstname.length > 1) { } else return false;
            if (fd.OnbContractorSurname !== null && fd.OnbContractorSurname.length > 1) { } else return false;
            if (fd.OnbContractorPhone !== null && fd.OnbContractorPhone.length > 1) { } else return false;
            if (fd.OnbContractorEmail !== null && fd.OnbContractorEmail.length > 1) { } else return false;
            if (fd.OnbContractorHomeAddress !== null && fd.OnbContractorHomeAddress.length > 1) { } else return false;
            if (fd.OnbContractorPostCode !== null && fd.OnbContractorPostCode.length > 1) { } else return false;
            if (fd.OnbStartDate === null) return false;
            if (fd.OnbEndDate !== null && fd.OnbEndDate > fd.OnbStartDate) { } else { return false; }
            if (fd.OnbDayRate !== null && fd.OnbDayRate > 0) { } else return false;
            if (fd.OnbSecurityClearanceId === null) return false;
            if (this.state.ContractorSecurityCheckEvidence === null) return false;

            //workdays
            let totalWorkingDays: number = 0;
            if (fd.OnbWorkingDayMon === true) totalWorkingDays++;
            if (fd.OnbWorkingDayTue === true) totalWorkingDays++;
            if (fd.OnbWorkingDayWed === true) totalWorkingDays++;
            if (fd.OnbWorkingDayThu === true) totalWorkingDays++;
            if (fd.OnbWorkingDayFri === true) totalWorkingDays++;
            if (fd.OnbWorkingDaySat === true) totalWorkingDays++;
            if (fd.OnbWorkingDaySun === true) totalWorkingDays++;
            if (totalWorkingDays === 0) return false;
            if (fd.OnbDecConflictId === null) return false;
            if (fd.OnbLineManagerUserId === null) return false;
            if (fd.OnbWorkOrderNumber !== null && fd.OnbWorkOrderNumber.length > 1) { } else { return false; }
            if (fd.OnbRecruitersEmail !== null && fd.OnbRecruitersEmail.length > 1) { } else { return false; }
        }
        return true;
    }

    private checkCaseCreated = (): boolean => {
        if (this.state.FormData['CaseCreated'] === true)
            return true;

        return false;
    }
    private saveData = (submitForApproval: boolean, submitDecision, stayOnNewCaseTab?: boolean): void => {
        this.StayOnNewCaseTab = stayOnNewCaseTab;
        if (this.validateEntity(submitForApproval, submitDecision)) {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
            let f: ICLCase = { ...this.state.FormData };
            delete f['CLHiringMembers']; //chile entity
            if (this.isNumeric(f.FinMaxRate) === true) {
                f.FinMaxRate = Number(f.FinMaxRate);
            }
            else {
                f.FinMaxRate = null;
            }
            if (this.isNumeric(f.FinBillableRate) === true) {
                f.FinBillableRate = Number(f.FinBillableRate);
            }
            else {
                f.FinBillableRate = null;
            }
            if (this.isNumeric(f.FinTotalDays) === true) {
                f.FinTotalDays = Number(f.FinTotalDays);
            }
            else {
                f.FinTotalDays = null;
            }
            if (this.isNumeric(f.FinEstCost) === true) {
                f.FinEstCost = Number(f.FinEstCost);
            }
            else {
                f.FinEstCost = null;
            }
            if (this.isNumeric(f.FinCostPerWorker) === true) {
                f.FinCostPerWorker = Number(f.FinCostPerWorker);
            }
            else {
                f.FinCostPerWorker = null;
            }
            if (submitForApproval === true) {
                f.Title = "SubmitForApproval"; //for api to know its a request for approval
            }
            if (submitDecision === true) {
                f.Title = "SubmitDecision";
            }

            this.clCaseService.updatePut(f.ID, f).then((): void => {
                this.saveChildEntitiesAfterUpdate();
                if (submitDecision === true) {
                    this.props.onShowList(true);
                }
                else {
                    if (submitForApproval !== true) {
                        this.props.onShowList(true);
                    }
                }
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(null);

                if (submitForApproval === true) {
                    this.setState({ HideSubmitApprovalDoneMessage: false });
                }
                else {
                    if (stayOnNewCaseTab === true) {
                        this.loadCaseInfo();
                        this.loadClCase();
                    }
                }

            }, (err) => {
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(`Error saving data`, err.message);
            });
        }
        else {
            this.setState({ HideFormValidationMessage: false });
        }
    }

    private saveChildEntitiesAfterUpdate = (): Promise<any> => {

        let promises = [];
        if (this.childEntities) {
            this.childEntities.forEach((ce) => {
                this.state.FormData[ce.ObjectParentProperty].forEach((c) => {
                    if (c.ID === 0) {
                        c[ce.ParentIdProperty] = this.state.FormData.ID;
                        promises.push(ce.ChildService.create(c));
                    }
                });

                this.state.FormDataBeforeChanges[ce.ObjectParentProperty].forEach((c) => {
                    if (this.state.FormData[ce.ObjectParentProperty].map(i => i[ce.ChildIdProperty]).indexOf(c[ce.ChildIdProperty]) === -1) {
                        promises.push(ce.ChildService.delete(c.ID));
                    }
                });
            });

            return Promise.all(promises).then(() => this.state.FormData);
        }
    }

    private saveData_Worker = (submitToEngaged: boolean, saveEngaged: boolean, moveToChecksDone: boolean, saveLeaving: boolean, moveToArchive: boolean, stayOnNewCaseTab?: boolean): void => {
        if (this.validateEntity_Onboarding(submitToEngaged)) {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
            let f: ICLWorker = { ...this.state.FormDataWorker };

            if (this.isNumeric(f.OnbDayRate) === true) {
                f.OnbDayRate = Number(f.OnbDayRate);
            }
            else {
                f.OnbDayRate = null;
            }

            if (submitToEngaged === true) {
                f.Title = "SubmitToEngaged"; //for api to know its a request for SubmitToEngaged
            }
            else if (saveEngaged === true) {
                if (moveToChecksDone === true) {
                    f.Title = "SaveEngaged_MoveToChecksDone"; //for api to know its a request from Move to Checks Done button
                }
                else {
                    f.Title = "SaveEngaged"; //for api to know its a request for Save button from Engaged state
                }
            }
            else if (saveLeaving === true) {
                if (moveToArchive === true) {
                    f.Title = "SaveLeaving_MoveToArchive";
                }
                else {
                    f.Title = "SaveLeaving";
                }

            }

            this.clWorkerService.updatePut(f.ID, f).then((): void => {
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(null);

                if (submitToEngaged === true) {
                    this.setState({ HideSubmitEngagedDoneMessage: false });
                }
                else {

                    if (stayOnNewCaseTab === true) {
                        this.loadCaseInfo();
                        this.loadCLWorker();
                    }
                    else {
                        this.props.onShowList(true);
                    }
                }

            }, (err) => {
                if (this.props.errorHandling?.onError)
                    this.props.errorHandling?.onError(`Error saving data`, err.message);
            });

        }
        else {
            this.setState({ HideFormValidationMessage: false });
        }
    }

    protected loadLookups(): Promise<any> {

        return Promise.all([
            this.loadCaseInfo(),
            this.loadClCase(),
            this.loadIR35Evidence(),
            this.loadCLWorker(),
            this.loadContractorSecurityCheckEvidence(),
        ]);
    }


    public componentDidMount(): void {
        this.setState({ Loading: true, Stage: this.props.stage }, this.callBackFirstLoad
        );
    }
    private callBackFirstLoad = (): void => {
        let loadingPromises = [this.loadLookups()];

        Promise.all(loadingPromises).then(p => this.setState({ Loading: false })).catch(err => this.setState({ Loading: false }));
    }

    private loadClCase = (): void => {
        if (this.props.clCaseId > 0) {

            this.clCaseService.readWithExpand(this.props.clCaseId).then((c: ICLCase) => {
                this.setState({
                    FormData: c,
                    FormDataBeforeChanges: c,
                }, () => {
                    this.setDisableFinEstCost();
                    this.blurRateTextField(null, "FinMaxRate");
                    setTimeout(() => {
                        this.blurRateTextField(null, "FinEstCost");


                    }, 2000);
                });

            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading case`, err.message);
            });

        }
        else {
            //set hiring manage to the current user as default for new case
            this.setState({ FormData: this.cloneObject(this.state.FormData, 'ApplHMUserId', this.props.currentUserId) });
        }
    }

    private loadCaseInfo = (): void => {

        if (this.props.clCaseId > 0) {
            this.clCaseService.getCaseInfo(this.props.clCaseId, this.props.clWorkerId).then((x: IClCaseInfo) => {
                this.setState({
                    CaseInfo: x
                });

            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Case info`, err.message);
            });
        }
        else {
            let x = { ...this.state.CaseInfo };
            x.Stage = "Draft";
            x.CreatedBy = this.props.currentUserName;
            x.CaseRef = "Available after creation";
            x.CreatedOn = "Available after creation";
            this.setState({ CaseInfo: x });
        }
    }

    private loadIR35Evidence = (): void => {

        if (this.props.clCaseId > 0) {
            const counter: number = this.state.EvidenceChangesCounter + 1;
            this.clCaseEvidenceService.readIR35Evidence(this.props.clCaseId).then((x: ICLCaseEvidence[]) => {
                if (x.length > 0) {
                    const ir35Ev: ICLCaseEvidence = x[0];
                    this.setState({
                        IR35Evidence: ir35Ev,
                        EvidenceChangesCounter: counter,
                    });
                }
                else {
                    this.setState({
                        IR35Evidence: null,
                        EvidenceChangesCounter: counter,
                    });
                }

            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Case IR35 Evidence`, err.message);
            });
        }
    }

    private loadCLWorker = (): void => {
        if (this.props.clWorkerId > 0) {
            this.clWorkerService.read(this.props.clWorkerId).then((w: ICLWorker) => {
                this.setState({
                    FormDataWorker: w,
                }, () => {
                    this.blurRateTextField_Worker(null, "OnbDayRate");

                    if (this.state.Stage === "Engaged") {
                        this.engaged_Checks();
                    }
                });


            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading worker`, err.message);
            });
        }
    }

    private loadContractorSecurityCheckEvidence = (): void => {

        const stage = this.state.Stage;
        if (stage === "Onboarding" || stage === "Engaged" || stage === "Leaving" || stage === "Left" || stage === "Extended") {
            //ok - load data
        }
        else {
            return;
        }

        this.clCaseEvidenceService.readContractorSecurityCheckEvidence(this.props.clWorkerId).then((x: ICLCaseEvidence[]) => {
            if (x.length > 0) {
                const contractorSecurityCheckEvidence: ICLCaseEvidence = x[0];

                this.setState({
                    ContractorSecurityCheckEvidence: contractorSecurityCheckEvidence,
                });
            }
            else {
                this.setState({
                    ContractorSecurityCheckEvidence: null,
                });
            }

        }, (err) => {
            if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Case ContractorSecurityCheckEvidence Evidence`, err.message);
        });

    }

    private handleEvidence_ChangeFilterText = (value: string): void => {
        this.setState({ Evidence_ListFilterText: value });
    }

    protected cloneObject(obj, changeProp?, changeValue?) {
        if (changeProp)
            return { ...obj, [changeProp]: changeValue };
        return { ...obj };
    }

    private changeTextField_ReqNumPositions = (value: string, f: string): void => {

        let maxLimit: number = 30;
        if (this.props.superUserPermission === true)
            maxLimit = 99;

        if (value == null || value == '') {
            this.setState({ FormData: this.cloneObject(this.state.FormData, f, value)/*, FormIsDirty: true*/ });
        }
        else {
            const isNum: boolean = this.isNumeric(value);
            if (isNum === true) {
                if (Number(value) <= maxLimit) {
                    if (Number(value) == 0) {
                        //make 1 if they enter 0
                        this.setState({ FormData: this.cloneObject(this.state.FormData, f, "1")/*, FormIsDirty: true*/ });
                    }
                    else {
                        //set to value what they entered
                        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value)/*, FormIsDirty: true*/ });
                    }

                }
                else {
                    //set to max limit
                    this.setState({ FormData: this.cloneObject(this.state.FormData, f, String(maxLimit))/*, FormIsDirty: true*/ });
                }

            }
            else {
                this.setState({ FormData: this.cloneObject(this.state.FormData, f, this.state.FormData[f])/*, FormIsDirty: true*/ });
            }
        }
    }

    protected changeTextField_number = (value: string, f: string): void => {

        if (value == null || value == '') {
            this.setState({ FormData: this.cloneObject(this.state.FormData, f, null)/*, FormIsDirty: true*/ });
        }
        else {
            const isNum: boolean = this.isNumeric(value);
            if (isNum === true) {
                this.setState({ FormData: this.cloneObject(this.state.FormData, f, value)/*, FormIsDirty: true*/ });
            }
            else {
                this.setState({ FormData: this.cloneObject(this.state.FormData, f, this.state.FormData[f])/*, FormIsDirty: true*/ });
            }
        }
    }

    private changeTextField_number_Worker = (value: string, f: string): void => {

        if (value == null || value == '') {
            this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, null)/*, FormIsDirty: true*/ });
        }
        else {
            const isNum: boolean = this.isNumeric(value);
            if (isNum === true) {
                this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, value)/*, FormIsDirty: true*/ });
            }
            else {
                this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, this.state.FormDataWorker[f])/*, FormIsDirty: true*/ });
            }
        }
    }

    protected changeTextField = (value: string, f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value)/*, FormIsDirty: true*/ });
    }

    private changeTextField_Worker = (value: string, f: string, engagedChecks?: boolean): void => {
        this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, value)/*, FormIsDirty: true*/ },

            () => {
                if (engagedChecks === true) {
                    this.engaged_Checks();
                }
            }

        );
    }

    private setDisableFinEstCost = () => {
        if (this.state.FormData.FinCalcType === 'Manual') {
            this.setState({ DisableFinEstCost: false });
        }
        else {
            this.setState({ DisableFinEstCost: true }, this.calculateRate);
        }
    }

    private changeCheckbox_Worker = (value: boolean, f: string): void => {
        this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, value)/*, FormIsDirty: true*/ });
    }
    private changeDropdown_FinCalcType = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key), /*FormIsDirty: true*/ }, this.setDisableFinEstCost);
    }
    private changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, option.key), /*FormIsDirty: true*/ });
    }
    private changeDropdown_Worker = (option: IDropdownOption, f: string, index?: number): void => {
        this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, option.key), /*FormIsDirty: true*/ });
    }
    protected changeChoiceGroup = (ev, option: IChoiceGroupOption, f: string): void => {
        const selectedKey = option.key;
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, selectedKey)/*, FormIsDirty: true*/ });

    }
    private changeUserPicker = (value: number[], f: string): void => {
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, value.length === 1 ? value[0] : null), });
    }
    private changeUserPicker_Worker = (value: number[], f: string, engagedChecks?: boolean, leavingChecks?: boolean): void => {
        this.setState({
            FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, value.length === 1 ? value[0] : null),
        },
            () => {
                if (engagedChecks === true) {
                    this.engaged_Checks();
                }
            }
        );
    }

    private changeMultiUserPicker = (value: number[], f: string, newEntity: object, userIdProperty: string): void => {
        const valuesUnique = value.filter((item, pos) => {
            return value.indexOf(item) == pos;
        });
        value = valuesUnique;

        const loadedUsers = this.cloneObject(this.state.FormDataBeforeChanges);
        let newUsers = [];
        value.forEach((userId) => {
            let existingUser = loadedUsers[f] ? loadedUsers[f].map(user => user[userIdProperty]).indexOf(userId) : -1;
            if (existingUser !== -1) {
                //existing user which is saved in db
                newUsers.push(loadedUsers[f][existingUser]);
            }
            else {
                //-1
                let newUser = { ...newEntity };
                newUser[userIdProperty] = userId;
                newUsers.push(newUser);
            }
        });
        this.setState({ FormData: this.cloneObject(this.state.FormData, f, newUsers), FormIsDirty: true });
    }

    private engaged_Checks = (): void => {
        const fd = this.state.FormDataWorker;
        if (fd.BPSSCheckedById !== null && fd.BPSSCheckedOn !== null &&
            fd.POCheckedById !== null && fd.POCheckedOn !== null &&
            fd.ITCheckedById !== null && fd.ITCheckedOn !== null &&
            fd.UKSBSCheckedById !== null && fd.UKSBSCheckedOn !== null &&
            fd.SDSCheckedById !== null && fd.SDSCheckedOn !== null &&
            fd.SDSNotes !== null && fd.SDSNotes.length > 5) {
            this.setState({ Engaged_MoveToChecksDoneBtn: true });
        }
        else {
            this.setState({ Engaged_MoveToChecksDoneBtn: false });
        }
    }

    private leaving_Checks = (): void => {
        const fd = this.state.FormDataWorker;

        if (fd.LeEndDate !== null &&
            fd.LeContractorPhone !== null && fd.LeContractorPhone.length > 1 &&
            fd.LeContractorEmail !== null && fd.LeContractorEmail.length > 1 &&
            fd.LeContractorHomeAddress !== null && fd.LeContractorHomeAddress.length > 1 &&
            fd.LeContractorPostCode !== null && fd.LeContractorPostCode.length > 1 &&
            fd.LeContractorDetailsCheckedById !== null && fd.LeContractorDetailsCheckedOn !== null &&
            fd.LeITCheckedById !== null && fd.LeITCheckedOn !== null &&
            fd.LeUKSBSCheckedById !== null && fd.LeUKSBSCheckedOn !== null) {
            this.setState({ Leaving_MoveToArchiveBtn: true });
        }
        else {
            this.setState({ Leaving_MoveToArchiveBtn: false });
        }

    }

    private isNumeric(str: any) {
        const tempNum = Number(str);
        return !isNaN(tempNum) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)); // ...and ensure strings of whitespace fail
    }

    private blurFinBillableRate = (ev, f: string): void => {
        const billableRate: number = Number(this.state.FormData.FinBillableRate);
        if (billableRate > 0) {
            const dayRateWithVAT: string = ((billableRate * 0.2) + (billableRate)).toFixed(2);
            let obj = this.cloneObject(this.state.FormData, 'FinMaxRate', dayRateWithVAT);
            this.setState({ FormData: obj/*, FormIsDirty: true*/ }, this.calculateRate);
        }
        else {
            this.setState({ FormData: this.cloneObject(this.state.FormData, 'FinMaxRate', null)/*, FormIsDirty: true*/ });
        }
    }

    private blurRateTextField = (ev, f: string): void => {
        if (Number(this.state.FormData[f]) > 0) {
            const rateStr = Number(this.state.FormData[f]).toFixed(2);
            this.setState({ FormData: this.cloneObject(this.state.FormData, f, rateStr)/*, FormIsDirty: true*/ });
        }
    }

    private blurRateTextField_Worker = (ev, f: string): void => {
        if (Number(this.state.FormDataWorker[f]) > 0) {
            const rateStr = Number(this.state.FormDataWorker[f]).toFixed(2);
            this.setState({ FormDataWorker: this.cloneObject(this.state.FormDataWorker, f, rateStr)/*, FormIsDirty: true*/ });
        }
    }

    private calculateTotalDays = (): void => {
        if (this.state.FormData.FinTotalDays == null || this.state.FormData.FinTotalDays == 0) {
            if (this.state.FormData.ReqEstStartDate != null && this.state.FormData.ReqEstEndDate != null) {
                const startDate: Date = new Date(this.state.FormData.ReqEstStartDate.getTime());
                const endDate: Date = new Date(this.state.FormData.ReqEstEndDate.getTime());
                const days: number = this.getBusinessDatesCount(startDate, endDate);
                this.setState({ FormData: this.cloneObject(this.state.FormData, 'FinTotalDays', days) });
            }
        }
    }

    private calculateRate = (): void => {
        if (this.state.FormData.FinCalcType === 'Automatic' && this.state.FormData.ReqEstStartDate != null && this.state.FormData.ReqEstEndDate != null && this.state.FormData.ReqNumPositions > 0 && this.state.FormData.FinMaxRate > 0) {
            //const startDate: Date = new Date(this.state.FormData.ReqEstStartDate.getTime());
            //const endDate: Date = new Date(this.state.FormData.ReqEstEndDate.getTime());
            const days: number = this.state.FormData.FinTotalDays;
            const numPositions: number = this.state.FormData.ReqNumPositions;
            const dayRate: number = this.state.FormData.FinMaxRate;
            const totalCost: string = (numPositions * dayRate * days).toFixed(2);
            const totalCostPerWorker: string = (dayRate * days).toFixed(2);
            let formObj = this.cloneObject(this.state.FormData, 'FinEstCost', totalCost);
            formObj = this.cloneObject(formObj, 'FinCostPerWorker', totalCostPerWorker);
            this.setState({ FormData: formObj });
        }
    }
    private addIr35Evidence = () => {
        this.setState({ ShowIR35EvidenceForm: true });

    }
    private addContractorSecurityCheckEvidence = () => {
        this.setState({ ShowContractorSecurityCheckEvidenceForm: true });
    }

    private ir35EvidenceSaved = (): void => {
        this.closeIR35EvidencePanel();
        this.loadIR35Evidence();
    }

    private contractorSecurityCheckEvidenceSaved = (): void => {
        this.closeContractorSecurityCheckEvidencePanel();
        this.loadContractorSecurityCheckEvidence();
    }

    private closeIR35EvidencePanel = (): void => {
        this.setState({ ShowIR35EvidenceForm: false });
    }
    private closeContractorSecurityCheckEvidencePanel = (): void => {
        this.setState({ ShowContractorSecurityCheckEvidenceForm: false });
    }

    private getBusinessDatesCount = (startDate: Date, endDate: Date): number => {
        var count = 0;
        var curDate = startDate;
        while (curDate <= endDate) {
            var dayOfWeek = curDate.getDay();
            if (!((dayOfWeek == 6) || (dayOfWeek == 0)))
                count++;
            curDate.setDate(curDate.getDate() + 1);
        }
        return count;
    }

    private viewIR35Evidence = async (): Promise<void> => {
        const fileName: string = this.state.IR35Evidence.Title;
        if (this.state.IR35Evidence.AttachmentType === "Link") {
            const a = document.createElement('a');
            a.href = fileName;
            a.target = "_blank";
            document.body.appendChild(a);
            setTimeout(() => {
                window.URL.revokeObjectURL(fileName);
                window.open(fileName, '_blank');
                document.body.removeChild(a);
            }, 1);
        }
        else {
            const fileUniqueID: string = this.state.IR35Evidence.FileUniqueID;
            try {
                await this.zFileService.downloadFile(fileUniqueID, fileName);
            } catch (error) {
                console.error('Error downloading file', error);
            }
        }
    }

    private viewContractorSecurityCheckEvidence = async (): Promise<void> => {
        const fileName: string = this.state.ContractorSecurityCheckEvidence.Title;
        if (this.state.ContractorSecurityCheckEvidence.AttachmentType === "Link") {
            const a = document.createElement('a');
            a.href = fileName;
            a.target = "_blank";
            document.body.appendChild(a);
            setTimeout(() => {
                window.URL.revokeObjectURL(fileName);
                window.open(fileName, '_blank');
                document.body.removeChild(a);
            }, 1);
        }
        else {
            const fileUniqueID: string = this.state.ContractorSecurityCheckEvidence.FileUniqueID;
            try {
                await this.zFileService.downloadFile(fileUniqueID, fileName);
            } catch (error) {
                console.error('Error downloading file', error);
            }
        }
    }

    private toggleIR35EvDeleteConfirm = (): void => {
        this.setState({ HideIR35EvDeleteDialog: !this.state.HideIR35EvDeleteDialog });
    }

    private toggleContractorSecurityCheckEvDeleteConfirm = (): void => {
        this.setState({ HideContractorSecurityCheckEvDeleteDialog: !this.state.HideContractorSecurityCheckEvDeleteDialog });
    }

    private deleteIR35Evidence = (): void => {
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
        this.setState({ HideIR35EvDeleteDialog: true });

        const fileName: string = this.state.IR35Evidence.Title;
        if (this.state.IR35Evidence.AttachmentType === "Link") {
            this.clCaseEvidenceService.delete(this.state.IR35Evidence.ID).then(this.loadIR35Evidence, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Cannot delete this evidence. `, err.message);
            });
        }
        else {

            this.clCaseEvidenceService.delete(this.state.IR35Evidence.ID).then(d => {
            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Cannot delete this evidence. `, err.message);
            });

        }
    }

    private deleteContractorSecurityCheckEvidence = (): void => {
        if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(null);
        this.setState({ HideContractorSecurityCheckEvDeleteDialog: true });

        const fileName: string = this.state.ContractorSecurityCheckEvidence.Title;
        if (this.state.ContractorSecurityCheckEvidence.AttachmentType === "Link") {
            this.clCaseEvidenceService.delete(this.state.ContractorSecurityCheckEvidence.ID).then(this.loadContractorSecurityCheckEvidence, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Cannot delete this evidence. `, err.message);
            });
        }
        else {

            this.clCaseEvidenceService.delete(this.state.ContractorSecurityCheckEvidence.ID).then(d => {
            }, (err) => {
                if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Cannot delete this evidence. `, err.message);
            });
        }
    }

    private isSuperUserOrApprover = (): boolean => {
        if (this.props.superUserPermission === true
            || this.props.currentUserId === this.state.FormData.BHUserId
            || this.props.currentUserId === this.state.FormData.FBPUserId
            || this.props.currentUserId === this.state.FormData.HRBPUserId
            || this.props.currentUserId === this.state.FormData.CBPUserId) {
            return true;
        }
        else {
            return false;
        }
    }

    private isViewOnlyPermission = (): boolean => {

        let isViewOnly: boolean = false;
        if (this.props.viewerPermission === true && this.isSuperUserOrApprover() === false
            && this.state.FormData.ApplHMUserId !== this.props.currentUserId
            && this.state.FormData.CreatedById !== this.props.currentUserId) {
            isViewOnly = true;
        }

        if (this.state.FormDataWorker.Archived === true) {
            isViewOnly = true;
        }

        return isViewOnly;
    }

    private afterSubmitEngagedSuccessMsg = (): void => {
        if (this.state.FormData.CaseType === "Extension") {
            this.setState({
                Stage: 'Engaged'
            }, () => {
                this.loadCLWorker();

            });
        }
        else {
            this.props.onShowList(true);
        }
    }

    private onViewExtHistroyClick = (caseId: number, workerId: number, stage: string): void => {
        this.props.onShowHistoricCase(workerId, caseId, stage);
    }

    private toggleAllowChangeHM = (): void => {
        this.setState({
            ShowAllowChangeHM: !this.state.ShowAllowChangeHM,
        });
    }

    private toggleAllowChangeRequirement = (): void => {
        this.setState({
            ShowAllowChangeRequirement: !this.state.ShowAllowChangeRequirement,
        });
    }

    private toggleAllowChangeCommercial = (): void => {
        this.setState({
            ShowAllowChangeCommercial: !this.state.ShowAllowChangeCommercial,
        });
    }

    private toggleAllowChangeResourcingJustification = (): void => {
        this.setState({
            ShowAllowChangeResourcingJustification: !this.state.ShowAllowChangeResourcingJustification,
        });
    }

    private toggleAllowChangeFinance = (): void => {
        this.setState({
            ShowAllowChangeFinance: !this.state.ShowAllowChangeFinance,
        });
    }

    private toggleAllowChangeOther = (): void => {
        this.setState({
            ShowAllowChangeOther: !this.state.ShowAllowChangeOther,
        });
    }

    private toggleAllowChangeApprovers = (): void => {
        this.setState({
            ShowAllowChangeApprovers: !this.state.ShowAllowChangeApprovers,
        });
    }

    private toggleAllowChangeOnboarding = (): void => {
        this.setState({
            ShowAllowChangeOnboarding: !this.state.ShowAllowChangeOnboarding,
        });
    }

    private createSDSPdf = (): void => {
        this.clWorkerService.createSDSPDF(this.state.FormDataWorker.ID).then((res: string): void => {
            this.loadCLWorker();
        }, (err) => {
            if (this.props.errorHandling?.onError)
                this.props.errorHandling?.onError(`Error creating PDF`, err.message);
        });
    }

    private refreshSDSPdfStatus = (): void => {
        this.loadCLWorker();
    }

    private downloadSDSPdf = async (): Promise<void> => {
        const fileName: string = this.state.FormDataWorker.SDSPdfName;
        const fileUniqueID: string = this.state.FormDataWorker.SDSPdfFileUniqueID;

        try {
            await this.zFileService.downloadFile(fileUniqueID, fileName);
        } catch (error) {
            console.error('Error downloading file', error);
        }
    }

    private createCasePdf = (): void => {
        this.clWorkerService.createCasePDF(this.props.clWorkerId).then((res: string): void => {
            this.loadCLWorker();
        }, (err) => {
            if (this.props.errorHandling?.onError)
                this.props.errorHandling?.onError(`Error creating PDF`, err.message);
        });
    }

    private refreshCasePdfStatus = (): void => {
        this.loadCLWorker();
    }

    private downloadCasePdf = async (): Promise<void> => {
        const fileName: string = this.state.FormDataWorker.CasePdfName;
        const fileUniqueID: string = this.state.FormDataWorker.CasePdfFileUniqueID;
        try {
            await this.zFileService.downloadFile(fileUniqueID, fileName);
        } catch (error) {
            console.error('Error downloading file', error);
        }
    }

    private showHelpPanel = (helpText?: string) => {
        this.setState({ UserHelpText: helpText, ShowHelpPanel: true });
    }

    private hideHelpPanel = () => {
        this.setState({ ShowHelpPanel: false });
    }
}

export default withMobileDetector(NewCaseTab);