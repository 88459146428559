import React from 'react';
import useScreenSizeDetector from './useScreenSizeDetector';

const withScreenSizeDetector = (WrappedComponent: React.ComponentType<any>) => {
    return (props: any) => {
        const screenSize = useScreenSizeDetector();
        return <WrappedComponent {...props} screenSize={screenSize} />;
    };
};

export default withScreenSizeDetector;
