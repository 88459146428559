import * as React from 'react';
import { Pivot, PivotItem } from '@fluentui/react/lib/Pivot';
import Section from './Section';
import RecommendationsTab from './RecommendationsTab';
import PeriodUpdateTab from './PeriodUpdateTab';
import * as types from '../../types';
import * as services from '../../services';
import { IEntity, IOrgLevel1, IUserContext } from '../../types';
import { CrDropdown } from '../cr/CrDropdown';
import { IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { CrLoadingOverlayWelcomeCat } from '../cr/CrLoadingOverlayWelcomeCat';

export interface INaoUpdatesClsProps extends types.IBaseComponentProps {
  userContext: IUserContext;
}

export interface ILookupData {
  DGAreas: IOrgLevel1[];
}

export class LookupData implements ILookupData {
  public DGAreas: IOrgLevel1[] = [];
}

export interface INaoUpdatesClsState {
  Loading: boolean;
  LookupData: ILookupData;
  PeriodId: string | number;
  DirectorateGroupId: string | number;
  IsArchivedPeriod: boolean;
  SelectedPivotKey: string;
  Section1_IsOpen: boolean;
  Section1_MainList_IncompleteOnly: boolean;
  Section1__MainList_JustMine: boolean;
  Section1_MainList_ListFilterText: string;
  Section2_IsOpen: boolean;
  Section2_MainList_IncompleteOnly: boolean;
  Section2__MainList_JustMine: boolean;
  Section2_MainList_ListFilterText: string;
  MainListsSaveCounter: number;

  //generic for both sections
  Section_MainList_SelectedId: number;
  Section_MainList_SelectedTitle: string;
  Section_MainList_FilteredItems: any[];

  //Rec Tab
  Section1_RecList_SelectedId: number;
  Section1_RecList_SelectedTitle: string;
  Section1_RecList_FilteredItems: any[];
  RecList_SelectedItem_ViewOnly: boolean;

}
export class NaoUpdatesClsState implements INaoUpdatesClsState {
  public Loading = false;
  public LookupData = new LookupData();
  public PeriodId: string | number = 0;
  public IsArchivedPeriod = false;
  public DirectorateGroupId: string | number = 0;
  public SelectedPivotKey = "Publications"; //default, 1st tab selected
  public Section1_IsOpen: boolean = true;
  public Section1_MainList_IncompleteOnly = false;
  public Section1__MainList_JustMine = false;
  public Section1_MainList_ListFilterText: string = null;
  public MainListsSaveCounter: number = 0;
  public Section2_IsOpen: boolean = false;
  public Section2_MainList_IncompleteOnly = false;
  public Section2__MainList_JustMine = false;
  public Section2_MainList_ListFilterText: string = null;

  //generic for both sections
  public Section_MainList_SelectedId: number = 0;
  public Section_MainList_SelectedTitle: string = null;
  public Section_MainList_FilteredItems = [];

  //Rec Tab
  public Section1_RecList_SelectedId: number;
  public Section1_RecList_SelectedTitle: string;
  public Section1_RecList_FilteredItems: any[];

  public RecList_SelectedItem_ViewOnly = false;

  constructor() {
  }
}


export default class NaoUpdatesCls extends React.Component<INaoUpdatesClsProps, NaoUpdatesClsState> {

  protected deirectorateGroupService: services.OrgLevel1Service = new services.OrgLevel1Service();
  protected naoPublicationService: services.NAOPublicationService = new services.NAOPublicationService();
  private readonly headerTxt_MainTab: string = "Publications";
  private readonly headerTxt_RecommendationsTab: string = "Recommendations";
  private readonly headerTxt_PeriodUpdateTab: string = "Updates";

  constructor(props: INaoUpdatesClsProps) {
    super(props);
    this.state = new NaoUpdatesClsState();
  }



  public render(): React.ReactElement<INaoUpdatesClsProps> {
    return (

      <Pivot onLinkClick={this.handlePivotClick} selectedKey={`${this.state.SelectedPivotKey}`}>
        <PivotItem headerText={this.headerTxt_MainTab} itemKey={this.headerTxt_MainTab}>
          {this.renderMainTab()}
        </PivotItem>
        {this.renderRecommendationsTab()}
        {this.renderPeriodUpdateTab()}

      </Pivot>
    );
  }

  private renderMainTab(): React.ReactElement<types.IWebPartComponentProps> {
    const { LookupData: lookups } = this.state;
    let dgAreasDrpOptions = lookups.DGAreas.map((dgArea) => { return { key: dgArea.ID, text: dgArea.Title }; });
    dgAreasDrpOptions = [{ key: 0, text: "All DGAreas" }, ...dgAreasDrpOptions];

    return (
      <div>
        <CrLoadingOverlayWelcomeCat isLoading={this.state.Loading} />
        <div style={{ paddingTop: "10px" }}>
          <CrDropdown
            label="Which DGArea?"
            options={dgAreasDrpOptions}
            onChange={(_, v) => this.changeDropdown(v, 'DirectorateGroupId')}
            selectedKey={this.state.DirectorateGroupId}
          />
          <br />
          {
            <div>
              <Section
                isArchive={false}
                sectionTitle="Active NAO/PAC Publications"
                dgAreaId={this.state.DirectorateGroupId}
                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                section_IsOpen={this.state.Section1_IsOpen}
                onSection_toggleOpen={this.handleSection1_toggleOpen}
                justMine={this.state.Section1__MainList_JustMine}
                incompleteOnly={this.state.Section1_MainList_IncompleteOnly}
                listFilterText={this.state.Section1_MainList_ListFilterText}
                onChangeFilterText={this.handleSection1_ChangeFilterText}
                onChangeIncompleteOnly={this.handleSection1_ChangeIncompleteOnly}
                onChangeJustMine={this.handleSection1_ChangeJustMine}
                onMainSaved={this.handleMainFormSaved}
                mainListsSaveCounter={this.state.MainListsSaveCounter}
                superUserPermission={this.isSuperUser()}
                {...this.props}
              />

              <Section
                isArchive={true}
                sectionTitle="Archived NAO/PAC Publications"
                dgAreaId={this.state.DirectorateGroupId}
                onItemTitleClick={this.handleSection_MainListItemTitleClick}
                section_IsOpen={this.state.Section2_IsOpen}
                onSection_toggleOpen={this.handleSection2_toggleOpen}
                justMine={this.state.Section2__MainList_JustMine}
                incompleteOnly={this.state.Section2_MainList_IncompleteOnly}
                listFilterText={this.state.Section2_MainList_ListFilterText}
                onChangeFilterText={this.handleSection2_ChangeFilterText}
                onChangeIncompleteOnly={this.handleSection2_ChangeIncompleteOnly}
                onChangeJustMine={this.handleSection2_ChangeJustMine}
                onMainSaved={this.handleMainFormSaved}
                mainListsSaveCounter={this.state.MainListsSaveCounter}
                superUserPermission={this.isSuperUser()}
                {...this.props}
              />
            </div>
          }
        </div>
      </div>
    );
  }

  private renderRecommendationsTab() {
    if (this.state.SelectedPivotKey === this.headerTxt_RecommendationsTab || this.state.SelectedPivotKey === this.headerTxt_PeriodUpdateTab) {
      return (
        <PivotItem headerText={this.headerTxt_RecommendationsTab} itemKey={this.headerTxt_RecommendationsTab}>
          {this.renderRecommendations()}
        </PivotItem>
      );
    }
    else
      return <React.Fragment></React.Fragment>;
  }

  private renderPeriodUpdateTab() {
    if (this.state.SelectedPivotKey === this.headerTxt_PeriodUpdateTab) {
      return (

        <PivotItem headerText={this.headerTxt_PeriodUpdateTab} itemKey={this.headerTxt_PeriodUpdateTab}>
          {this.renderPeriodUpdate()}
        </PivotItem>
      );
    }
    else
      return <React.Fragment></React.Fragment>;
  }

  private renderRecommendations(): React.ReactElement<types.IWebPartComponentProps> {
    return (
      <RecommendationsTab
        filteredItems={this.state.Section_MainList_FilteredItems}
        parentId={this.state.Section_MainList_SelectedId}
        periodId={this.state.PeriodId}
        parentTitle={this.state.Section_MainList_SelectedTitle}
        onItemTitleClick={this.handleSection1_RecListItemTitleClick}
        onShowList={this.handleShowListSection1}
        superUserPermission={this.isSuperUser()}
        dgOrDGMemberPermission={this.isDG_Or_DGMember()}
        {...this.props}
      />
    );
  }

  private renderPeriodUpdate(): React.ReactElement<types.IWebPartComponentProps> {
    return (
      <PeriodUpdateTab
        naoRecommendationId={this.state.Section1_RecList_SelectedId}
        naoPeriodId={this.state.PeriodId}
        filteredItems={this.state.Section1_RecList_FilteredItems}
        onShowList={this.handleShowSection1RecList}
        isViewOnly={this.state.RecList_SelectedItem_ViewOnly}
        {...this.props}
      />
    );
  }

  protected loadDGAreas = (): void => {
    this.deirectorateGroupService.readAll(`?$orderby=Title`).then((data: IEntity[]): IEntity[] => {
      this.setState({ LookupData: this.cloneObject(this.state.LookupData, 'DGAreas', data) });
      return data;
    }, (err) => { if (this.props.errorHandling?.onError) this.props.errorHandling?.onError(`Error loading Teams lookup data`, err.message); });
  }

  private loadLookups(): Promise<any> {
    return Promise.all([
      this.loadDGAreas(),
    ]).then(() => {
      this.setState({ Loading: false })
    });
  }

  public componentDidMount(): void {
    this.setState({ Loading: true });
    this.loadLookups();
  }

  private getCurrentUserId = (): number => {
    return this.props.userContext.UserId;
  }


  private isSuperUser(): boolean {
    return this.props.userPermissions.UserIsSystemAdmin()
      || this.props.userPermissions.UserIsNAOTrackerSuperUser();
  }

  private isDG_Or_DGMember(): boolean {
    if (this.props.userContext.DirectorGeneralOf.length > 0) {
      return true;
    }


    //ToDo
    // //DirectorateGroup member check
    // let dgms = this.state.DirectorateGroupMembers;
    // for(let i=0; i<dgms.length; i++){
    //   let dgm: types.IOrgLevel1Member = dgms[i];
    //   if(this.state.DirectorateGroupId === dgm.DirectorateGroupID){
    //     if(dgm.ViewOnly === true){
    //       return false;
    //     }
    //     else{
    //       //may need is admin check here
    //       return true;
    //     }  
    //   }

    // }

    return false;
  }

  protected changeDropdown = (option: IDropdownOption, f: string, index?: number): void => {
    this.setState({ DirectorateGroupId: option.key },
    );
  }

  private handlePivotClick = (item: PivotItem): void => {
    this.setState({ SelectedPivotKey: item.props.headerText });
  }

  private handleSection_MainListItemTitleClick = (ID: number, title: string, filteredItems: any[]): void => {
    console.log('on main list item title click ', ID, title, filteredItems);
    const currentPublication = filteredItems.filter(x => x['ID'] === ID);
    const currentPeriodId: number = Number(currentPublication[0]["CurrentPeriodId"]);
    console.log('currentPeriodId', currentPeriodId);

    this.setState({
      SelectedPivotKey: this.headerTxt_RecommendationsTab,
      Section_MainList_SelectedId: ID,
      Section_MainList_SelectedTitle: title,
      Section_MainList_FilteredItems: filteredItems,
      PeriodId: currentPeriodId,
    });
  }

  private handleSection1_RecListItemTitleClick = (ID: number, title: string, filteredItems: any[]): void => {
    const currentUderId: number = this.getCurrentUserId();

    let assigneePermission: boolean = false;
    const currentRec = filteredItems.filter(x => x['ID'] === ID);
    let assignedToIdsStr: string = "";
    if (currentRec.length > 0) {
      assignedToIdsStr = currentRec[0]["AssignedToIds"];
      const assignedToIdsArr: string[] = assignedToIdsStr.split(',');

      for (let i = 0; i < assignedToIdsArr.length; i++) {
        let assigneeId: number = Number(assignedToIdsArr[i]);
        if (assigneeId === currentUderId) {
          assigneePermission = true;
          break;
        }
      }
    }

    let recList_SelectedItem_ViewOnly: boolean = true;
    if (this.isSuperUser() === true || assigneePermission === true) {
      recList_SelectedItem_ViewOnly = false;
    }

    this.setState({
      SelectedPivotKey: this.headerTxt_PeriodUpdateTab,
      Section1_RecList_SelectedId: ID,
      Section1_RecList_SelectedTitle: title,
      RecList_SelectedItem_ViewOnly: recList_SelectedItem_ViewOnly,
      Section1_RecList_FilteredItems: filteredItems
    });
  }

  private handleSection1_toggleOpen = (): void => {
    this.setState({ Section1_IsOpen: !this.state.Section1_IsOpen });
  }

  private handleSection1_ChangeFilterText = (value: string): void => {
    this.setState({ Section1_MainList_ListFilterText: value });
  }

  private handleSection1_ChangeIncompleteOnly = (value: boolean): void => {
    this.setState({ Section1_MainList_IncompleteOnly: value });
  }

  private handleSection1_ChangeJustMine = (value: boolean): void => {
    this.setState({ Section1__MainList_JustMine: value });
  }

  private handleShowListSection1 = (): void => {
    this.setState({ SelectedPivotKey: this.headerTxt_MainTab });
  }

  private handleShowSection1RecList = (): void => {
    this.setState({ SelectedPivotKey: this.headerTxt_RecommendationsTab });
  }

  private handleMainFormSaved = (): void => {
    const x: number = this.state.MainListsSaveCounter + 1;
    console.log('in handleMainFormSaved', x);
    this.setState({ MainListsSaveCounter: x });

  }

  private handleSection2_toggleOpen = (): void => {
    this.setState({ Section2_IsOpen: !this.state.Section2_IsOpen });
  }

  private handleSection2_ChangeFilterText = (value: string): void => {
    this.setState({ Section2_MainList_ListFilterText: value });
  }

  private handleSection2_ChangeIncompleteOnly = (value: boolean): void => {
    this.setState({ Section2_MainList_IncompleteOnly: value });
  }

  private handleSection2_ChangeJustMine = (value: boolean): void => {
    this.setState({ Section2__MainList_JustMine: value });
  }

  private cloneObject(obj, changeProp?, changeValue?) {
    if (changeProp)
      return { ...obj, [changeProp]: changeValue };
    return { ...obj };
  }

}


